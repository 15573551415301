import { combineReducers } from 'redux';
import items from './items';
import list from './cardsList';

const virtualCards = combineReducers({
  items,
  list,
});

export type VirtualCardsState = ReturnType<typeof virtualCards>;

export default virtualCards;
