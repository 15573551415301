/* eslint-disable no-param-reassign */
import {
  createAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { combineReducers } from 'redux';
import createAsyncThunkStatusSlice from 'shared/utils/redux/createAsyncThunkStatusSlice';
import {
  Subaccount,
  SubaccountDetails,
  SubaccountResponse,
  SubaccountsResponse,
} from 'shared/types/Subaccount';
import { SubaccountFilters } from './useSubaccountsFilters';

interface FetchSubaccountsParams extends SubaccountFilters {
  currentKey?: string;
  companyId: number;
  refresh?: boolean;
}

interface FetchSubaccountParams {
  subaccountId: string;
  refresh?: boolean;
}

export const fetchSubaccounts = createAsyncThunk(
  'subaccounts/fetch',
  async (
    {
      companyId,
      archived,
      budget,
      search,
      type,
      currentKey,
    }: FetchSubaccountsParams,
    { rejectWithValue },
  ) => {
    const opts: RoutingParams = {
      company: companyId,
    };

    opts.archived = archived ? 1 : 0;

    if (budget) {
      opts.budget = budget;
    }
    if (search) {
      opts.search = search;
    }
    if (type) {
      opts.type = type;
    }

    try {
      const response = await axios.get<SubaccountsResponse>(
        AppRouting.generate('api_subaccounts_get', opts),
      );
      return { ...response.data, currentKey };
    } catch (ex) {
      if (!ex.response) {
        throw ex;
      }
      return rejectWithValue(ex);
    }
  },
);

export const fetchSubaccount = createAsyncThunk(
  'subaccount/fetch',
  async ({ subaccountId }: FetchSubaccountParams, { rejectWithValue }) => {
    const opts: RoutingParams = {
      subaccount: subaccountId,
    };
    try {
      const response = await axios.get<SubaccountResponse>(
        AppRouting.generate('api_subaccount_get', opts),
      );
      return response.data;
    } catch (ex) {
      if (!ex.response) {
        throw ex;
      }
      return rejectWithValue(ex);
    }
  },
);

interface SubaccountManagerParams {
  subaccountId: string;
  employeeId: number;
}

export const addSubaccountOwner = createAsyncThunk(
  'subaccount/addOwner',
  async ({ subaccountId, employeeId }: SubaccountManagerParams) => {
    const opts: RoutingParams = {
      subaccount: subaccountId,
      employee: employeeId,
    };

    const response = await axios.put<SubaccountResponse>(
      AppRouting.generate('api_subaccount_manager_add', opts),
    );
    return response.data;
  },
);

export const patchSubaccount = createAction<SubaccountDetails>(
  'subaccount/patch',
);

const initialSubaccountsState: Record<string, Subaccount> = {};

export const getSubaccountsKey = ({
  companyId,
  archived,
  budget,
  search,
  type,
}: FetchSubaccountsParams) => {
  const params: (string | number)[] = [`companyId:${companyId}`];
  if (type) {
    params.push(`type:${type}`);
  }
  if (archived) {
    params.push('archived');
  }
  if (budget) {
    params.push(`budget:${budget}`);
  }
  if (search) {
    params.push(`search:${search}`);
  }

  return params.join('|');
};

const subaccountsItemsSlice = createSlice({
  name: 'listItems',
  reducers: {},
  initialState: initialSubaccountsState,
  extraReducers: builder => {
    builder.addCase(fetchSubaccounts.fulfilled, (state, action) => {
      state = { ...state, ...action.payload.entities.subaccounts };
      return state;
    });
    builder.addCase(patchSubaccount, (state, action) => {
      const { subaccountId } = action.payload;
      state[subaccountId] = {
        ...state[subaccountId],
        ...action.payload,
        variant: 'list',
      };
      return state;
    });
    builder.addMatcher(
      isAnyOf(fetchSubaccount.fulfilled, addSubaccountOwner.fulfilled),
      (state, action) => {
        const { data } = action.payload;
        state[data.subaccountId] = { ...state[data.subaccountId], ...data };
        state[data.subaccountId].variant = 'list';
        return state;
      },
    );
  },
});

const subaccountsItemsFilterKeysSlice = createSlice({
  name: 'filterKeys',
  reducers: {},
  initialState: { key: '' },
  extraReducers: builder => {
    builder.addCase(fetchSubaccounts.fulfilled, (state, action) => {
      const { currentKey } = action.payload;
      state = { key: currentKey || '' };
      return state;
    });
  },
});

const initialSubaccountDetailsState: Record<string, SubaccountDetails> = {};

const subaccountsDetailsSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState: initialSubaccountDetailsState,
  extraReducers: builder => {
    builder.addCase(patchSubaccount, (state, action) => {
      const { subaccountId } = action.payload;
      state[subaccountId] = {
        ...state[subaccountId],
        ...action.payload,
        variant: 'details',
      };
      return state;
    });
    builder.addMatcher(
      isAnyOf(fetchSubaccount.fulfilled, addSubaccountOwner.fulfilled),
      (state, action) => {
        const { data } = action.payload;
        state[data.subaccountId] = { ...state[data.subaccountId], ...data };
        return state;
      },
    );
  },
});

const subaccountsListSlice = createSlice({
  name: 'list',
  reducers: {},
  initialState: {} as Record<string, string[]>,
  extraReducers: builder => {
    builder.addCase(fetchSubaccounts.fulfilled, (state, action) => {
      const key = getSubaccountsKey(action.meta.arg);
      state[key] = action.payload.data;
      return state;
    });
  },
});

const subaccountsStatus = createAsyncThunkStatusSlice(fetchSubaccounts, {
  getKey: getSubaccountsKey,
});

const subaccountStatus = createAsyncThunkStatusSlice(fetchSubaccount, {
  getKey: (payload: FetchSubaccountParams) => payload.subaccountId,
});

const subaccounts = combineReducers({
  listItems: subaccountsItemsSlice.reducer,
  items: subaccountsDetailsSlice.reducer,
  itemStatus: subaccountStatus.reducer,
  list: subaccountsListSlice.reducer,
  status: subaccountsStatus.reducer,
  filterKeys: subaccountsItemsFilterKeysSlice.reducer,
});

export default subaccounts;
