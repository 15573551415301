import ReconnectingWebSocket from 'reconnecting-websocket';
import { Dispatch } from 'redux';
import config from 'shared/utils/config';
import defaultGetToken, {
  getExpires as defaultGetExpires,
} from 'shared/utils/user/getToken';
import { Notification } from '../Notification';
import actions from './websocketActions';

interface WsOpts {
  url?: string;
  wsUrl?: string;
  debug?: boolean;
  logger?: typeof console.log;
  getToken?: () => string | undefined;
  getExpires?: () => string | undefined;
}

let ws: ReconnectingWebSocket | undefined;

let isDebug: boolean = false;

type WsNotification = Omit<Notification, 'notificationId'> & { id: string };

const defaultLogger: typeof console.log = (...args) =>
  isDebug && console.log(...args);
let logDebug = defaultLogger;

const sendCommand = (command: string, parameters: any) => {
  if (!ws || ws.readyState !== ws.OPEN) {
    throw new Error('Websocket not open');
  } else {
    ws.send(JSON.stringify({ command, parameters }));
    logDebug(`[WS] Sent ${command}`, parameters);
  }
};

const authenticate = async (dispatch: Dispatch<any>, opts: WsOpts) => {
  const getToken = opts.getToken || defaultGetToken;
  const getExpires = opts.getExpires || defaultGetExpires;
  const token = await getToken();
  const expires = await getExpires();
  try {
    sendCommand('authenticate', { token, expires });
    // sendCommand('pop', { token });
  } catch (ex) {
    console.error(ex);
    // ws.close();
  }
};

const handleMessage = (dispatch: Dispatch<any>) => (e: { data: string }) => {
  logDebug('[WS] Message', e.data);
  const data = JSON.parse(e.data) as WsNotification;
  dispatch(
    actions.message({
      notificationId: data.id,
      // @ts-ignore
      createdAt: new Date().toISOString(),
      ...data,
    }),
  );

  // toDo: remove this if there is no problems with that
  // sendCommand('confirm_notification_received', {
  //   id: data.id,
  // });
};

const addListeners = (dispatch: Dispatch<any>, opts: WsOpts = {}) => {
  if (!ws) {
    return;
  }
  ws.addEventListener('open', () => {
    logDebug(`[WS] Connected to ${opts.url || config.wsUrl}`);
    authenticate(dispatch, opts);
  });
  ws.addEventListener('close', () => {
    logDebug('[WS] Closed');
  });
  ws.addEventListener('error', () => {
    logDebug('[WS] Error');
  });
  ws.addEventListener('message', handleMessage(dispatch));
};

const initWebsocket = (dispatch: Dispatch<any>, opts: WsOpts) => {
  const { debug, url, logger } = opts;
  isDebug = !!debug;
  logDebug = logger || defaultLogger;
  ws = new ReconnectingWebSocket(url || config.wsUrl, undefined, { debug });
  addListeners(dispatch, opts);
};

export const close = () => {
  if (ws && ws.close) {
    ws.close();
  }
};

export default initWebsocket;
