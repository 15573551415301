import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import fetchUser from '../users/fetch';

export const names = asyncNames('FETCH_GROUP');
export const actions = createActions(names);

const fetchGroup = (params, full = true) => async dispatch => {
  dispatch(actions.request(params));
  try {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { company_id } = params;
    const response = await axios(
      AppRouting.generate(
        'api_group_get_group',
        {
          ...params,
        },
        true,
      ),
    );
    await dispatch(actions.success({ ...params, group: response.data }));

    if (full && response.data.parent_id) {
      dispatch(
        fetchGroup({
          company_id,
          group_id: response.data.parent_id,
        }),
      );
    }
    if (full && response.data.user_id) {
      dispatch(
        fetchUser({
          company_id,
          user_id: response.data.user_id,
        }),
      );
    }
    return response.data;
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||fetchFailure'),
    );
    dispatch(actions.failure({ error, ...params }));
    const ex = new Error(message);
    ex.response = error.response;
    throw ex;
  }
};

export default fetchGroup;
