import { names } from 'shared/actions/purchases/fetchPurchases';
import makePurchaseListKey from './makePurchaseListKey';

const list = (state = null, { type, payload }) => {
  if (type === names.success) {
    const { data } = payload.data;
    if (payload.params.page > 1) {
      return [...state, ...data];
    }
    return data;
  }
  return state;
};

const lists = (state = {}, action) => {
  if (action.type === names.success) {
    const { params } = action.payload;
    const key = makePurchaseListKey({
      mode: params.company_id || 'private',
      employee: params.employee,
      types: params.types,
    });
    return { ...state, [key]: list(state[key], action) };
  }
  return state;
};

export default lists;
