import { PurchaseProduct } from 'shared/types/Purchase';
import { BaseProduct } from 'shared/types/Product';
import { UserData } from 'shared/types/User';

export const getProductsData = (products: BaseProduct[] | null) => {
  if (!products) {
    return null;
  }
  return products.map((product: BaseProduct) => {
    return {
      item_id: product.id,
      item_name: product.name,
      item_brand: product.partnerName,
      item_category: product.categorySlug,
      category_name: product.categorySlug,
      currency: 'PLN',
    };
  });
};

export const getProductData = (product: BaseProduct | PurchaseProduct) => {
  return {
    item_id: product?.id,
    item_name: product?.name,
    item_brand: product?.partnerName,
    item_category: product?.categorySlug,
    category_name: product?.categorySlug,
    currency: 'PLN',
  };
};

export const getUserData = (userData: UserData) => {
  return {
    birthDate: userData?.birthDate,
    city: userData?.city,
    companyName: userData?.companyName,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    gender: userData?.gender,
    userId: userData?.userId,
    locale: userData?.locale,
    country: userData?.address?.country,
  };
};
