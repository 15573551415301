import { names } from 'shared/actions/challengeCompanyGroupList';
import { combineReducers, AnyAction } from 'redux';
import { keyBy } from 'lodash';
import makeStatusReducer, { StatusState } from './status';

export interface ChallengeCompany {
  id: number;
  shortName: string;
  permissions: string[];
}

export interface ChallengeCompanyGroupListItem {
  challengeCompanyGroupId: string;
  name: string;
  companies: ChallengeCompany[];
  image: string | null;
  commonPermissions: string[];
}

const list = (state = {}, action: AnyAction) => {
  if (action.type === names.success) {
    const payload = [...action.payload];

    const challengeGroupsList = payload.map(
      (company: ChallengeCompanyGroupListItem) => {
        // const arr: string[] = [];
        // company.companies.forEach((item: ChallengeCompany) => {
        //   arr.push(...item.permissions);
        // });
        // eslint-disable-next-line no-param-reassign
        // company.commonPermissions = uniq(arr);
        return company;
      },
    );

    return keyBy(challengeGroupsList, 'challengeCompanyGroupId');
  }
  return state;
};

const ids = (state = [], action: AnyAction) => {
  if (action.type === names.success) {
    return action.payload.map(
      (item: ChallengeCompanyGroupListItem) => item.challengeCompanyGroupId,
    );
  }
  return state;
};

const options = (state = [], action: AnyAction) => {
  if (action.type === names.success) {
    return action.payload.map((item: ChallengeCompanyGroupListItem) => {
      return {
        label: item.name,
        value: item.challengeCompanyGroupId,
      };
    });
  }
  return state;
};

export interface ChallengeCompanyGroupReducerState {
  list: Record<string, ChallengeCompanyGroupListItem>;
  status: StatusState;
  ids: string[];
  options: {
    label: string;
    value: string;
  }[];
}

const challengeCompanyGroup = combineReducers({
  list,
  status: makeStatusReducer(names),
  ids,
  options,
});

export default challengeCompanyGroup;
