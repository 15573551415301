import { Action, combineReducers } from 'redux';
import { MetaState } from 'shared/types/reducers/MetaState';
import makeStatusReducer from 'shared/reducers/status';
import {
  fetchDocumentsCategoriesNames,
  FetchDocumentsCategoriesSuccessAction,
  DocumentsCategoriesSuccessAction,
} from './categoriesActions';
import { DocumentCategory, DocumentStatusConfig } from './DocumentWrapper';
import {
  fetchDocumentNames,
  FetchDocumentSuccessAction,
  fetchDocumentsWrappersNames,
  FetchDocumentsWrappersSuccessAction,
} from './documentsWrappersActions';

export const getDocumentCategoriesKey = ({
  companyId,
}: {
  companyId?: number;
}) => {
  const parts: string[] = [];

  if (!companyId) {
    parts.push(`user`);
  }

  if (companyId) {
    parts.push(`companyId:${companyId}`);
  }

  return parts.join('|');
};

const items = (
  state: Record<string, DocumentCategory> = {},
  action: Action<any>,
) => {
  if (
    action.type === fetchDocumentsCategoriesNames.success ||
    action.type === fetchDocumentNames.success ||
    action.type === fetchDocumentsWrappersNames.success
  ) {
    const { payload } = action as
      | FetchDocumentsCategoriesSuccessAction
      | FetchDocumentSuccessAction
      | FetchDocumentsWrappersSuccessAction;
    return {
      ...state,
      ...payload.response.entities.categories,
    };
  }
  return state;
};

const list = (state: string[] = [], action: Action<any>) => {
  if (action.type === fetchDocumentsCategoriesNames.success) {
    const { payload } = action as FetchDocumentsCategoriesSuccessAction;
    return payload.response.data;
  }
  return state;
};

const meta = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === fetchDocumentsCategoriesNames.success) {
    const { payload } = action as FetchDocumentsCategoriesSuccessAction;
    const { data, entities, ...rest } = payload.response;
    return rest;
  }
  return state;
};

const listReducer = combineReducers({
  list,
  meta,
  status: makeStatusReducer(fetchDocumentsCategoriesNames),
});

export type CategoriesList = ReturnType<typeof listReducer>;

const documentsCategoriesActions = Object.values(fetchDocumentsCategoriesNames);

const lists = (
  state: Record<string, CategoriesList> = {},
  action: Action<any>,
) => {
  if (documentsCategoriesActions.includes(action.type)) {
    const { payload } = action as DocumentsCategoriesSuccessAction;

    const key = getDocumentCategoriesKey(payload);
    return {
      ...state,
      [key]: listReducer(state[key], action),
    };
  }
  return state;
};

const statuses = (
  state: Record<string, DocumentStatusConfig> = {},
  action: Action<any>,
) => {
  if (
    action.type === fetchDocumentsCategoriesNames.success ||
    action.type === fetchDocumentNames.success ||
    action.type === fetchDocumentsWrappersNames.success
  ) {
    const { payload } = action as
      | FetchDocumentsCategoriesSuccessAction
      | FetchDocumentSuccessAction
      | FetchDocumentsWrappersSuccessAction;
    const { entities } = payload.response;
    return {
      ...state,
      ...entities.statuses,
    };
  }
  return state;
};

export type ItemsState = ReturnType<typeof items>;

const categories = combineReducers({
  items,
  statuses,
  lists,
});

export type DocumentCategoriesState = ReturnType<typeof categories>;

export default categories;
