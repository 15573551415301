import { names } from 'shared/actions/company';
import { names as updateReportNames } from 'shared/actions/company/updateReportDay';
import { addSingleInterval } from 'shared/utils/zfss/minMaxIntervals';

const initial = null;

const company = (state = initial, { type, payload }) => {
  if (type === names.set) {
    return payload ? addSingleInterval(payload) : state;
  }
  if (type === updateReportNames.success) {
    return { ...state, operationReportPeriodDay: payload.day };
  }
  return state;
};

export default company;
