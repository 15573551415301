import axios from 'axios';
import get from 'lodash/get';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('FETCH_PRODUCT');
export const actions = createActions(names);

const fetchProduct = params => async dispatch => {
  dispatch(actions.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_get_product',
        {
          ...params,
        },
        true,
      ),
    );
    await dispatch(actions.success({ ...params, product: response.data }));

    return response.data;
  } catch (ex) {
    const message = get(
      ex,
      'response.data.errors.global',
      i18next.t('products|||fetchProductError'),
    );
    dispatch(actions.failure({ error: ex, ...params }));
    const error = new Error(message);
    error.response = ex.response;
    throw error;
  }
};

export default fetchProduct;
