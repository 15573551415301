import { names } from 'shared/actions/sidenav';

const initial = false;
// const initial = false;

const sidenav = (state = initial, action) => {
  if (action.type === names.set) {
    return action.payload;
  }
  return state;
};

export default sidenav;
