import { asyncNames, createActions, getFetchAction } from './utils';
// import { normalize } from 'normalizr';

export const names = {
  tileNative: asyncNames('FETCH_TILE_NATIVE'),
  tileCatalog: asyncNames('FETCH_TILE_CATALOG'),
};

export const actions = {
  tileNative: createActions(names.tileNative),
  tileCatalog: createActions(names.tileCatalog),
};

const tileNative = getFetchAction(actions.tileNative, 'api_injectable_html_get_specified', false);
const tileCatalog = getFetchAction(actions.tileCatalog, 'api_injectable_html_get_specified', false);

const fetch = {
  tileNative: (params = {}) => tileNative({ ...params, type: 'tile_native' }),
  tileCatalog: (params = {}) => tileCatalog({ ...params, type: 'tile_catalog' }),
};

export default fetch;
