import axios from 'axios';
// import { normalize } from 'normalizr';
import { groupsSchema } from 'shared/schemas/groups';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

const PER_PAGE = 50;

export const names = asyncNames('FETCH_GROUPS');

export const groups = createActions(names);
/* eslint-disable @typescript-eslint/camelcase */
const shouldLoad = ({ company_id, page = 1 }, getState) => {
  const state = getState();
  const list = get(state, `groups.lists.${company_id}`);
  /* eslint-enable @typescript-eslint/camelcase */
  if (!list) {
    return true;
  }
  const { meta, status } = list;
  if (status.loading || status.error) {
    return false;
  }
  if (!status.loaded) {
    return true;
  }
  if (page > meta.pages) {
    return true;
  }
  return false;
};

export const normalizeGroups = data => {
  const groupsMap = {};
  const { length } = data.data;
  const list = flatMap(data.data, group => {
    groupsMap[group.id] = group;
    return !group.parent_id ? [group.id] : [];
  });
  Object.values(groupsMap).forEach(group => {
    if (group.parent_id) {
      const parent = groupsMap[group.parent_id];
      if (!parent) {
        list.push(group.id);
        return;
      }
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(group.id);
    }
  });
  return {
    result: {
      ...data,
      length,
      data: list,
    },
    entities: {
      ...data.entities,
      groups: groupsMap,
    },
  };
};

const fetchGroup = (params, forceRefresh = false, opts) => async (
  dispatch,
  getState,
) => {
  if (!forceRefresh && !shouldLoad(params, getState)) {
    return {};
  }
  dispatch(groups.request({ params }));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_group_get_groups',
        {
          ...params,
          max_per_page: params.max || PER_PAGE,
        },
        true,
      ),
      opts,
    );
    const data = normalizeGroups(response.data, groupsSchema);
    await dispatch(groups.success({ params, data }));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return error;
    }
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||fetchFailure'),
    );

    dispatch(notify({ message }));
    dispatch(groups.failure({ params, error }));
    return error;
  }
};

export default fetchGroup;
