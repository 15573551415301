import i18next from 'i18next';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

export const langs = ['pl', 'en'];

// const req = require.context(
//   'file-loader?emitFile=false&name=[name].[hash:5].[ext]!',
//   true, // Load files recursively. Pass false to skip recursion.
// );

// const map = {};
// langs.forEach(lang => {
//   // eslint-disable-next-line
//   map[lang] = require(`../../locales/${lang}/translation.json`);
// });
// const getPath = (file, lang) => `/locales/${lang}/${file}`;

window.i18next = i18next;

const initI18next = locale =>
  new Promise(resolve => {
    i18next
      .use(Fetch)
      .use(initReactI18next)
      .init(
        {
          lng: locale,
          compatibilityJSON: 'v3',
          backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
          },
          fallbackLng: 'pl',
          load: 'current',
          keySeparator: '|||',
          nsSeparator: ':',
          interpolation: {
            escapeValue: false,
          },
          react: {
            useSuspense: true,
          },
        },
        () => {
          // HACK: react-i18next on dev reload
          if (!window.i18next.options) {
            window.i18next.options = {};
          }
          // const trans = window.i18next.t;
          // window.i18next.t = (...args) => {
          //   return trans(...args);
          // }
          resolve();
        },
      );
  });

export default initI18next;
