import { createAction } from 'redux-actions';
import { createActions, asyncNames } from 'shared/actions/utils';
import { MetaState } from 'shared/types/reducers/MetaState';
import { DocumentWrapper, DocumentWrapperResponse } from './DocumentWrapper';

export const fetchDocumentsWrappersNames = asyncNames(
  'FETCH_DOCUMENTS_WRAPPERS',
);

export const fetchDocumentNames = asyncNames('FETCH_DOCUMENT');

export const updateDocumentWrapperName = 'UPDATE_DOCUMENT_WRAPPER';

export const updateDocumentWrapperAction = createAction(
  updateDocumentWrapperName,
);

export const fetchDocumentsWrappersActions = createActions(
  fetchDocumentsWrappersNames,
);

export const fetchDocumentActions = createActions(fetchDocumentNames);

export interface UpdateDocumentWrapperAction {
  type: typeof updateDocumentWrapperName;
  payload: DocumentWrapper;
}

export interface FetchDocumentSuccessAction {
  type: typeof fetchDocumentNames.success;
  payload: FetchDocumentSuccessActionPayload;
}

export interface DocumentsWrappersSuccessActionPayload {
  categoryId: number | string;
  companyId?: number;
  filter?: string;
}

export interface DocumentsWrappersSuccessAction {
  type: string;
  payload: DocumentsWrappersSuccessActionPayload;
}

export interface FetchDocumentsWrappersSuccessActionPayload
  extends DocumentsWrappersSuccessActionPayload {
  response: {
    data: string[];
    entities: {
      wrappers: Record<string, DocumentWrapper>;
    } & DocumentWrapperResponse['entities'];
  } & MetaState;
}

export interface FetchDocumentsWrappersSuccessAction
  extends DocumentsWrappersSuccessAction {
  type: typeof fetchDocumentsWrappersNames.success;
  payload: FetchDocumentsWrappersSuccessActionPayload;
}

export interface FetchDocumentsSuccessAction {
  type: typeof fetchDocumentNames.success;
  payload: FetchDocumentSuccessActionPayload;
}

export interface FetchDocumentSuccessActionPayload {
  response: DocumentWrapperResponse;
}
