import { createActions, asyncNames } from 'shared/actions/utils';
import { DateRange } from 'shared/types/Dates';
import Locales from 'shared/types/Locales';
import {
  ChartGranulation,
  ChartSection,
  GenderChartSection,
  PieChartSection,
  PopularityChartSection,
} from './ChartTypes';
import { SectionsResponse } from './useInsightsDashboard';

export const insightsChartActionsNames = asyncNames('FETCH_INSIGHTS_CHART');

export const insightsHomeActionsNames = asyncNames('FETCH_INSIGHTS_HOME');

export const insightsChartActions = createActions(insightsChartActionsNames);

export const insightsHomeActions = createActions(insightsHomeActionsNames);

export interface InsightActionParams {
  type: string;
  companyId: number;
  granulation: ChartGranulation;
  periods?: number;
  locale: Locales;
  range: DateRange;
}

export interface InsightChartAction {
  type: string;
  payload: {
    params: InsightActionParams;
  };
}
export interface InsightHomeAction {
  type: string;
  payload: {
    params: { company: number };
  };
}

export interface InsightChartSuccessAction extends InsightChartAction {
  type: typeof insightsChartActionsNames.success;
  payload: {
    data:
      | ChartSection
      | GenderChartSection
      | PopularityChartSection
      | PieChartSection;
    params: InsightActionParams;
  };
}

export interface InsightHomeSuccessAction extends InsightChartAction {
  type: typeof insightsHomeActionsNames.success;
  payload: {
    data: SectionsResponse;
    params: InsightActionParams;
  };
}
