import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import axios from 'axios';
import { routing as AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { defaultPerPage } from 'shared/utils/configStatic';
import { KeyDictionary } from 'shared/types/IdDictionary';
import errorLogger from 'shared/utils/errorLogger';
import { createActions, asyncNames } from 'shared/actions/utils';
import {
  Challenge,
  ChallengeUserData,
  ChallengeListResponse,
  ChallengesCounters,
  ChallengesCompanyCounters,
  ChallengesFilters,
  ChallengesHomeResponse,
  ChallengeStats,
  ChallengeScoresResponse,
  ChallengeParticipantsResponse,
  ChallengeHomeListResponse,
  ChallengeParticipant,
  ChallengeStatChart,
} from './Challenge';
import {
  Activity,
  Training,
  TrainingsFilters,
  TrainingsResponse,
} from './Trainings';

export const challengesHomeActionNames = asyncNames('FETCH_CHALLENGES_HOME');

export const fetchChallengesHomeActions = createActions(
  challengesHomeActionNames,
);

export const fetchChallengesActionsNames = asyncNames('FETCH_CHALLENGES');

export const setChallengesCountersName = 'SET_CHALLENGES_COUNTERS';

export const setChallengesCounters = createAction(setChallengesCountersName);

export const setChallengesCompanyCountersName =
  'SET_CHALLENGES_COMPANY_COUNTERS';

export const setChallengesCompanyCounters = createAction(
  setChallengesCompanyCountersName,
);

export const setChallengeName = 'SET_CHALLENGE';

export const setChallengeAction = createAction(setChallengeName);

export const fetchChallengesActions = createActions(
  fetchChallengesActionsNames,
);

export const joinChallengeName = 'JOIN_CHALLENGE';

export const joinChallengeAction = createAction(joinChallengeName);

export const leaveChallengeName = 'LEAVE_CHALLENGE';

export const leaveChallengeAction = createAction(leaveChallengeName);

export const setChallengeStatsName = 'SET_CHALLENGE_STATS';

export const setChallengeStats = createAction(setChallengeStatsName);

export const setScoresActionName = 'SET_CHALLENGE_SCORES';

export const setScoresAction = createAction(setScoresActionName);

export const setParticipantsActionName = 'SET_CHALLENGE_PARTICIPANTS';

export const setParticipantsAction = createAction(setParticipantsActionName);

export const fetchActivitiesNames = asyncNames('FETCH_ACTIVITIES');

export const fetchActivitiesActions = createActions(fetchActivitiesNames);

export const fetchTrainingsNames = asyncNames('FETCH_TRAININGS');

export const fetchTrainingsActions = createActions(fetchTrainingsNames);

export const updateTrainingActionName = 'UPDATE_TRAINING';

export const updateTraining = createAction(updateTrainingActionName);

export const deleteTrainingActionName = 'DELETE_TRAINING';

export const deleteTraining = createAction(deleteTrainingActionName);

export const updateChallengeActionName = 'UPDATE_CHALLENGE';

export const updateChallenge = createAction(updateChallengeActionName);

export const createChallengeActionName = 'CREATE_CHALLENGE';

export const createChallenge = createAction(createChallengeActionName);

export const deleteChallengeActionName = 'DELETE_CHALLENGE';

export const deleteChallenge = createAction(deleteChallengeActionName);

export const setTrainingsStatsName = 'SET_TRAININGS_STATS';

export const setTrainingsStats = createAction(setTrainingsStatsName);

export interface ChallengesHomeSuccessAction {
  type: typeof challengesHomeActionNames.success;
  payload: {
    data: ChallengesHomeResponse;
    filters: ChallengesFilters;
  };
}

export interface FetchChallengesSuccessAction {
  type: typeof fetchChallengesActionsNames.success;
  payload: {
    data: ChallengeListResponse;
    filters: ChallengesFilters;
  };
}

export interface SetChallengesCountersAction {
  type: typeof setChallengesCountersName;
  payload: {
    data: ChallengesCounters;
    companyId: number;
  };
}

export interface SetChallengesCompanyCountersAction {
  type: typeof setChallengesCompanyCountersName;
  payload: ChallengesCompanyCounters;
}

export interface SetChallengeAction {
  type: typeof setChallengeName;
  payload: Challenge;
}

export interface JoinChallengeAction {
  type: typeof joinChallengeName;
  payload: ChallengeUserData;
}

export interface LeaveChallengeAction {
  type: typeof leaveChallengeName;
  payload: {
    challengeId: number;
    employeeId: number;
    stats: ChallengeStats;
  };
}

export interface SetChallengeStatsAction {
  type: typeof setChallengeStatsName;
  payload: ChallengeUserData;
}

export interface setChallengesScoresAction {
  type: typeof setScoresActionName;
  payload: {
    entities: ChallengeScoresResponse['entities'];
  };
}

export interface setChallengesParticipantsAction {
  type: typeof setParticipantsActionName;
  payload: {
    entities: ChallengeParticipantsResponse['entities'] & {
      participants: KeyDictionary<ChallengeParticipant>;
    };
  };
}

export interface FetchActivitiesSuccessAction {
  type: typeof fetchActivitiesNames.success;
  payload: {
    data: {
      [type: string]: Activity;
    };
    list: string[];
  };
}

export interface FetchTrainingsSuccessAction {
  type: typeof fetchTrainingsNames.success;
  payload: {
    data: TrainingsResponse;
    params: TrainingsFilters;
  };
}

export interface UpdateTrainingSuccessAction {
  type: typeof updateTrainingActionName;
  payload: {
    update: Partial<Training>;
    trainingId: number;
  };
}

export interface DeleteTrainingSuccessAction {
  type: typeof deleteTrainingActionName;
  payload: {
    trainingId: number;
  };
}

export interface UpdateChallengeSuccessAction {
  type: typeof updateChallengeActionName;
  payload: {
    update: Partial<Challenge>;
    challengeId: number;
  };
}

export interface CreateChallengeSuccessAction {
  type: typeof createChallengeActionName;
  payload: Challenge;
}

export interface DeleteChallengeSuccessAction {
  type: typeof deleteChallengeActionName;
  payload: {
    challengeId: number;
  };
}

export interface SetTrainingsStatsAction {
  type: typeof setTrainingsStatsName;
  payload: {
    stats: ChallengeStatChart[];
  };
}

export const fetchChallengesHome =
  ({
    companyId,
    isCompanyChallenge,
  }: {
    companyId: number;
    isCompanyChallenge?: boolean;
  }) =>
  async (
    dispatch: Dispatch<any>,
    // getState: () => AppState,
  ) => {
    try {
      dispatch(
        fetchChallengesHomeActions.request({
          companyId,
          filters: { isCompanyChallenge },
        }),
      );
      const opts: RoutingParams = {
        max_per_page: defaultPerPage,
        company: companyId,
      };

      if (isCompanyChallenge) {
        opts.scope = 'company';
      }

      const { data } = await axios.get<ChallengeHomeListResponse>(
        AppRouting.generate('api_challenge_home', opts),
      );
      dispatch(
        fetchChallengesHomeActions.success({
          data,
          companyId,
          filters: { isCompanyChallenge },
        }),
      );
    } catch (ex) {
      if (axios.isCancel(ex)) {
        dispatch(
          fetchChallengesHomeActions.cancel({
            error: ex,
            companyId,
            filters: { isCompanyChallenge },
          }),
        );
        return;
      }
      errorLogger(ex);
      dispatch(
        fetchChallengesHomeActions.failure({
          error: ex,
          companyId,
          filters: { isCompanyChallenge },
        }),
      );
      throw ex;
    }
  };
