import { names as fetchNames } from './fetch';
import { names as actionNames } from './actions';
import { names as fetchWhipRound } from './fetchWhipRound';

// eslint-disable-next-line import/prefer-default-export
export const names = {
  ...actionNames,
  fetch: fetchNames,
  single: fetchWhipRound,
};
