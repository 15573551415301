import axios from 'axios';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('ADD_EMPLOYEE');
export const actions = createActions(names);

const addEmployee = params => async dispatch => {
  const { data, companyId } = params;
  dispatch(actions.request(params));
  const url = AppRouting.generate(
    'api_employees_invite',
    {
      company_id: companyId,
    },

    true,
  );
  try {
    const response = await axios.post(url, data);
    await dispatch(actions.success({ params, users: response.data }));
    return response?.data;
  } catch (error) {
    dispatch(actions.failure({ error, ...params }));
    throw error;
  }
};

export default addEmployee;
