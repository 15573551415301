import { combineReducers } from 'redux';
import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';
import { names as purchaseNames } from 'shared/actions/purchases/fetchPurchase';
import { Action } from 'redux-actions';
import produce from 'immer';
import { PurchaseDocumentConfig } from 'shared/types/Document';
import { names, SubmitEditAction } from './submitActions';

export interface SubmitItems {
  [id: number]: PurchaseDocumentConfig;
}

export interface SubmitsState {
  items: SubmitItems;
}

const items = (state: SubmitItems = {}, action: Action<any>) => {
  if (action.type === purchasesNames.success) {
    return {
      ...state,
      ...action.payload?.data?.entities?.submits,
    };
  }
  if (action.type === purchaseNames.success) {
    return {
      ...state,
      ...action.payload?.entities?.submits,
    };
  }
  if (action.type === names.edit) {
    const { payload } = action as SubmitEditAction;
    return produce(state, draftState => {
      /* eslint-disable no-param-reassign */
      draftState[payload.submit.submitId].data = payload.data;
      draftState[payload.submit.submitId].originalConfiguration =
        payload.submit.configuration;
      /* eslint-enable no-param-reassign */
    });
  }
  return state;
};

const submits = combineReducers({
  items,
});

export default submits;
