import produce from 'immer';
import { combineReducers } from 'redux';
import { Notification } from 'shared/modules/notifications/Notification';
import makeStatusReducer from 'shared/reducers/status';
import {
  names,
  WebsocketMessageAction,
  HandleSurchargeAction,
  NotificationListSuccessAction,
  NotificationsMetaState,
  MarkMessagesReadAction,
  MarkAllMessagesReadAction,
} from './websocketActions';

const messages = (
  state: Notification[] = [],
  { type, payload }: WebsocketMessageAction,
) => {
  if (type === names.message) {
    return [...state, payload];
  }
  return state;
};

const surcharge = (state = true, { type, payload }: HandleSurchargeAction) => {
  if (type === names.handleSurcharge) {
    return payload;
  }
  return state;
};

const list = (
  state: string[] = [],
  action: NotificationListSuccessAction | WebsocketMessageAction,
): string[] => {
  if (action.type === names.success) {
    const { payload } = action as NotificationListSuccessAction;
    const { params, data } = payload;
    return params.page === 1 ? data : [...state, ...data];
  }
  if (action.type === names.message) {
    const { payload } = action as WebsocketMessageAction;
    return [payload.notificationId, ...state];
  }
  return state;
};

const items = (
  state: Record<string, Notification> = {},
  action:
    | NotificationListSuccessAction
    | WebsocketMessageAction
    | MarkMessagesReadAction
    | MarkAllMessagesReadAction,
) => {
  if (action.type === names.message) {
    const { payload } = action as WebsocketMessageAction;
    return {
      ...state,
      [payload.notificationId]: payload,
    };
  }
  if (action.type === names.success) {
    const { payload } = action as NotificationListSuccessAction;
    return {
      ...state,
      ...payload.entities.notifications,
    };
  }
  if (action.type === names.markRead) {
    const { payload } = action as MarkMessagesReadAction;

    return produce(state, draft => {
      payload.messages.forEach(msg => {
        // eslint-disable-next-line no-param-reassign
        draft[msg.notificationId].read = true;
      });
    });
  }
  if (action.type === names.markAllRead) {
    return produce(state, draft => {
      Object.keys(draft).forEach(id => {
        // eslint-disable-next-line no-param-reassign
        draft[id].read = true;
      });
    });
  }
  return state;
};

const metaReducer = (
  state: NotificationsMetaState | null = null,
  action:
    | NotificationListSuccessAction
    | MarkMessagesReadAction
    | MarkAllMessagesReadAction
    | WebsocketMessageAction,
): NotificationsMetaState | null => {
  if (action.type === names.success) {
    const { payload } = action as NotificationListSuccessAction;
    const { meta } = payload;
    return meta;
  }

  if (action.type === names.message) {
    const { payload } = action as WebsocketMessageAction;
    if (!state || payload.read) {
      return state;
    }
    return {
      ...state,
      unreadCount: state.unreadCount + 1,
    };
  }

  if (action.type === names.markAllRead) {
    if (!state) {
      return state;
    }
    return {
      ...state,
      unreadCount: 0,
    };
  }

  if (action.type === names.markRead) {
    const { payload } = action as MarkMessagesReadAction;

    if (!state) {
      return state;
    }
    return {
      ...state,
      unreadCount: Math.max(
        state.unreadCount - payload.messages.filter(msg => !msg.read).length,
        0,
      ),
    };
  }

  return state;
};

const websocket = combineReducers({
  messages,
  list,
  items,
  status: makeStatusReducer(names),
  meta: metaReducer,
  surcharge,
});

export type WebsocketState = ReturnType<typeof websocket>;

export default websocket;
