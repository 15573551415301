import parse from 'date-fns/parseISO';
import format from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';
import en from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';

const locales = {
  pl,
  en,
};

let locale = 'pl';

export const DEFAULT_TIMEZONE = 'Europe/Warsaw';

// moment().format('LT');   // 20:17
// moment().format('LTS');  // 20:17:31
// moment().format('L');    // 28.09.2018
// moment().format('l');    // 28.9.2018
// moment().format('LL');   // 28 września 2018
// moment().format('ll');   // 28 wrz 2018
// moment().format('LLL');  // 28 września 2018 20:17
// moment().format('lll');  // 28 wrz 2018 20:17
// moment().format('LTll');  // 20:17, 28 wrz 2018
// moment().format('LLLL'); // piątek, 28 września 2018 20:17
// moment().format('llll');  // pt, 28 wrz 2018 20:17

const lookupPL = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'dd.MM.yyyy',
  l: 'dd.M.yyyy',
  LL: 'dd MMMM yyyy',
  ll: 'dd MMM yyyy',
  localeLLL: 'dd MMMM yyyy HH:mm',
  lll: 'dd MMM yyyy HH:mm',
  LTll: 'HH:mm, dd MMM yyyy',
  llll: 'ddd, dd MMM yyyy HH:mm',
  DM: 'dd MMMM', // 28 września
};

// moment().format('LT');   // 8:16 PM
// moment().format('LTS');  // 8:16:45 PM
// moment().format('L');    // 09/28/2018
// moment().format('l');    // 9/28/2018
// moment().format('LL');   // September 28, 2018
// moment().format('ll');   // Sep 28, 2018
// moment().format('LLL');  // September 28, 2018 8:16 PM
// moment().format('lll');  // Sep 28, 2018 8:16 PM
// moment().format('LTll');  // 8:16 PM Sep 28, 2018
// moment().format('LLLL'); // Friday, September 28, 2018 8:16 PM
// moment().format('llll'); // 09 lis 2022 14:03

const lookupEN = {
  LT: 'hh:mm',
  LTS: 'hh:mm:ss',
  L: 'dd/MM/yyyy',
  l: 'dd/M/yyyy',
  LL: 'dd, MMMM, yyyy',
  ll: 'dd, MMM, yyyy',
  localeLLL: 'dd, MMMM, yyyy hh:mm',
  lll: 'dd, MMM, yyyy hh:mm',
  LTll: 'hh:mm, dd, MMM, yyyy ',
  llll: 'ddd, dd, MMMM, yyyy hh:mm',
  DM: 'dd MMMM', // September 28
};

const lookups = {
  pl: lookupPL,
  en: lookupEN,
};

const time24h = {
  pl: true,
  en: false,
};

export const getLocale = () => locales[locale];

class MomentCompatiblity {
  constructor(from) {
    if (!from) {
      this.date = new Date();
      return;
    }
    try {
      if (from instanceof Date) {
        this.date = from;
        return;
      }
    } catch (ex) {
      this.date = parse(from);
      return;
    }
    this.date = parse(from);
  }

  toDate = () => this.date;

  format = (string, tz) => {
    const target = lookups[locale][string] || string;
    const localDate = tz ? utcToZonedTime(this.date, tz) : this.date;
    return format(localDate, target, {
      locale: getLocale(),
    });
  };
}

const moment = from => new MomentCompatiblity(from);

moment.locale = lang => {
  locale = lang;
};

moment.is24h = () => time24h[locale];

moment.formatLocale = (date, string) => {
  const target = lookups[locale][string] || string;
  return format(date, target, { locale: getLocale() });
};

export default moment;
