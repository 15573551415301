import produce from 'immer';
import { ApiZfssInterval, ZfssInterval } from 'shared/types/ZfssIntervals';
import { Company } from 'shared/types/Company';
import { CompaniesState } from 'shared/reducers/companies';

export const intervalsMinMax = (
  intervals: ApiZfssInterval[] | null,
): ZfssInterval[] | null => {
  if (!intervals) {
    return null;
  }
  return intervals.map((item, index) => ({
    ...item,
    index,
    // create readable min max values
    min: intervals[index - 1] ? intervals[index - 1].limit_value : 0,
    max: item.limit_value,
  }));
};

interface IntervalGroupsType {
  id: string;
  name: string;
  intervals: ZfssInterval[] | ApiZfssInterval[];
}

interface IntervalGroupsMinMaxType {
  id: string;
  name: string;
  intervals: ZfssInterval[];
}

export const intervalGroupsMinMax = (
  intervalGroups: IntervalGroupsType[],
): IntervalGroupsMinMaxType[] => {
  const arr: IntervalGroupsMinMaxType[] = [];

  intervalGroups.forEach(item => {
    arr.push({
      id: item.id,
      name: item.name,
      intervals: item.intervals.map((interval, index) => ({
        ...interval,
        index,
        // create readable min max values
        min: item.intervals[index - 1]
          ? item.intervals[index - 1].limit_value
          : 0,
        max: interval.limit_value,
      })),
    });
  });

  return arr;
};

export const addSingleInterval = (company: Company) =>
  produce(company, draftData => {
    if (draftData.zfss?.intervals) {
      // eslint-disable-next-line no-param-reassign
      draftData.zfss.intervals = intervalsMinMax(draftData.zfss.intervals);
    }
    if (draftData.zfss?.intervalGroups) {
      // eslint-disable-next-line no-param-reassign
      draftData.zfss.intervalGroups = intervalGroupsMinMax(
        // @ts-ignore
        draftData.zfss.intervalGroups,
      );
    }
  });

const addZfssMinMax = (companies: CompaniesState) =>
  produce(companies, draftData => {
    const keys = Object.keys(draftData);
    keys.forEach(key => {
      const company = companies[Number(key)];
      if (company.zfss?.intervals) {
        // eslint-disable-next-line no-param-reassign
        company.zfss.intervals = intervalsMinMax(company.zfss.intervals);
      }
      if (company.zfss?.intervalGroups) {
        // eslint-disable-next-line no-param-reassign
        company.zfss.intervalGroups = intervalGroupsMinMax(
          // @ts-ignore
          company.zfss.intervalGroups,
        );
      }
    });
  });

export default addZfssMinMax;
