const makeCategoryKey = ({ category, prefix }: { category: string, prefix: string }) => {
  const base = category != null ? category : 'all';
  if (!prefix) {
    return base;
  }

  return `${prefix}.${base}`;
};

export default makeCategoryKey;
