import { Reducer, Action } from 'redux';

export interface StatusState {
  loaded: boolean;
  loading: boolean;
  error: Error | null;
}

interface ActionNames {
  request: string;
  success: string;
  failure: string;
  cancel: string;
  initial?: string;
}

interface StatusAction {
  type: string;
  payload: any;
}

export const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

function assertStatusAction(
  input: Action<any> | StatusAction,
  names: ActionNames,
  alternate?: ActionNames,
): asserts input is StatusAction {
  // <-- the magic
  const actionNames = Object.values(names);
  if (actionNames.includes(input.type)) {
    return;
  }
  if (alternate) {
    const alternateNames = Object.values(alternate);
    if (alternateNames.includes(input.type)) {
      return;
    }
  }
  throw new Error('Not a StatusAction');
}

export const requestState = (
  action: StatusAction,
  prev: StatusState,
  resetLoaded?: boolean,
) => ({
  loaded: resetLoaded ? false : prev.loaded || false,
  loading: true,
  error: null,
  params: action.payload && action.payload.params,
});

export const cancelState = (action: StatusAction, prev: StatusState) => ({
  loaded: prev.loaded || false,
  loading: false,
  error: null,
  params: action.payload && action.payload.params,
});

export const successState = (action: StatusAction) => ({
  loaded: true,
  loading: false,
  error: null,
  params: action.payload.params,
});

export const errorState = (error: Error) => ({
  loaded: true,
  loading: false,
  error,
});

interface StatusReducerConfig {
  resetLoadedState?: (action: Action<any>) => boolean;
}

const makeStatusReducer = (
  names: ActionNames,
  config?: StatusReducerConfig,
): Reducer<StatusState> => (
  state = initialState,
  action: Action<any> | StatusAction,
) => {
  const resetLoaded =
    config?.resetLoadedState && config.resetLoadedState(action);
  try {
    assertStatusAction(action, names);
  } catch (ex) {
    return state;
  }

  const initialName = names.initial || 'META_INITIAL_STATES';

  switch (action.type) {
    case names.request:
      return requestState(action, state, resetLoaded);
    case names.cancel:
      return cancelState(action, state);
    case names.success:
      return successState(action);
    case names.failure:
      return errorState(action.payload?.error);
    case initialName:
      return initialState;
    default:
      return state;
  }
};

export const makeAltStatusReducer = (
  names: ActionNames,
  alternate: ActionNames,
): Reducer<StatusState> => (
  state = initialState,
  action: Action<any> | StatusAction,
) => {
  try {
    assertStatusAction(action, names, alternate);
  } catch (ex) {
    return state;
  }
  switch (action.type) {
    case names.request:
    case alternate.request:
      return requestState(action, state);

    case names.success:
    case alternate.success:
      return successState(action);

    case names.failure:
    case alternate.failure:
      return errorState(action.payload?.error || new Error('Fetch failure'));

    case names.cancel:
    case alternate.cancel:
      return cancelState(action, state);

    default:
      return state;
  }
};

export default makeStatusReducer;
