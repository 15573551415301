const colors = [
  '#5bccff',
  '#3fb549',
  '#039BE5',
  '#0097A7',
  '#FF8F00',
  '#6200EA',
  '#D32F2F',
  '#00897B',
  '#EF6C00',
  '#607D8B',
];

interface UserName {
  firstName: string;
  lastName: string;
}

const getUserColor = (user: UserName) => {
  try {
    const first = user.firstName;
    const last = user.lastName;
    const int = first[0].charCodeAt(0) + last[0].charCodeAt(0);
    const index = int % colors.length;
    return colors[index];
  } catch (ex) {
    return colors[0];
  }
};

export default getUserColor;
