import { createActions, asyncNames } from 'shared/actions/utils';
import { MetaState } from 'shared/types/reducers/MetaState';
import { DocumentCategory, DocumentStatusConfig } from './DocumentWrapper';

export const fetchDocumentsCategoriesNames = asyncNames(
  'FETCH_DOCUMENTS_CATEGORIES',
);

export const fetchDocumentsCategoriesActions = createActions(
  fetchDocumentsCategoriesNames,
);

export interface DocumentsCategoriesSuccessActionPayload {
  companyId: number;
}

export interface DocumentsCategoriesSuccessAction {
  type: string;
  payload: DocumentsCategoriesSuccessActionPayload;
}

export interface FetchDocumentsCategoriesSuccessActionPayload
  extends DocumentsCategoriesSuccessActionPayload {
  response: {
    data: string[];
    entities: {
      categories: Record<string, DocumentCategory>;
      statuses: Record<string, DocumentStatusConfig>;
    };
  } & MetaState;
}

export interface FetchDocumentsCategoriesSuccessAction
  extends DocumentsCategoriesSuccessAction {
  type: typeof fetchDocumentsCategoriesNames.success;
  payload: FetchDocumentsCategoriesSuccessActionPayload;
}
