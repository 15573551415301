import axios from 'axios';
import { asyncNames, createActions } from 'shared/actions/utils';
import errorLogger from 'shared/utils/errorLogger';

export const names = asyncNames('FETCH_KUDOS_SINGLE');
export const actions = createActions(names);

const fetchSingleKudos = (params, axiosOpts) => async dispatch => {
  dispatch(actions.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_kudos_single_get',
        {
          ...params,
          comments_count: params.commentsCount || 1,
        },
        true,
      ),
      axiosOpts,
    );
    await dispatch(
      actions.success({
        ...params,
        ...response.data,
      }),
    );

    return response.data;
  } catch (ex) {
    if (axios.isCancel(ex)) {
      dispatch(actions.cancel({ error: ex, ...params }));
      return ex;
    }
    errorLogger(ex);
    dispatch(actions.failure({ error: ex, ...params }));
    throw ex;
  }
};

export default fetchSingleKudos;
