import { combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import {
  feedbackNames,
  FetchFeedbackStatsAction,
  FetchFeedbackStatsSuccess,
} from './feedbackActions';
import { ManagerFeedbackSummary } from './FeedbackType';

export const getFeedbackStatsKey = ({
  employeeId,
  intention,
  from,
  to,
}: {
  intention: string;
  employeeId: number;
  from?: string;
  to?: string;
}) => {
  const parts = [intention, employeeId];
  if (from) {
    parts.push(`from:${from}`);
  }
  if (to) {
    parts.push(`to:${to}`);
  }
  return parts.join('|');
};

const data = (
  state: ManagerFeedbackSummary | null = null,
  action: FetchFeedbackStatsAction | FetchFeedbackStatsSuccess,
) => {
  if (action.type === feedbackNames.stats.success) {
    const { payload } = action as FetchFeedbackStatsSuccess;

    return payload.response;
  }
  return state;
};

const statsState = combineReducers({
  data,
  status: makeStatusReducer(feedbackNames.stats),
});

export type FeedbackStatState = ReturnType<typeof statsState>;

const stats = (
  state: Record<string, FeedbackStatState> = {},
  action: FetchFeedbackStatsAction,
) => {
  if (
    action.type === feedbackNames.stats.success ||
    action.type === feedbackNames.stats.request ||
    action.type === feedbackNames.stats.failure ||
    action.type === feedbackNames.stats.cancel
  ) {
    const { payload } = action;
    const key = getFeedbackStatsKey(payload);
    return {
      ...state,
      [key]: statsState(state[key], action),
    };
  }
  return state;
};

export default stats;
