import { createAction } from 'redux-actions';
import axios from 'shared/utils/axios';
import getToken from 'shared/utils/user/getToken';
import fetchCompanies from 'shared/actions/user/fetchCompanies';
import moment from 'shared/utils/moment';
import { fetchCategories } from './categories';
import productActions from './products/actions';
import { AppRouting } from '../utils/AppRouting';
import { safeLocalStorage } from '../utils/safeStorage';

export const names = {
  set: 'SET_LOCALE',
};

export const set = createAction(names.set);

const locale = ({ lang }) => async dispatch => {
  dispatch(set(lang));
  window.i18next.changeLanguage(lang);
  dispatch(productActions.clear());
  safeLocalStorage.setItem('locale', lang);
  moment.locale(lang);
  if (getToken()) {
    axios.post(AppRouting.generate('api_user_set_locale', { locale: lang }));
    dispatch(fetchCategories({ locale: lang }));
    dispatch(fetchCompanies());
  }
  // try {
  //   window.smartsupp('language', lang);
  // } catch (ex) {
  //   console.error(ex);
  // }
  return true;
};

export default locale;
