const setModes = (store, params) => {
  const debug = params.debug != null;
  const modes = {};
  if (debug) {
    modes.debug = debug;
  }

  const disablePopup = params.disablePopup != null || params.ci_mode != null;
  const ciMode = params.ci_mode != null;

  if (ciMode) {
    modes.ciMode = 1;
  }

  const appBanner = params.appBanner != null;

  const forceSlides = params.forceSlides && parseInt(params.forceSlides, 10);

  if (disablePopup) {
    modes.disablePopup = disablePopup;
  }
  if (appBanner) {
    modes.appBanner = appBanner;
  }
  if (forceSlides) {
    modes.forceSlides = forceSlides;
  }
  store.dispatch({
    type: 'MODES',
    payload: modes,
  });
  return modes;
};

export default setModes;
