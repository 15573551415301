/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface StatusType {
  pending: boolean;
  loaded: boolean;
  refresh: boolean;
  error: any | null;
}

const pendingStatus: StatusType = {
  pending: true,
  loaded: false,
  refresh: false,
  error: null,
};

const refreshStatus: StatusType = {
  pending: true,
  loaded: false,
  refresh: true,
  error: null,
};

const loadedStatus: StatusType = {
  pending: false,
  refresh: false,
  loaded: true,
  error: null,
};

const initialStatus: Record<string, StatusType> = {};

const createAsyncThunkStatusSlice = <TArg extends { refresh?: boolean }>(
  thunk: AsyncThunk<any, TArg, any>,
  opts: { getKey: (arg: TArg) => string },
) => {
  return createSlice({
    name: 'status',
    reducers: {},
    initialState: initialStatus,
    extraReducers: builder => {
      builder.addCase(thunk.pending, (state, action) => {
        const key = opts.getKey(action.meta.arg);
        state[key] = action.meta.arg.refresh ? refreshStatus : pendingStatus;
      });
      builder.addCase(thunk.fulfilled, (state, action) => {
        const key = opts.getKey(action.meta.arg);
        state[key] = loadedStatus;
      });
      builder.addCase(thunk.rejected, (state, action) => {
        const key = opts.getKey(action.meta.arg);
        state[key] = {
          ...state[key],
          error: action.payload || action.error,
        };
      });
    },
  });
};

export default createAsyncThunkStatusSlice;
