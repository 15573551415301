import createAction from 'redux-actions/lib/createAction';

export const names = {
  clear: 'PRODUCTS_CLEAR',
};

const clear = createAction(names.clear);

export default {
  clear,
};
