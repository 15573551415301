import axios from 'axios';
import getEnv from 'shared/getEnv';
import errorLogger from 'shared/utils/errorLogger';
// import logout from 'shared/actions/user/logout';

import setSDToken from 'shared/utils/user/setSDToken';
import getTokenDefault, {
  getSecurityDeviceToken,
} from 'shared/utils/user/getToken';
import { toggleOffline } from 'shared/actions/ui';
import { DEFAULT_API_VERSION } from 'shared/defaultApiVersion';
import EXCEPTION_KEYS from 'shared/types/Login';

export const setToken = async (
  { dispatch },
  getToken = getTokenDefault,
  onLogout,
  logoutAction,
  extraHeaders = {},
) => {
  try {
    axios.interceptors.request.use(
      async config => {
        const token = await getToken();
        const sdToken = await getSecurityDeviceToken();
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          ...extraHeaders,
          'X-Version': getEnv('API_VERSION') || DEFAULT_API_VERSION,
        };
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers['X-Viamobile-Token'] = token;
        }
        if (sdToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers['x-nais-sd'] = sdToken;
        }
        return config;
      },
      error => Promise.reject(error),
    );
    // Add a response interceptor
    axios.interceptors.response.use(
      response => response,
      async error => {
        const response = error?.response;
        const status = response?.status || null;
        const data = response?.data || null;

        if (!error.response && navigator && !navigator.onLine) {
          dispatch(toggleOffline(true));
        }

        if (error.response && error.response.status === 401) {
          // dont remove token since its already invalid
          dispatch(logoutAction(false));
          if (onLogout) {
            onLogout();
          }
          return Promise.reject(error);
        }

        if (status === 403 && data.key === EXCEPTION_KEYS.new_device) {
          const sdToken = response.headers['x-nais-sd'];

          if (sdToken) {
            setSDToken(sdToken);
          }
        }

        // Do something with response error
        return Promise.reject(error);
      },
    );
  } catch (ex) {
    errorLogger(ex);
  }
};

export default axios;
