import { Action, combineReducers } from 'redux';
import merge from 'lodash/merge';
import { MetaState } from 'shared/types/reducers/MetaState';
import makeStatusReducer from 'shared/reducers/status';
import { names as documentNames, DocumentPatchAction } from './documentActions';
import {
  fetchDocumentsWrappersNames,
  FetchDocumentsWrappersSuccessAction,
  DocumentsWrappersSuccessAction,
  fetchDocumentNames,
  FetchDocumentSuccessAction,
  UpdateDocumentWrapperAction,
  updateDocumentWrapperName,
} from './documentsWrappersActions';
import { DocumentWrapper } from './DocumentWrapper';

export const getDocumentsWrappersKey = ({
  companyId,
  categoryId,
  filter,
  actionRequired,
  status,
}: {
  status?: string;
  companyId?: number;
  categoryId?: string | number;
  filter?: string;
  actionRequired?: boolean;
}) => {
  const parts: string[] = [];

  if (categoryId) {
    parts.push(`categoryId:${categoryId}`);
  }
  if (status) {
    parts.push(`status:${status}`);
  }

  if (filter) {
    parts.push('filter');
  }

  if (!companyId) {
    parts.push(`user`);
  }

  if (companyId) {
    parts.push(`companyId:${companyId}`);
  }

  if (actionRequired) {
    parts.push(`actionRequired`);
  }
  return parts.join('|');
};

const items = (
  state: Record<string, DocumentWrapper> = {},
  action: Action<any>,
) => {
  if (action.type === fetchDocumentsWrappersNames.success) {
    const { payload } = action as FetchDocumentsWrappersSuccessAction;
    return {
      ...state,
      ...payload.response.entities.wrappers,
    };
  }

  if (action.type === fetchDocumentNames.success) {
    const { payload } = action as FetchDocumentSuccessAction;
    const { data } = payload.response;
    return {
      ...state,
      [data.id]: data,
    };
  }

  if (action.type === updateDocumentWrapperName) {
    const { payload } = action as UpdateDocumentWrapperAction;
    return {
      ...state,
      [payload.id]: payload,
    };
  }

  if (action.type === documentNames.patch) {
    const { payload } = action as DocumentPatchAction;
    const source = Object.values(state).find(
      item => item.entity.id === payload.id,
    );
    if (!source) {
      return state;
    }
    return {
      ...state,
      [source.id]: {
        ...source,
        entity: merge(source.entity, payload.item),
      } as DocumentWrapper,
    };
  }
  return state;
};

const list = (state: string[] = [], action: Action<any>) => {
  if (action.type === fetchDocumentsWrappersNames.success) {
    const { payload } = action as FetchDocumentsWrappersSuccessAction;
    return payload.response.data;
  }
  return state;
};

const meta = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === fetchDocumentsWrappersNames.success) {
    const { payload } = action as FetchDocumentsWrappersSuccessAction;
    const { data, entities, ...rest } = payload.response;
    return rest;
  }
  return state;
};

const listReducer = combineReducers({
  list,
  meta,
  status: makeStatusReducer(fetchDocumentsWrappersNames),
});

export type WrapperList = ReturnType<typeof listReducer>;

const documentsWrappersActions = Object.values(fetchDocumentsWrappersNames);

const lists = (
  state: Record<string, WrapperList> = {},
  action: Action<any>,
) => {
  if (documentsWrappersActions.includes(action.type)) {
    const { payload } = action as DocumentsWrappersSuccessAction;

    const key = getDocumentsWrappersKey(payload);
    return {
      ...state,
      [key]: listReducer(state[key], action),
    };
  }
  return state;
};

export type ItemsState = ReturnType<typeof items>;

const documentsWrappers = combineReducers({
  items,
  lists,
});

export type DocumentsWrappersState = ReturnType<typeof documentsWrappers>;

export default documentsWrappers;
