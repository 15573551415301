import Locales from 'shared/types/Locales';

export const langs: Locales[] = ['pl', 'en'];

export const getPreferredLang = (defaultLang: Locales) => {
  try {
    const supportsLanguages = navigator && 'languages' in navigator;
    if (!supportsLanguages) {
      return defaultLang;
    }

    const pref = navigator.languages.find(navLang =>
      langs.some(lang => navLang.startsWith(lang)),
    );
    if (!pref) {
      return defaultLang;
    }
    return langs.find(lang => pref.startsWith(lang)) || defaultLang;
  } catch (ex) {
    return defaultLang;
  }
};
