/**
 * IE 10 WORKAROUND
 *
 * The following code is a workaround for the lack of support for Object.setPrototypeOf
 */

if (typeof Object.setPrototypeOf === 'undefined') {
  // eslint-disable-next-line
  Object.setPrototypeOf = require('setprototypeof');
}
