import { getFetchAction, asyncNames, createActions } from './utils';

export const names = {
  update: 'SEARCH_HISTORY_UPDATE',
  ...asyncNames('SEARCH_HISTORY'),
};

export const actions = createActions(names);

export const fetchSearchHistory = getFetchAction(actions, 'api_get_logged_user_last_search');

export default actions.update;
