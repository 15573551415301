import errorLogger from 'shared/utils/errorLogger';
import get from 'lodash/get';
import axios from 'axios';
import notify from 'shared/actions/notify';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('TOGGLE_CODE');
export const actions = createActions(names);

const toggle = ({ codeId, value }) =>
  axios.post(AppRouting.generate('api_toggle_code', { code_id: codeId }), {
    used: value,
  });

const toggleCode = params => async dispatch => {
  dispatch(actions.request(params));
  try {
    await toggle(params);
    dispatch(actions.success(params));
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||error|||default'),
    );

    dispatch(notify({ message }));
    dispatch(actions.failure({ params, error }));
  }
};

export default toggleCode;
