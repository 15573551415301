// add: 'CART_ADD',
// remove: 'CART_REMOVE',
// modify: 'CART_MODIFY',
// clear: 'CART_CLEAR',
import { combineReducers, AnyAction } from 'redux';
import { names } from 'shared/actions/cart/cart';
import { names as buyNames } from 'shared/actions/products/buy';

import cloneDeep from 'lodash/cloneDeep';
import splice from 'shared/utils/array/splice';
import { CartItem, CartGift } from 'shared/types/reducers/CartState';
import { GymsteerTraining } from 'shared/modules/gymsteer/GymsteerType';

const makeCartItem = (payload: any): CartItem => ({
  id: payload.id,
  parentId: payload.parentId,
  quantity: payload.quantity || 1,
  customPrice: payload.customPrice || null,
  training: (payload.training as GymsteerTraining) || undefined,
  card: payload.card || undefined,
});

const items = (state: CartItem[] = [], action: AnyAction) => {
  if (action.type === names.clear) {
    return [];
  }
  if (action.type === names.add) {
    const newItem = makeCartItem(action.payload);
    const itemIndex = state.findIndex(item => item.id === newItem.id);
    if (itemIndex > -1) {
      const newState = cloneDeep(state);
      newState[itemIndex].quantity += 1;
      return newState;
    }
    // HACK: keep only 1 item in cart, buying multiple items is not supported
    return [newItem];
  }
  if (action.type === names.modify) {
    const newItem = makeCartItem(action.payload);
    const itemIndex = state.findIndex(item => item.id === newItem.id);
    if (itemIndex > -1) {
      const newState = cloneDeep(state);
      newState[itemIndex] = { ...newState[itemIndex], ...newItem };
      return newState;
    }
    return state;
  }
  if (action.type === names.remove) {
    const itemIndex = state.findIndex(item => item.id === action.payload.id);
    if (itemIndex > -1) {
      return splice(state, itemIndex, 1);
    }
    return state;
  }
  return state;
};

const gift = (state: CartGift | null = null, action: AnyAction) => {
  if (action.type === names.clear) {
    return null;
  }
  if (action.type === names.gift) {
    return action.payload.gift;
  }
  return state;
};

const summary = (state = null, action: AnyAction) => {
  if (action.type === names.clear) {
    return null;
  }
  if (action.type === buyNames.success) {
    return action.payload;
  }
  return state;
};

const funding = (state = {}, action: AnyAction) => {
  if (action.type === names.clear) {
    return null;
  }
  if (action.type === names.funding) {
    return action.payload;
  }
  return state;
};

const documentSubmit = (state = null, action: AnyAction) => {
  if (action.type === names.clear) {
    return null;
  }
  if (action.type === names.documentSubmit) {
    return action.payload;
  }
  return state;
};

const cart = combineReducers({
  items,
  gift,
  funding,
  summary,
  documentSubmit,
});

export default cart;
