import { Action, combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import {
  fetchActivitiesNames,
  FetchActivitiesSuccessAction,
} from './challengesActions';
import { Activity } from './Trainings';

export interface ActivitiesState {
  [type: string]: Activity;
}

const items = (state: ActivitiesState = {}, action: Action<any>) => {
  if (action.type === fetchActivitiesNames.success) {
    const { payload } = action as FetchActivitiesSuccessAction;
    return payload.data;
  }
  return state;
};

const list = (state: string[] = [], action: Action<any>) => {
  if (action.type === fetchActivitiesNames.success) {
    const { payload } = action as FetchActivitiesSuccessAction;
    return payload.list;
  }
  return state;
};

const activities = combineReducers({
  items,
  list,
  status: makeStatusReducer(fetchActivitiesNames),
});

export default activities;
