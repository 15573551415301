import * as Sentry from '@sentry/browser';
import { IS_DEV } from 'shared/consts';
import { configureErrorLogger } from 'shared/utils/errorLogger';

/**
 * Get logging environment
 */
const logEnv = () => {
  // @ts-ignore
  const env = (window.SENTRY_ENV || process.env.SENTRY_ENV) as
    | string
    | undefined;
  if (env === 'prod') {
    return 'production';
  }
  if (!env) {
    return 'production';
  }
  return env;
};

/**
 * Sentry initialization
 */
const initSentry = () => {
  if (IS_DEV) {
    return;
  }
  Sentry.init({
    dsn: 'https://9c3d003dcf9f415ab29cc85032fefe76@sentry.viamobile.pl/9',
    release: process.env.REVISION,
    environment: logEnv(),
  });

  configureErrorLogger({
    logger: Sentry,
  });
};

export default initSentry;
