import { names } from 'shared/actions/users/userCompany';
import { AnyAction, combineReducers } from 'redux';
import { Permissions } from 'shared/types/Company';

export type UserCompany = number | null;

export type SelectorState = { permissions: Permissions; some?: boolean } | null;

const selected = (state: UserCompany = null, action: AnyAction) => {
  if (action.type === names.setUserCompany) {
    return action.payload;
  }
  return state;
};

export const selector = (state: SelectorState = null, action: AnyAction) => {
  if (action.type === names.setViewPermissions) {
    return action.payload;
  }
  return state;
};

export type UserCompanyState = {
  selected: UserCompany;
  selector: SelectorState;
};

const userCompany = combineReducers({ selected, selector });

export default userCompany;
