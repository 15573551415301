import {
  asyncNames,
  createActions,
  getFetchAction,
} from 'shared/actions/utils';
// import { normalize } from 'normalizr';

export const names = asyncNames('FETCH_GUIDE_SETS');
export const actions = createActions(names);

const fetchGuideSets = getFetchAction(actions, 'api_guides_get', false);

export default fetchGuideSets;
