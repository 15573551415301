import { combineReducers } from 'redux';
import { Action } from 'redux-actions';
import { Question } from 'shared/types/surveys/SurveyQuestion';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import produce from 'immer';
import makeStatusReducer from 'shared/reducers/status';
import {
  CompanySurveyListItem,
  Survey,
  SurveyConfig,
  SurveySummary,
  UserSurveyListItem,
} from 'shared/types/surveys/Survey';
import {
  userListActionNames,
  userSurveyActionNames,
  companyListActionNames,
  surveySummaryActionNames,
  updateSurveyDatesName,
} from './surveyActions';

export interface QuestionsState {
  [id: number]: Question;
}

export interface SurveyConfigState {
  [id: number]: SurveyConfig;
}

export interface UserSurveysState {
  [id: number]: Survey | UserSurveyListItem;
}

export interface CompanySurveysState {
  [id: number]: SurveySummary | CompanySurveyListItem;
}

export const isSurvey = (item: Survey | UserSurveyListItem): item is Survey => {
  return 'periods' in item;
};

export const isSurveySummary = (
  item: SurveySummary | CompanySurveyListItem,
): item is SurveySummary => {
  return 'periods' in item;
};

export type ListState = number[];

const questions = (state: QuestionsState = {}, action: Action<any>) => {
  if (
    action.type === userSurveyActionNames.success ||
    action.type === surveySummaryActionNames.success
  ) {
    const newQuestions = action.payload.entities.questions as QuestionsState;
    return {
      ...state,
      ...newQuestions,
    };
  }
  return state;
};

const userSurveys = (state: UserSurveysState = {}, action: Action<any>) => {
  if (action.type === userListActionNames.success) {
    const byId = keyBy(action.payload.data, 'surveyId');
    return merge(state, byId);
  }
  if (action.type === userSurveyActionNames.success) {
    const data = action.payload.data as Survey;
    const prev = state[data.surveyId] || {};
    return {
      ...state,
      [data.surveyId]: merge(prev, data),
    };
  }
  return state;
};

const companySurveys = (
  state: CompanySurveysState = {},
  action: Action<any>,
) => {
  if (action.type === companyListActionNames.success) {
    const byId = keyBy(action.payload.data, 'surveyId');
    return merge(state, byId);
  }
  if (action.type === updateSurveyDatesName) {
    const { surveyId, renewalDate, endDate } = action.payload;
    return produce(state, draftState => {
      /* eslint-disable no-param-reassign */
      if (endDate) {
        draftState[surveyId].endDate = endDate;
      }
      if (renewalDate) {
        draftState[surveyId].renewalDate = renewalDate;
      }
      /* eslint-enable no-param-reassign */
    });
  }
  if (action.type === surveySummaryActionNames.success) {
    const data = action.payload.data as SurveySummary;
    const prev = state[data.surveyId] || {};
    return {
      ...state,
      [data.surveyId]: merge(prev, data),
    };
  }
  return state;
};

const userList = (state: ListState = [], action: Action<any>) => {
  if (action.type === userListActionNames.success) {
    const data = action.payload.data as Survey[];
    const current = data.map(item => item.surveyId);
    return current;
  }
  return state;
};

interface CompanyListState {
  [id: number]: ListState;
}

const companyList = (state: CompanyListState = {}, action: Action<any>) => {
  if (action.type === companyListActionNames.success) {
    const data = action.payload.data as Survey[];
    const current = data.map(item => item.surveyId);
    return {
      ...state,
      [action.payload.companyId]: current,
    };
  }
  return state;
};

const user = combineReducers({
  list: userList,
  items: userSurveys,
  status: makeStatusReducer(userListActionNames),
});

const company = combineReducers({
  list: companyList,
  items: companySurveys,
});

const surveys = combineReducers({
  questions,
  company,
  user,
});

export type SurveysState = ReturnType<typeof surveys>;

export default surveys;
