import axios from 'shared/utils/axios';
import errorLogger from 'shared/utils/errorLogger';
import { fetchCategories } from 'shared/actions/categories';
import { initRefreshToken } from 'shared/utils/user/tokenRefresh';
import initWebsocket from 'shared/modules/notifications/websocket/websocketListener';
import fetchGuideSets from 'shared/modules/guides/fetchGuideSets';
import setLocale from 'shared/actions/locale';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import { URL_LOGIN } from 'shared/utils/user/localUsers';

import getAllUrlParams from 'shared/utils/queryString';
import { asyncNames, createActions } from '../utils';
import data, { actions as dataActions } from './data';
import fetchCompanies from './fetchCompanies';
import logout from './logout';

export const names = asyncNames('LOGIN');
export const actions = createActions(names);

export const persist = (token, expires) => {
  const isSession = !!safeSessionStorage.getItem('token');
  const storage = isSession ? safeSessionStorage : safeLocalStorage;

  storage.setItem('token', token);
  storage.setItem('expires', expires);
};

const oauthParams = [
  'oauth',
  'client_id',
  'response_type',
  'redirect_uri',
  'state',
  'scope',
];

const getOauthParams = () => {
  const params = getAllUrlParams(window.location.search);
  const filteredEntries = Object.entries(params).filter(([name]) =>
    oauthParams.includes(name),
  );
  return Object.fromEntries(filteredEntries);
};

export const loginSuccess =
  (params = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const debug = !!state.ui.mode.debug;
    await initRefreshToken(persist, undefined, undefined, { debug });
    await dispatch(actions.success(params));
    if (params.data) {
      if (params.data.user.locale !== state.locale) {
        await dispatch(setLocale({ lang: params.data.user.locale }));
      }
      await dispatch(dataActions.success(params.data));
    } else {
      if (params?.aud !== 'whistle') {
        dispatch(data(setLocale));
      }
    }
    if (params?.aud !== 'whistle') {
      dispatch(
        fetchCompanies({ data: params.data }, async () => {
          await dispatch(logout());
          window.location = '/';
        }),
      );
      dispatch(fetchCategories());
      dispatch(fetchGuideSets());
    }
    initWebsocket(dispatch, { debug });
  };

export const persistTokenFromResponse = (response, rememberMe) => {
  const token = response.headers['x-viamobile-token'];
  const expiresAt = response.headers['x-viamobile-expires-at'];
  persist(token, expiresAt, rememberMe);
};

const login = params => async dispatch => {
  dispatch(actions.request({ params }));
  try {
    const url = AppRouting.generate('api_login', {
      ...getOauthParams(),
    });
    const { username = '', password, rememberMe } = params;
    const response = await axios.post(url, {
      username: username.trim(),
      password,
    });
    // For regular login remove the url login marker
    safeSessionStorage.removeItem(URL_LOGIN);
    persistTokenFromResponse(response, rememberMe);
    if (response.data?.redirect) {
      window.location = response.data.redirect;
      return;
    }
    await dispatch(loginSuccess({ params, data: response.data }));
  } catch (error) {
    if (!error.response || error.response.status !== 400) {
      errorLogger(error);
    }
    dispatch(actions.failure());
    throw error;
  }
};

export default login;
