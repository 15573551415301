import { combineReducers } from 'redux';
import { ThirdPartyConsent } from 'shared/types/Consents';
import {
  names,
  ConsentsFetchedAction,
  ConsentUpdateAction,
} from './consentActions';

export interface ConsentsItems {
  [id: string]: ThirdPartyConsent;
}

type ConsentsList = number[];

const items = (
  state: ConsentsItems = {},
  action: ConsentsFetchedAction | ConsentUpdateAction,
) => {
  if (action.type === names.consentsFetched) {
    const { payload } = action as ConsentsFetchedAction;
    return {
      ...state,
      ...payload.entities.consents,
    };
  }
  if (action.type === names.consentUpdate) {
    const { payload } = action as ConsentUpdateAction;

    return {
      ...state,
      [payload.id]: {
        ...state[payload.id],
        ...payload,
      },
    };
  }
  return state;
};

const list = (state: ConsentsList = [], action: ConsentsFetchedAction) => {
  if (action.type === names.consentsFetched) {
    return action.payload.data;
  }
  return state;
};

const consents = combineReducers({
  items,
  list,
});

export type ConsentsState = ReturnType<typeof consents>;

export default consents;
