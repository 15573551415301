import { createAction } from 'redux-actions';
import { createActions, asyncNames } from 'shared/actions/utils';

export const userListActionNames = asyncNames('SURVEYS_USER_LIST');

export const userListAction = createActions(userListActionNames);

export const userSurveyActionNames = asyncNames('SURVEY_USER');

export const userSurveyAction = createActions(userSurveyActionNames);

export const companyListActionNames = asyncNames('SURVEYS_COMPANY_LIST');

export const companyListAction = createActions(companyListActionNames);

export const surveySummaryActionNames = asyncNames('SURVEY_SUMMARY');

export const surveySummaryAction = createActions(surveySummaryActionNames);

export const updateSurveyDatesName = 'UPDATE_SURVEY_DATES';

export const updateSurveyDates = createAction(updateSurveyDatesName);
