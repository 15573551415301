import { AnyAction, Store } from 'redux';
import { AppState } from 'shared/types/AppState';
import { RouteNames } from 'shared/types/RouteNames';
import routesConfigJson from './routesConfig.json';
import routesConfigDefaultsJson from './routesConfigDefaults.json';

import defaultConfig from './config';

type TextConfig = ['text', string];
type VariableConfig = ['variable', string, string, string];

type FragmentConfig = TextConfig | VariableConfig;

interface RouteDefaults {
  [key: string]: string | null;
}

export interface RoutingParams {
  [key: string]: number | string | null | undefined | boolean;
}

interface SearchParamsConfig {
  [key: string]: true;
}

interface RouteConfig {
  [key: string]: FragmentConfig[];
}

interface RouteConfigDefaults {
  [key: string]: RouteDefaults;
}

export type AppConfig = {
  contactMail: string;
  api?: string;
  apiUrl?: string;
  wsUrl: string;
  SMALL_PLACEHOLDER: string;
  WIDE_PLACEHOLDER: string;
  defaultPerPage: number;
};

export type StoreType = Store<AppState, AnyAction>;

interface AppRoutingOptions {
  store: StoreType;
  debug?: boolean;
  config?: AppConfig;
}

const routesConfig = routesConfigJson as unknown as RouteConfig;
const routesConfigDefaults = routesConfigDefaultsJson as RouteConfigDefaults;

const getFragment = (
  config: FragmentConfig,
  params: RoutingParams,
  path: string,
  defaults: RouteDefaults,
) => {
  if (config[0] === 'text') {
    return config[1];
  }
  const key = config[3];
  const value = params[key] !== undefined ? params[key] : defaults?.[key];
  if (!key || typeof value === 'undefined') {
    throw new Error(`[AppRouting] Missing ${key} for ${path}`);
  }
  if (value === null) {
    return '';
  }
  return config[1] + value;
};

const getSearch = (
  params: RoutingParams = {},
  routeConfig: FragmentConfig[],
  debug: boolean,
) => {
  try {
    const configKeys: SearchParamsConfig = {};
    routeConfig.forEach(item => {
      if (item[0] === 'variable') {
        configKeys[item[3]] = true;
      }
    });
    const keys = Object.keys(params).filter(key => !configKeys[key]);
    const searchArr = keys.map(
      key => `${key}=${encodeURIComponent(params[key] ?? 0)}`,
    );
    if (searchArr.length) {
      return `?${searchArr.join('&')}`;
    }
  } catch (ex) {
    if (debug) {
      console.error('[AppRouting] Could not build search Params', ex);
    }
  }
  return '';
};

const makeGenerate =
  ({
    debug,
    getLocale,
    config,
  }: {
    debug: boolean;
    getLocale: () => string;
    config: AppConfig;
  }) =>
  (path: RouteNames, params?: RoutingParams, absolute = true) => {
    const merged = { ...params, _locale: getLocale() };

    let routeConfig = routesConfig[path];
    const defaults = routesConfigDefaults[path];

    if (!routeConfig) {
      throw new Error(`[AppRouting] Missing configuration for path ${path}`);
    }
    routeConfig = [...routeConfig].reverse();
    const base = absolute ? config.apiUrl : '';
    const search = getSearch(params, routeConfig, debug);
    const fragments = routeConfig.map(c =>
      getFragment(c, merged, path, defaults),
    );
    const route = `${base}${fragments.join('')}${search}`;
    if (debug) {
      console.log(`[AppRouting] Route ${route} from ${path}`, params);
    }
    return route;
  };

const makeAppRouting = ({
  store,
  debug = false,
  config = defaultConfig,
}: AppRoutingOptions) => {
  if (debug) {
    console.log('[AppRouting] debug on', debug);
  }

  const getLocale = () => store.getState().locale;

  const getApiUrl = () => config.apiUrl;

  const getStaticFile = (name: string) =>
    `${config.apiUrl}/${getLocale()}/files/${name}`;

  return {
    generate: makeGenerate({ debug, getLocale, config }),
    getLocale,
    getApiUrl,
    getStaticFile,
  };
};

// eslint-disable-next-line import/no-mutable-exports
export let routing: ReturnType<typeof makeAppRouting>;

// eslint-disable-next-line import/no-mutable-exports
export let AppRouting: ReturnType<typeof makeAppRouting>;

export type AppRoutingType = ReturnType<typeof makeAppRouting>;

const getAppRouting = (
  params: AppRoutingOptions,
  force = false,
): AppRoutingType => {
  if (!routing || force) {
    const appRouting = makeAppRouting(params);
    routing = appRouting;
    AppRouting = appRouting;
  }
  return routing;
};

export default getAppRouting;
