import { combineReducers } from 'redux';
import { names } from 'shared/actions/ui';

export interface GuideState {
  open: boolean;
  force: boolean;
}

interface GuideAction {
  type: string;
}

const guideOpen = (state = false, action: GuideAction) => {
  if (action.type === names.setGuide) {
    return true;
  }
  if (action.type === names.closeGuide) {
    return false;
  }
  return state;
};

const forceGuide = (state = false, action: GuideAction) => {
  if (action.type === names.closeGuide) {
    return false;
  }
  if (action.type === names.forceGuide) {
    return true;
  }
  return state;
};

const guide = combineReducers({
  open: guideOpen,
  force: forceGuide,
});

export default guide;
