import { names } from 'shared/actions/mobile';
import detectPlatform from 'shared/utils/detectPlatform';

const mobile = (state = null, action) => {
  if (action.type === names.set) {
    return action.payload.mobile;
  }
  if (state === null) {
    return detectPlatform(true).mobile || false;
  }
  return state;
};

export const small = (state = null, action) => {
  if (action.type === names.set) {
    return action.payload.small;
  }

  if (state === null) {
    return detectPlatform(true).small || false;
  }
  return state;
};

export const medium = (state = null, action) => {
  if (action.type === names.set) {
    return action.payload.medium;
  }

  if (state === null) {
    return detectPlatform(true).medium || false;
  }
  return state;
};

export default mobile;
