/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import subaccounts from './subaccountsStore';
import subaccountTypes from './subaccountTypesStore';
import preliminaries from './preliminaryStore';
import preliminaryTypes from './preliminaryTypesStore';
import dynamicForms from './dynamicFormStore';

const budgetReducer = combineReducers({
  subaccounts,
  subaccountTypes,
  preliminaries,
  preliminaryTypes,
  dynamicForms,
});

export type BudgetState = ReturnType<typeof budgetReducer>;

export default budgetReducer;
