import { combineReducers } from 'redux';
import { names } from 'shared/actions/whipRounds';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { names as userDataNames } from 'shared/actions/user/data';
import { names as accountNames } from 'shared/actions/accounts';
import { userStatuses, statuses } from 'shared/utils/whipRoundsConfig';

import makeStatusReducer from './status';

const list = (state = null, action) => {
  if (action.type === names.fetch.success) {
    return action.payload.data.map(round => round.id);
  }

  if (action.type === names.remove) {
    const { id } = action.payload;
    if (!state) {
      return null;
    }
    return state.filter(roundId => roundId !== id);
  }
  return state;
};

const items = (state = {}, action) => {
  if (action.type === names.patch) {
    const { id, name, value } = action.payload;
    return { ...state, [id]: { ...state[id], [name]: value } };
  }
  if (action.type === names.fetch.success) {
    const rounds = {};
    action.payload.data.forEach(round => {
      rounds[round.id] = round;
    });
    return rounds;
  }
  if (action.type === names.single.success) {
    const whipRound = action.payload.data;
    return { ...state, [whipRound.id]: whipRound };
  }
  if (
    action.type === 'UPDATE_ACCOUNTS' ||
    action.type === accountNames.success
  ) {
    return merge({}, state, action.payload.entities.whiprounds);
  }
  if (action.type === userDataNames.success) {
    return merge({}, state, action.payload.accounts.entities.whiprounds);
  }

  if (action.type === names.accept) {
    const { id, userId } = action.payload;
    const memberIndex = findIndex(
      state[id].members,
      member => member.userId === userId,
    );
    const newState = cloneDeep(state);
    newState[id].members[memberIndex].status = userStatuses.accepted;
    const filtered = newState[id].members.filter(
      user => user.status === userStatuses.accepted,
    );
    if (filtered.length === newState[id].maxUsers) {
      newState[id].status = statuses.closed;
    }
    return newState;
  }
  if (action.type === names.finalize) {
    const { id } = action.payload;
    const newState = cloneDeep(state);
    newState[id].status = statuses.closed;
    return newState;
  }
  if (action.type === names.finish) {
    const { id } = action.payload;
    const newState = cloneDeep(state);
    newState[id].status = statuses.finished;
    return newState;
  }
  if (action.type === names.remove) {
    const { id } = action.payload;
    const newState = cloneDeep(state);
    newState[id].status = statuses.removed;
    return newState;
  }
  if (action.type === names.reject) {
    const { id, userId } = action.payload;
    const memberIndex = findIndex(
      state[id].members,
      member => member.userId === userId,
    );
    const newState = cloneDeep(state);
    newState[id].members[memberIndex].status = userStatuses.declined;
    return newState;
  }
  if (action.type === names.join) {
    const { id, members } = action.payload;
    const newState = cloneDeep(state);
    members.forEach(member => {
      const memberIndex = findIndex(
        state[id].members,
        item => member.id === item.id,
      );
      if (memberIndex > -1) {
        newState[id].members[memberIndex].status = member.status;
      } else {
        newState[id].members.push(member);
      }
    });
    return newState;
  }
  return state;
};

export const status = makeStatusReducer(names);

export default combineReducers({
  items,
  list,
  status,
});
