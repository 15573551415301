import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import uuidv4 from 'uuid/v4';
import errorLogger from '../utils/errorLogger';

export const names = {
  open: 'NOTIFICATION_OPEN',
  close: 'NOTIFICATION_CLOSE',
  register: 'NOTIFICATIONS_REGISTER',
  unregister: 'NOTIFICATIONS_UNREGISTER',
};

const open = createAction(names.open);

export const close = createAction(names.close);

export const notificationsRegister = createAction(names.register);
export const notificationsUnregister = createAction(names.unregister);

export type ToastNotificationType = 'error' | 'info' | 'success';

export interface ToastNotificationPayload {
  message: string;
  title?: string;
  type?: ToastNotificationType;
  forceShow?: boolean;
  duration?: number;
  notificationId?: string;
  navigate?: string;
  data?: Record<string, string>;
}

export interface OpenNotificationAction {
  type: 'NOTIFICATION_OPEN';
  payload: ToastNotification;
}

export interface CloseNotificationAction {
  type: 'NOTIFICATION_CLOSE';
  payload: { id: string };
}

export interface NotificationsRegisterAction {
  type: 'NOTIFICATIONS_REGISTER';
  payload: {
    uid: string;
  };
}

export interface NotificationsUnregisterAction {
  type: 'NOTIFICATIONS_UNREGISTER';
  payload: {
    uid: string;
  };
}

export interface ToastNotification extends ToastNotificationPayload {
  id: string;
  type: ToastNotificationType;
}

const notify =
  (payload: ToastNotificationPayload) => (dispatch: Dispatch<any>) => {
    if (!payload.message || typeof payload.message !== 'string') {
      errorLogger('Invalid notification message', payload.message);
      return undefined;
    }
    return dispatch(open({ type: 'error', ...payload, id: uuidv4() }));
  };

export default notify;
