import merge from 'lodash/merge';

import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';
import { names as purchaseNames } from 'shared/actions/purchases/fetchPurchase';
import { names as purchasePatchNames } from 'shared/actions/purchases/patchPurchase';

import { names as codeNames } from 'shared/actions/purchases/toggleCode';
import produce from 'immer';

const initial = {};

const items = (state = initial, { type, payload }) => {
  if (type === purchasesNames.success) {
    return merge({}, state, payload.data.entities.purchases);
  }
  /* eslint-disable no-param-reassign */
  if (type === purchaseNames.success || type === purchasePatchNames.success) {
    const nextState = produce(state, draftState => {
      const purchase = payload.data;
      draftState[purchase.id] = purchase;
    });
    return nextState;
  }
  if (type === codeNames.success) {
    const { purchaseId, codeId, value } = payload;
    const nextState = produce(state, draftState => {
      const codeIndex = draftState[purchaseId].codes.findIndex(
        code => code.id === codeId,
      );
      draftState[purchaseId].codes[codeIndex].used = value;
    });
    return nextState;
  }
  /* eslint-enable no-param-reassign */
  return state;
};

export default items;
