import createAction from 'redux-actions/lib/createAction';

export const names = {
  finalize: 'WHIP_ROUND_FINALIZE',
  reject: 'WHIP_ROUND_REJECT',
  accept: 'WHIP_ROUND_ACCEPT',
  join: 'WHIP_ROUND_JOIN',
  finish: 'WHIP_ROUND_FINISH',
  remove: 'WHIP_ROUND_REMOVE',
  patch: 'WHIP_ROUND_PATCH',
};

export const finalize = createAction(names.finalize);
export const reject = createAction(names.reject);
export const accept = createAction(names.accept);
export const join = createAction(names.join);
export const finish = createAction(names.finish);
export const patch = createAction(names.patch);
export const remove = createAction(names.remove);
