import getDomain from '../domain';
import isStringTrue from './isStringTrue';
import configStatic from './configStatic';

export type ENV_KEYS =
  | 'IS_DEV'
  | 'API_DOMAIN'
  | 'DISABLE_HTTPS'
  | 'DISABLE_ERROR_BOUNDARY'
  | 'DISABLE_IMAGE_PROCESSING'
  | 'SELECT_AUTO_X_NAIS_HEADER';

declare global {
  interface Window {
    IS_DEV?: string;
    IS_SSR?: boolean;
    WS_DOMAIN?: string;
    API_DOMAIN?: string;
    DISABLE_HTTPS?: string;
    DISABLE_ERROR_BOUNDARY?: string;
    DISABLE_IMAGE_PROCESSING?: string;
    SELECT_AUTO_X_NAIS_HEADER?: string;
  }
}

// This is a noop in react-native
// FIXME: this file should not be used in react-native
const getEnv = (env: ENV_KEYS) => {
  if (typeof window !== 'undefined') {
    return window[env];
  }
  // @ts-ignore - process is not defined in react-native
  if (typeof process !== 'undefined') {
    // @ts-ignore - process is not defined in react-native
    return process.env[env];
  }
  return '';
};

const disableHttps = isStringTrue(getEnv('DISABLE_HTTPS'));

const config = {
  api: getDomain() as string,
  apiUrl: getDomain(false, true) as string,
  wsUrl: `ws${!disableHttps ? 's' : ''}://${getDomain(true)}`,
  ...configStatic,
} as const;

// eslint-disable-next-line prefer-destructuring
// export const defaultPerPage = config.defaultPerPage;

export default config;
