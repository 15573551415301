import pick from 'lodash/pick';

const multiAdapter = (storages, map) => ({
  0: storages,
  1: map,

  put(key, value, callback, write) {
    if (!write) {
      return;
    }
    try {
      const res = storages.map((storage, index) => {
        const values = pick(value, map[index]);
        return storage.setItem(key, JSON.stringify(values));
      });
      callback(null, res);
    } catch (e) {
      callback(e);
    }
  },

  get(key, callback) {
    try {
      const res = storages.reduce(
        (acc, storage) => ({ ...acc, ...JSON.parse(storage.getItem(key)) }),
        {},
      );
      callback(null, res);
    } catch (e) {
      callback(e);
    }
  },

  del(key, callback) {
    try {
      storages.forEach(storage => storage.removeItem(key));
      callback(null);
    } catch (e) {
      callback(e);
    }
  },
});

export default multiAdapter;
