import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_TILES');

export const tiles = createActions(names);

const fetchTiles = params => async (dispatch) => {
  dispatch(tiles.request());
  try {
    const response = await axios(AppRouting.generate('api_get_purchases', {}, true));
    return dispatch(tiles.success({ tiles: response.data }));
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('dashboard|||tilesFetchFailure'),
    );

    dispatch(notify({ message }));
    return dispatch(tiles.failure({ params, error }));
  }
};

export default fetchTiles;
