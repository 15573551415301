import { combineReducers } from 'redux';
import items from './items';
import list from './list';

const bonuses = combineReducers({
  items,
  list,
});

export default bonuses;
