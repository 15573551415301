import urlParse from 'url-parse';

const getAllUrlParams = (url: string | Location) => {
  if (typeof url === 'string') {
    const { query } = urlParse(url, true);
    return query;
  }
  const { query } = urlParse(url.href, true);
  return query;
};

export default getAllUrlParams;
