import { createAction } from 'redux-actions';

export const names = {
  update: 'UPDATE_MEMBER_COUNT',
};

const updateMemberCount = createAction<{ member_count: number; id: number }>(
  names.update,
);

export default updateMemberCount;
