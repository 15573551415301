import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { normalize } from 'normalizr';
import productsSchema from 'shared/schemas/products';
import {
  gaViewProducts,
  gaViewProductsList,
} from 'shared/googleAnalytics/googleAnalyticsEvents';
import { getProductsData } from 'shared/googleAnalytics/gaHelperFunctions';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_PRODUCTS');
export const products = createActions(names);

const getParams = params => {
  const { category, limit, ...rest } = params;
  const opts = {};
  if (category != null) {
    opts.category = category;
  }
  if (limit != null) {
    opts.max_per_page = limit;
  }
  return { ...opts, ...rest };
};

const fetchProducts =
  ({ params = {}, clear, cancelToken, user_data, location, driver }) =>
  async dispatch => {
    await dispatch(products.request({ params, clear }));

    const { category } = params;

    try {
      if (cancelToken) {
        cancelToken.current?.cancel();
        // eslint-disable-next-line no-param-reassign
        cancelToken.current = axios.CancelToken.source();
      }
      const response = await axios(
        AppRouting.generate('api_search_products', getParams(params)),
        {
          cancelToken: cancelToken?.current?.token,
        },
      );

      const data = normalize(response.data, productsSchema);
      const { entities } = data;

      const prod = entities.products ? Object.values(entities.products) : null;
      const ga_products_data = getProductsData(prod);

      const mobile = !window.length;

      if (location && ga_products_data) {
        // Google Analytics
        gaViewProducts({
          ga_products_data,
          user_data,
          mobile,
          driver,
          item_list_id: location,
          item_list_name: location,
        });
      } else if (category && ga_products_data) {
        // Google Analytics
        gaViewProductsList({
          ga_products_data,
          user_data,
          mobile,
          driver,
          item_list_id: category,
          item_list_name: category,
        });
      }

      await dispatch(products.success({ params, data }));
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      errorLogger(error);
      const message = get(
        error,
        'response.data.errors.global',
        i18next.t('products|||fetchFailure'),
      );

      dispatch(notify({ message }));
      dispatch(products.failure({ params, error }));
    }
  };

export default fetchProducts;
