type UnsafePermissions = string[] | { [key: string]: string };

const getArray = (input: UnsafePermissions) => {
  if (Array.isArray(input)) {
    return input;
  }
  return Object.values(input);
};

const hasPermission = (
  permission: string | string[],
  userPermissions?: UnsafePermissions,
  some?: boolean,
): boolean => {
  if (Array.isArray(permission)) {
    const filterPerms = (perm: string) => hasPermission(perm, userPermissions);
    if (some) {
      return permission.some(filterPerms);
    }
    return permission.every(filterPerms);
  }
  if (!userPermissions) {
    return false;
  }
  const perms = getArray(userPermissions);
  return perms.includes(permission);
};

export default hasPermission;
