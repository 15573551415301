/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { combineReducers } from 'redux';
import {
  PreliminaryTypeConfig,
  PreliminaryTypesResponse,
} from 'shared/types/Preliminary';
import createAsyncThunkStatusSlice from '../../utils/redux/createAsyncThunkStatusSlice';

interface FetchPreliminaryTypesParams {
  companyId: number;
  refresh?: boolean;
}

export const fetchPreliminaryTypes = createAsyncThunk(
  'preliminary_types/fetch',
  async ({ companyId }: FetchPreliminaryTypesParams) => {
    const opts: RoutingParams = {
      company: companyId,
    };

    const response = await axios.get<PreliminaryTypesResponse>(
      AppRouting.generate('api_preliminary_types_get', opts),
    );
    return response.data;
  },
);

export const getPreliminaryTypesKey = ({
  companyId,
}: FetchPreliminaryTypesParams) => {
  const params: (string | number)[] = [`companyId:${companyId}`];

  return params.join('|');
};

const initialPreliminaryTypesState: Record<string, PreliminaryTypeConfig> = {};

const preliminaryTypesSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState: initialPreliminaryTypesState,
  extraReducers: builder => {
    builder.addCase(fetchPreliminaryTypes.fulfilled, (state, action) => {
      const { entities } = action.payload;
      state = { ...state, ...entities.preliminaryTypes };
      return state;
    });
  },
});

const preliminaryTypesListSlice = createSlice({
  name: 'list',
  reducers: {},
  initialState: {} as Record<string, string[]>,
  extraReducers: builder => {
    builder.addCase(fetchPreliminaryTypes.fulfilled, (state, action) => {
      const key = getPreliminaryTypesKey(action.meta.arg);
      state[key] = action.payload.data;
      return state;
    });
  },
});

const preliminaryStatus = createAsyncThunkStatusSlice(fetchPreliminaryTypes, {
  getKey: getPreliminaryTypesKey,
});

const preliminaryTypes = combineReducers({
  items: preliminaryTypesSlice.reducer,
  list: preliminaryTypesListSlice.reducer,
  status: preliminaryStatus.reducer,
});

export default preliminaryTypes;
