import { names, fetchNames } from 'shared/actions/categories';
import {
  Category,
  CategoriesSection,
  CategoriesResponse,
} from 'shared/types/Category';
import { AnyAction } from 'redux';

const initial = {};

export interface CategoriesStateItems {
  [slug: string]: Category;
}

export interface Categories {
  items: CategoriesStateItems;
  list: CategoriesSection[];
}

export interface CategoriesState {
  [locale: string]: Categories;
}

interface FetchSuccessAction {
  type: string;
  payload: {
    data: CategoriesResponse;
  };
}

const getItems = (action: FetchSuccessAction) => action.payload.data.entities;

const getList = (action: FetchSuccessAction) => action.payload.data.data;

const categories = (state: CategoriesState = initial, action: AnyAction) => {
  // if (action.type === names.set) {
  //   return action.payload;
  // }
  if (action.type === names.clear) {
    return initial;
  }
  if (action.type === fetchNames.success) {
    return {
      ...state,
      [action.payload.locale]: {
        items: getItems(action as FetchSuccessAction),
        list: getList(action as FetchSuccessAction),
      },
    };
  }
  return state;
};

export interface CategoriesStore {
  categories: CategoriesState;
  locale: string;
}

export const selectCategoriesState = (state: CategoriesStore): Categories =>
  state.categories && state.categories[state.locale];

export const selectCategoriesFlat = (
  state: CategoriesStore,
  defValue?: Category[],
) => {
  const categoriesState = selectCategoriesState(state);

  if (!categoriesState) {
    return defValue;
  }

  return categoriesState.list
    .map(group => group.children.map(slug => categoriesState.items[slug]))
    .flat() as Category[];
};

export const selectCategories = (state: CategoriesStore, defValue?: any) => {
  const categoriesState = selectCategoriesState(state);
  if (!categoriesState) {
    return defValue;
  }

  return categoriesState.items;
};

export default categories;
