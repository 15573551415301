import axios from 'axios';
import get from 'lodash/get';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('EDIT_GROUP');
export const actions = createActions(names);

const editGroup = ({
  /* eslint-disable @typescript-eslint/camelcase */
  group_id,
  name,
  owner,
  parent,
  groups,
  company_id,
  confirmBonus,
  treeManager,
  /* eslint-enable @typescript-eslint/camelcase */
}) => async dispatch => {
  dispatch(actions.request({ company_id }));
  try {
    const response = await axios.patch(
      AppRouting.generate(
        'api_group_edit_group',
        { company_id, group_id },
        true,
      ),
      {
        user: owner && owner.id,
        name,
        parent,
        requireBonusConfirmation: confirmBonus,
        treeManager,
      },
    );
    await dispatch(
      actions.success({
        company_id,
        owner,
        name,
        group_id,
        parent,
        groups,
        requireBonusConfirmation: confirmBonus,
        treeManager,
      }),
    );
    return response.data;
  } catch (error) {
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('groups|||editFailure'),
    );
    dispatch(actions.failure({ group_id, name, owner, company_id, error }));
    throw new Error(message);
  }
};

export default editGroup;
