import { PurchaseProduct } from './Purchase';

// eslint-disable-next-line import/prefer-default-export
export enum WhipRoundStatus {
  created = 'created',
  started = 'started',
  closed = 'closed',
  removed = 'removed',
  finished = 'finished',
}

// eslint-disable-next-line import/prefer-default-export
export enum WhipRoundMemberStatus {
  prepared = 'prepared',
  invited = 'invited',
  accepted = 'accepted',
  declined = 'declined',
  closed = 'closed',
  finished = 'finished',
  removed = 'removed',
}

export interface WhipRoundMember {
  status: WhipRoundMemberStatus;
  userId: number;
  id: number;
  contribution: number;
}

export interface WhipRoundGiftUser {
  id: number;
  firstName: string;
  fullName?: string;
  lastName: string;
  email: string;
  avatar: string | null;
  userId: string | null;
  normalAvatar: undefined;
}

export interface WhipRoundGift {
  deliver: WhipRoundGiftUser;
  user: WhipRoundGiftUser;
  description: string;
  id: number;
}

export interface WhipRoundOrders {
  createdAt: string;
  gift: WhipRoundGift;
  id: number;
  priceAmount: number;
  product: PurchaseProduct;
  quantity: number;
}

export interface WhipRound {
  ownerId: number;
  orders: WhipRoundOrders[];
  createdAt: string;
  closeAt: string;
  accountId: number | null;
  status: WhipRoundStatus;
  members: WhipRoundMember[];
  maxUsers: number | null;
  id: number;
  startsAt: string;
  expiresAt: string;
  title: string;
  visibility: 'private';
  type: 'fixed_members' | 'fixed_amount';
  description: string | null;
  contribution: number;
  target: number | null;
}

export interface WhipRoundsState {
  items: {
    [id: number]: WhipRound;
  };
  list: number[];
}

export interface WhipRoundSection {
  title: string;
  data: WhipRound[];
}
