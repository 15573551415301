export interface ChartPoint {
  x: string | Date | number;
  y: number | null;
}

export enum ChartSeriesType {
  bar = 'bar',
  line = 'line',
}

export interface ChartSeries {
  data: ChartPoint[];
  color: string;
  type: ChartSeriesType;
  maxima?: number; // Max value on axis
  solid?: boolean; // Line solid style
  label?: string; // Axis label
  suffix?: string; // Axis suffix
  maxPrecision?: number; // Max legend precision
  legendLabel?: string;
}

export interface PieChartSeries {
  data: ChartPoint[];
  colors: string[];
  label: string; // Axis label
}

export enum ChartGranulation {
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
  custom = 'custom',
}

export interface SummaryItem {
  value: number;
  recommendation?: number | null;
  recommendationState?: 'positive' | 'negative' | 'neutral';
  valueSuffix?: string;
  description: string;
}

export interface ExtraChart {
  data: ChartPoint[];
  label: string;
  value: number;
  maxPrecision?: number;
  unit: string;
}

export interface ChartSection {
  chart: ChartSeries[];
  summaryDescription?: string;
  type: 'chart';
  extra?: ExtraChart[];
  summary?: SummaryItem[];
}

export interface PieChartSection {
  chart: PieChartSeries[];
  type: 'pie';
  summary?: SummaryItem[];
  summaryDescription?: string;
}

export interface CategoryPopularityItem {
  label: string;
  icon: string;
  percentage: number;
  value: number;
}

export interface PopularityChartSection {
  chart: { data: CategoryPopularityItem[] }[];
  summary?: SummaryItem[];
  type: 'popularity';
  summaryDescription?: string;
}

export interface GenderChartSection {
  chart: {
    femalePercent: ChartSeries;
    femaleAvg: ChartSeries;
    malePercent: ChartSeries;
    maleAvg: ChartSeries;
  };
  type: 'gender';
  summary?: SummaryItem[];
  summaryDescription?: string;
}

export const InsightsChartTypes = {
  kudos: {
    influence: 'kudos-influence',
    unappreciated: 'kudos-unappreciated',
    scope: 'kudos-scope',
    role: 'kudos-role',
    usage: 'kudos-usage',
  },
  recognition: {
    influence: 'recognition-influence',
    prizes: 'recognition-prizes',
    average: 'recognition-average',
    gender: 'recognition-gender', // GenderChartSection
    agePercentage: 'recognition-age-percentage',
    ageAmount: 'recognition-age-amount',
  },
  budget: {
    accumulation: 'budget-accumulation',
    cart: 'budget-cart',
    topFive: 'budget-topfive',
    categories: 'budget-categories',
  },
};
