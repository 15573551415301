import { Dispatch } from 'redux';
import axios, { AxiosRequestConfig } from 'axios';
import { routing as AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { createActions, asyncNames } from 'shared/actions/utils';
import errorLogger from 'shared/utils/errorLogger';
import { RouteNames } from 'shared/types/RouteNames';
import { TFunction } from 'i18next';
import get from 'lodash/get';
import notify from 'shared/actions/notify';
import { PostEntity, PostResponse } from './PostTypes';

export const fetchPostsNames = asyncNames('FETCH_POSTS');

export const fetchPostsActions = createActions(fetchPostsNames);

export interface PostsParams {
  id: string | number | null;
  entity: PostEntity;
}

export interface PostFilters {
  query?: string;
  type?: string | null;
  joined?: boolean;
  organized?: boolean;
}

const entityTypes: {
  [key: string]: {
    route: RouteNames;
    id: string | null;
    params?: RoutingParams;
  };
} = {
  [PostEntity.challenge]: {
    route: 'api_post_list',
    id: PostEntity.challenge,
  },
  [PostEntity.newsfeed]: {
    route: 'api_post_newsfeed',
    id: 'company',
  },
  [PostEntity.article]: {
    route: 'api_posts_object_get',
    id: 'objectId',
    params: {
      objectType: 'article',
    },
  },
};

export interface FetchPostsAction {
  type: string;
  payload: {
    params: PostsParams;
    page: number;
  };
}

export interface FetchPostsSuccessAction extends FetchPostsAction {
  type: typeof fetchPostsNames.success;
  payload: PostResponse & {
    params: PostsParams & RoutingParams;
  };
}

const fetchPosts =
  (
    params: PostsParams & { trans: TFunction },
    opts: RoutingParams & { page: number },
    axiosOpts?: AxiosRequestConfig,
  ) =>
  async (dispatch: Dispatch<any>) => {
    const { entity, id, trans } = params;
    dispatch(fetchPostsActions.request({ params, page: opts.page }));
    try {
      const config = entityTypes[entity];
      const reqOpts: RoutingParams = {
        max_per_page: 10,
        ...opts,
      };

      if (id && config.id) {
        reqOpts[config.id] = id;
      }

      const response = await axios.get(
        AppRouting.generate(config.route, { ...reqOpts, ...config.params }),
        axiosOpts,
      );
      dispatch(
        fetchPostsActions.success({
          page: opts.page,
          params,
          ...response.data,
        }),
      );
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        dispatch(fetchPostsActions.cancel({ page: opts.page, params, error }));
        return undefined;
      }

      const message = get(
        error,
        'response.data.errors.global',
        trans('employee|||fetchUserFailure'),
      );
      dispatch(notify({ message }));

      errorLogger(error);

      dispatch(fetchPostsActions.failure({ page: opts.page, params, error }));
      throw error;
    }
  };

export default fetchPosts;
