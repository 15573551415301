import produce from 'immer';
import { Action, combineReducers } from 'redux';
import { MetaState } from 'shared/types/reducers/MetaState';
import makeStatusReducer from 'shared/reducers/status';
import makeReactionsStateReducer from '../reactions/makeReactionStateReducer';
import {
  fetchPostsNames,
  FetchPostsSuccessAction,
  PostsParams,
  FetchPostsAction,
} from './fetchPosts';
import {
  postCreatedActionName,
  PostCreatedAction,
  postUpdatedActionName,
  PostUpdatedAction,
  PostDeletedAction,
  postDeletedActionName,
} from './postsActions';
import { Post } from './PostTypes';

const postReactionsReducer = makeReactionsStateReducer('post');

type PostIdType = string | number;

const items = (state: Record<PostIdType, Post> = {}, action: Action<any>) => {
  if (action.type === fetchPostsNames.success) {
    const { payload } = action as FetchPostsSuccessAction;
    return {
      ...state,
      ...payload.entities.posts,
    };
  }
  if (
    action.type === postCreatedActionName ||
    action.type === postUpdatedActionName
  ) {
    const { payload } = action as PostCreatedAction | PostUpdatedAction;
    return {
      ...state,
      [payload.post.id]: payload.post,
    };
  }
  return postReactionsReducer(state, action) as Record<PostIdType, Post>;
};

export const getPostListKey = ({ entity, id }: PostsParams) => {
  return `${entity}|${id}`;
};

const metaReducer = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === fetchPostsNames.success) {
    const { payload } = action as FetchPostsSuccessAction;
    const { entities, data, params, ...meta } = payload;
    return meta;
  }

  return state;
};

const list = (state: PostIdType[] = [], action: Action<any>) => {
  if (action.type === fetchPostsNames.success) {
    const { payload } = action as FetchPostsSuccessAction;

    if (payload.page === 1) {
      return payload.data;
    }
    return [...state, ...payload.data];
  }
  if (action.type === postCreatedActionName) {
    const { payload } = action as PostCreatedAction;

    return [payload.post.id, ...state];
  }
  if (action.type === postDeletedActionName) {
    const { payload } = action as PostDeletedAction;

    return state.filter(id => id !== payload.id);
  }
  return state;
};

const listState = combineReducers({
  meta: metaReducer,
  status: makeStatusReducer(fetchPostsNames, {
    resetLoadedState: action => {
      if (action.type !== fetchPostsNames.request) {
        return false;
      }
      const { payload } = action as FetchPostsAction;
      return payload.page === 1;
    },
  }),
  list,
});

interface PostLits {
  [key: string]: ReturnType<typeof listState>;
}

const lists = (state: PostLits = {}, action: Action<any>) => {
  if (
    action.type === fetchPostsNames.success ||
    action.type === fetchPostsNames.request ||
    action.type === fetchPostsNames.failure ||
    action.type === fetchPostsNames.cancel ||
    action.type === postCreatedActionName
  ) {
    const { payload } = action as FetchPostsAction | PostCreatedAction;
    const key = getPostListKey(payload.params);
    return {
      ...state,
      [key]: listState(state[key], action),
    };
  }
  if (action.type === postDeletedActionName) {
    return produce(state, draft => {
      Object.keys(state).forEach(key => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = listState(state[key], action);
      });
    });
  }

  return state;
};

const posts = combineReducers({
  items,
  lists,
});

export type PostsState = ReturnType<typeof posts>;

export default posts;
