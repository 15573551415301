/* eslint-disable @typescript-eslint/naming-convention */
import { names } from 'shared/actions/groups/fetch';
import { names as addNames } from 'shared/actions/groups/add';
import { names as deleteNames } from 'shared/actions/groups/delete';
import { names as groupNames } from 'shared/actions/groups/fetchGroup';
import { names as editNames } from 'shared/actions/groups/edit';
import { names as userDataNames } from 'shared/actions/user/data';
import { names as updateCountNames } from 'shared/actions/groups/updateMemberCount';
import { names as accountNames } from 'shared/actions/accounts';
import { names as challengeGroupsNames } from 'shared/modules/challenges/fetchChallengeGroups';

import merge from 'lodash/merge';

import without from 'lodash/without';
import { setChallengeGroupsName } from 'shared/modules/challenges/challengeGroupsAction';

const items = (state = {}, { type, payload }) => {
  if (type === names.success || type === challengeGroupsNames.success) {
    return { ...state, ...payload.data.entities.groups };
  }
  if (type === groupNames.success) {
    const { group } = payload;
    const prev = state[group.id] || {};
    return { ...state, [group.id]: { ...prev, ...group } };
  }
  if (type === editNames.success) {
    const {
      owner,
      name,
      group_id: groupId,
      parent,
      groups,
      submitProcess,
      requireBonusConfirmation,
    } = payload;
    const prev = state[groupId] || {};

    const newState = {
      ...state,
      ...groups,
      [groupId]: {
        ...prev,
        name,
        user_id: owner ? owner.id : null,
        submitProcess,
        parent_id: parent,
        requireBonusConfirmation,
      },
    };

    return newState;
  }
  if (type === updateCountNames.update) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { member_count, id } = payload;
    const prev = state[id];
    if (!prev) {
      return state;
    }
    return {
      ...state,
      [id]: {
        ...prev,
        member_count,
      },
    };
  }
  if (type === deleteNames.success) {
    let newState = { ...state };
    payload.groups.forEach(key => {
      const group = newState[key];
      if (group.parent_id && newState[group.parent_id]) {
        const parent = newState[group.parent_id];
        const children = without(parent.children, key);
        newState = { ...newState, [parent.id]: { ...parent, children } };
      }
    });
    return newState;
  }
  if (type === addNames.success) {
    const { group } = payload;
    const newState = { ...state, [group.id]: group };
    if (group.parent_id) {
      const parent = { ...newState[group.parent_id] };
      parent.children = parent.children
        ? [...parent.children, group.id]
        : [group.id];
      newState[group.parent_id] = parent;
    }
    return newState;
  }
  if (
    type === 'UPDATE_ACCOUNTS' ||
    type === accountNames.success ||
    type === setChallengeGroupsName
  ) {
    return merge({}, state, payload.entities.groups);
  }
  if (type === userDataNames.success) {
    return merge({}, state, payload.accounts.entities.groups);
  }

  return state;
};

export default items;
