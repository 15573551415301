import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { Group } from 'shared/types/Group';
import { AppRouting } from 'shared/utils/AppRouting';
import { User } from 'shared/types/User';
import { Dispatch } from 'react';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('ADD_GROUP');
export const actions = createActions(names);

const addGroup = ({
  name,
  userId,
  // eslint-disable-next-line @typescript-eslint/camelcase
  company_id,
  type = 4,
  owner,
  parentId,
  treeManager,
  visibleZfssDeclarations,
  visibleBenefits,
  visibleProcesses,
  ownerCanConfirmBenefitDocuments,
  confirmBonus,
}: {
  name: string;
  company_id: number;
  userId?: number | null;
  confirmBonus?: boolean;
  treeManager?: boolean;
  visibleBenefits?: boolean;
  visibleZfssDeclarations?: boolean;
  visibleProcesses?: boolean;
  ownerCanConfirmBenefitDocuments?: boolean;
  type?: number;
  parentId?: number | null;
  owner?: User | null;
}) => async (dispatch: Dispatch<any>) => {
  dispatch(actions.request({ company_id }));
  try {
    const response = await axios.post<Group>(
      AppRouting.generate('api_group_create', { company_id }, true),
      {
        group: name,
        user: userId,
        type,
        parent: parentId,
        requireBonusConfirmation: confirmBonus,
        treeManager,
        visibleZfssDeclarations,
        visibleBenefits,
        visibleProcesses,
        ownerCanConfirmBenefitDocuments,
      },
    );
    await dispatch(
      actions.success({ company_id, group: response.data, owner }),
    );
    dispatch(
      // @ts-ignore
      notify({ type: 'success', message: i18next.t('groups|||addSuccess') }),
    );

    return response.data;
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      // @ts-ignore
      i18next.t('groups|||addFailure'),
    );
    dispatch(actions.failure({ company_id, error, parentId }));
    throw new Error(message);
  }
};

export default addGroup;
