import { Action } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import {
  setScoresActionName,
  setParticipantsActionName,
} from 'shared/modules/challenges/challengesActions';
import { feedbackNames } from 'shared/modules/feedback/feedbackActions';
import keyBy from 'lodash/keyBy';
import { ADD_USERS_ENTITIES } from 'shared/actions/users/addUsers';
import { EmployeeData, EmployeeSearchItem } from 'shared/types/Employee';
import { fetchOrganizersActionsNames } from 'shared/modules/challenges/organizerActions';
import {
  fetchDocumentNames,
  FetchDocumentSuccessAction,
  fetchDocumentsWrappersNames,
  FetchDocumentsWrappersSuccessAction,
} from '../modules/documents/documentsWrappersActions';
import { fetchPostsNames } from '../modules/posts/fetchPosts';
import {
  fetchSubaccount,
  fetchSubaccounts,
} from '../modules/budgets/subaccountsStore';
import {
  fetchPreliminaries,
  fetchPreliminary,
} from '../modules/budgets/preliminaryStore';

export interface EmployeeDataState {
  [id: number]: EmployeeData;
}

interface EmployeesAction {
  type: string;
  payload: {
    entities: {
      employees: EmployeeData;
    };
  };
}

interface EmployeesResponseAction {
  type: string;
  payload: {
    response: {
      entities: {
        employees: EmployeeData;
      };
    };
  };
}

export const employeeSearchResultAction = createAction<EmployeeSearchItem[]>(
  'employee/searchResult',
);

const actionNames = [
  setParticipantsActionName,
  setScoresActionName,
  fetchOrganizersActionsNames.success,
  ADD_USERS_ENTITIES,
  fetchPostsNames.success,
  fetchSubaccount.fulfilled.type,
  fetchSubaccounts.fulfilled.type,
  fetchPreliminary.fulfilled.type,
  fetchPreliminaries.fulfilled.type,
];

function isEmployeesAction(action: Action<any>): action is EmployeesAction {
  if (actionNames.includes(action.type)) {
    return true;
  }

  return false;
}

const employeeData = (state: EmployeeDataState = {}, action: Action<any>) => {
  if (
    action.type === feedbackNames.list.success ||
    action.type === feedbackNames.setFeedback
  ) {
    const entities = (action as EmployeesResponseAction).payload.response
      ?.entities;
    return { ...state, ...entities?.employees };
  }

  if (isEmployeesAction(action)) {
    const entities = action.payload?.entities;
    return { ...state, ...entities?.employees };
  }
  if (action.type === employeeSearchResultAction.type) {
    const { payload } = action as ReturnType<typeof employeeSearchResultAction>;
    const employees = keyBy(payload, 'employeeId');
    return {
      ...state,
      ...employees,
    };
  }
  if (
    action.type === fetchDocumentNames.success ||
    action.type === fetchDocumentsWrappersNames.success
  ) {
    const { payload } = action as
      | FetchDocumentSuccessAction
      | FetchDocumentsWrappersSuccessAction;
    return {
      ...state,
      ...payload.response.entities.employees,
    };
  }

  return state;
};

export default employeeData;
