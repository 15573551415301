import { names } from 'shared/actions/user/registration';

const registration = (state = null, { type, payload }) => {
  if (type === names.success) {
    return payload;
  }
  if (type === names.failure) {
    return payload;
  }
  return state;
};

export default registration;
