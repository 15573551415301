import { combineReducers } from 'redux';
import { names } from 'shared/actions/groups/fetch';
import { names as deleteNames } from 'shared/actions/groups/delete';
import { names as addNames } from 'shared/actions/groups/add';
import without from 'lodash/without';
import makeStatusReducer from '../status';

const makeKey = payload => payload.company_id || payload.params.company_id;

const meta = (state = {}, { type, payload }) => {
  if (type === names.success) {
    const { entities, data, ...stats } = payload.data.result;
    return stats;
  }
  return state;
};

const list = (state = [], { type, payload }) => {
  if (type === names.success) {
    const { result } = payload.data;
    if (payload.params.page > 1) {
      return [...state, ...result.data];
    }
    return result.data;
  }
  if (type === deleteNames.success) {
    return without(state, ...payload.groups);
  }
  if (type === addNames.success && !payload.group.parent_id) {
    return [...state, payload.group.id];
  }
  return state;
};

const item = combineReducers({
  status: makeStatusReducer(names),
  meta,
  list,
});

const items = (state = {}, action) => {
  const actions = [
    ...Object.values(names),
    ...Object.values(deleteNames),
    addNames.success,
  ];
  if (actions.includes(action.type)) {
    const key = makeKey(action.payload);
    return { ...state, [key]: item(state[key], action) };
  }
  return state;
};

export default items;
