import { combineReducers } from 'redux';
import {
  names,
  OpenNotificationAction,
  CloseNotificationAction,
  NotificationsRegisterAction,
  NotificationsUnregisterAction,
  ToastNotification,
} from 'shared/actions/notify';

export const listInitial: ToastNotification[] = [];
export const registryInitial: string[] = ['main'];

const list = (
  state = listInitial,
  action: CloseNotificationAction | OpenNotificationAction,
) => {
  if (action.type === names.open) {
    const { payload } = action as OpenNotificationAction;
    return [...state, payload];
  }
  if (action.type === names.close) {
    return state.filter(item => item.id !== action.payload.id);
  }
  return state;
};

const registry = (
  state: string[] = registryInitial,
  action: NotificationsRegisterAction | NotificationsUnregisterAction,
) => {
  if (action.type === names.register) {
    return [...state, action.payload.uid];
  }
  if (action.type === names.unregister) {
    return state.filter(uid => uid !== action.payload.uid);
  }
  return state;
};

const notifications = combineReducers({
  registry,
  list,
});

export type NotificationsState = ReturnType<typeof notifications>;

export default notifications;
