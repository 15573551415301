import {
  names as reactionNames,
  ReactionActions,
  ReactionEntity,
} from 'shared/modules/reactions/reactionActions';
import produce from 'immer';
import { Action } from 'redux';
import { ReactionsResponse } from '../comments/Comment';

interface WithReactionsState {
  [id: number]: {
    reactions: ReactionsResponse;
  };
}

const makeReactionsStateReducer = (entity: ReactionEntity) => (
  state: WithReactionsState,
  action: ReactionActions | Action<any>,
) => {
  if (action.type === reactionNames.add) {
    const { payload } = action as ReactionActions;
    if (payload.entity !== entity) {
      return state;
    }
    const nextState = produce(state, draftState => {
      const item = draftState[payload.id];
      if (!item) {
        return;
      }
      const reactions = item.reactions[payload.type];
      // eslint-disable-next-line no-param-reassign
      item.reactions[payload.type] = [...reactions, payload.userId];
    });
    return nextState;
  }

  if (action.type === reactionNames.undo) {
    const { payload } = action as ReactionActions;
    if (payload.entity !== entity) {
      return state;
    }
    const nextState = produce(state, draftState => {
      const item = draftState[payload.id];
      if (!item) {
        return;
      }
      const reactions = item.reactions[payload.type];
      // eslint-disable-next-line no-param-reassign
      item.reactions[payload.type] = reactions.filter(
        id => id !== payload.userId,
      );
    });
    return nextState;
  }

  return state;
};

export default makeReactionsStateReducer;
