import createAction from 'redux-actions/lib/createAction';
import { safeLocalStorage } from 'shared/utils/safeStorage';
import errorLogger from '../utils/errorLogger';

export const names = {
  set: 'CURRENT_COMPANY_SET',
};

const set = createAction(names.set);

const webStoreId = id => safeLocalStorage.setItem('company_id', `${id}`);

const getId = params => {
  try {
    if (!params || !params.id) {
      throw new Error('Set company missing params or id');
    }
    const { id } = params;
    return id;
  } catch (ex) {
    errorLogger(ex);
    throw ex;
  }
};

export const getSetCurrentCompany = storeId => params => async dispatch => {
  await dispatch(set(params));

  try {
    const id = getId(params);
    storeId(id);
  } catch (ex) {
    // no localStorage
  }
};

export default getSetCurrentCompany(webStoreId);
