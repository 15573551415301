import { Action } from 'redux';
import { names as companyNames } from 'shared/actions/user/fetchCompanies';
import { Employee } from 'shared/types/Employee';

export interface EmployeesState {
  [id: number]: Employee;
}

interface EmployeesAction {
  type: string;
  payload: {
    entities: {
      employees: EmployeesState;
    };
  };
}

function isEmployeesAction(action: Action<any>): action is EmployeesAction {
  return action.type === companyNames.success;
}

const employees = (state: EmployeesState = {}, action: Action<any>) => {
  if (isEmployeesAction(action)) {
    const { entities } = action.payload;
    return { ...state, ...entities.employees };
  }
  return state;
};

export default employees;
