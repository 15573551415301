import { names as userNames } from 'shared/actions/users/fetch';
import { names as groupsNames } from 'shared/actions/groups/fetch';
import { names as whipRounds } from 'shared/actions/whipRounds';
import { names as editNames } from 'shared/actions/groups/edit';
import { names as groupAddNames } from 'shared/actions/groups/add';
import { names as addNames } from 'shared/actions/users/addEmployee';
import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';
import { names as purchaseNames } from 'shared/actions/purchases/fetchPurchase';
import { names as kudosNames } from 'shared/actions/fetchKudos';
import { names as kudosSingleNames } from 'shared/actions/fetchSingleKudos';
import produce from 'immer';

import merge from 'lodash/merge';
import { ADD_USERS, ADD_USERS_ENTITIES } from 'shared/actions/users/addUsers';
import { names as commentActionsNames } from 'shared/modules/comments/commentsActions';
import { names as reactionActionsNames } from 'shared/modules/reactions/reactionActions';
import { toggleReceptionActionName } from 'shared/hooks/company/useConfirmEntry';
import { fetchPostsNames } from 'shared/modules/posts/fetchPosts';

const items = (state = {}, { type, payload }) => {
  if (type === toggleReceptionActionName) {
    const { userId, employeeId, enabled } = payload;
    return produce(state, draftState => {
      if (!draftState[userId]?.employees?.[employeeId]?.hasReceptionView) {
        return;
      }
      /* eslint-disable no-param-reassign */
      draftState[userId].employees[employeeId].hasReceptionView = enabled;
      /* eslint-enable no-param-reassign */
    });
  }
  if (type === userNames.success) {
    const { user } = payload;
    return { ...state, [user.id]: user };
  }
  if (type === editNames.success || type === groupAddNames.success) {
    const { owner } = payload;
    if (!owner || state[owner.id]) {
      return state;
    }
    return { ...state, [owner.id]: owner };
  }

  if (type === addNames.success) {
    const users = {};
    payload.users.forEach(user => {
      users[user.id] = user;
    });

    return { ...state, ...users };
  }
  if (type === groupsNames.success) {
    const { users } = payload.data.result.entities;
    return { ...state, ...users };
  }

  if (
    type === whipRounds.fetch.success ||
    type === whipRounds.single.success ||
    type === commentActionsNames.success ||
    type === kudosNames.success ||
    type === kudosSingleNames.success ||
    type === ADD_USERS_ENTITIES ||
    type === fetchPostsNames.success
  ) {
    const { users } = payload.entities;
    return merge({}, state, users);
  }

  // FIXME!!!
  // type === whipRounds.join
  if (type === reactionActionsNames.add) {
    const { user } = payload;
    return merge({}, state, { [user.id]: user });
  }
  if (type === ADD_USERS) {
    const { users } = payload;
    return merge({}, state, users);
  }
  if (type === purchasesNames.success) {
    return merge({}, state, payload.data.entities.users);
  }
  if (type === purchaseNames.success) {
    return merge({}, state, payload.entities.users);
  }
  return state;
};

export default items;
