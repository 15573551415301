import { combineReducers } from 'redux';
import categories from 'shared/modules/documents/categoriesStore';
import wrappers from 'shared/modules/documents/documentsWrappersStore';

const documents = combineReducers({
  categories,
  wrappers,
});

export type DocumentsState = ReturnType<typeof documents>;

export default documents;
