import get from 'lodash/get';
// import { normalize } from 'normalizr';
import axios from 'shared/utils/axios';
import { actions as account } from 'shared/actions/accounts';
import { actions as purchase } from 'shared/actions/purchases/fetchPurchase';

import { asyncNames, createActions } from '../utils';

export const names = asyncNames('BUY_PRODUCT');
export const actions = createActions(names);

const buy = params => async dispatch => {
  const {
    id,
    quantity,
    price,
    providerData,
    accountId,
    subaccountId,
    gift,
    documentSubmit,
    virtualCardId,
  } = params;
  const data = {
    quantity: parseInt(quantity, 10),
  };

  if (providerData) {
    data.providerData = providerData;
  }
  if (price != null) {
    data.priceAmount = price;
  }
  if (accountId) {
    data.account = accountId;
  }
  if (subaccountId) {
    data.subaccount = subaccountId;
  }
  if (gift) {
    data.description = gift.description;
    data.userId = gift.id;
  }
  if (documentSubmit) {
    data.documentSubmit = documentSubmit;
  }

  if (virtualCardId) {
    data.card = virtualCardId;
  }

  dispatch(actions.request(params));

  const response = await axios.post(
    AppRouting.generate('api_product_buy', {
      id,
    }),
    data,
  );
  const accounts = get(response, 'data.entities.accounts');
  if (accounts) {
    dispatch(account.update(accounts));
  }
  dispatch(actions.success({ ...data, purchaseId: response.data.data.id }));
  dispatch(purchase.success(response.data));
  return response;
};

export default buy;
