/* eslint-disable no-param-reassign */
import { Action, combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import { MetaState } from 'shared/types/reducers/MetaState';
import { EmployeeSearchItem } from 'shared/types/Employee';
import produce from 'immer';
import keyBy from 'lodash/keyBy';
import {
  EmployeesListAction,
  employeesListActionNames,
  FetchEmployeesParams,
  EmployeesListSuccessAction,
  DELETE_EMPLOYEES,
  DeleteEmployeeAction,
} from './employeeListActions';

export type EmployeesListIdsState = number[];
export type EmployeesSearchItemsState = Record<
  string | number,
  EmployeeSearchItem
>;

const items = (
  state: EmployeesSearchItemsState = {},
  action: EmployeesListSuccessAction | DeleteEmployeeAction,
) => {
  if (action.type === employeesListActionNames.success) {
    const { payload } = action as EmployeesListSuccessAction;
    const employees = keyBy(payload.response.data, 'employeeId');
    return { ...state, ...employees };
  }
  if (action.type === DELETE_EMPLOYEES) {
    const { payload } = action as DeleteEmployeeAction;
    return produce(state, draft => {
      payload.employees.forEach(id => {
        if (!draft[id]) {
          return;
        }
        draft[id].deletionDate = payload.deletionDate;
        draft[id].terminationDate = payload.terminationDate;
        draft[id].refundAfterDelete = payload.refundAfterDelete;
      });
    });
  }
  return state;
};

const list = (
  state: Record<number, EmployeesListIdsState> = {},
  action: EmployeesListSuccessAction,
) => {
  if (action.type === employeesListActionNames.success) {
    const { payload } = action as EmployeesListSuccessAction;

    const { data } = payload.response;
    const ids = data.map(item => item.employeeId);
    return {
      ...state,
      [payload.page]: ids,
    };
  }
  return state;
};

export const getEmployeesListKey = ({
  companyId,
  filter,
  bonus,
  zfss,
  prize,
  corporate,
  formOfEmployment,
  role,
  sort,
  corporateGroupCompany,
  perPage,
  zfssBonus,
  bonusDestinationId,
  cross,
  groupId,
}: FetchEmployeesParams) => {
  const parts: string[] = [`company:${companyId || 0}`];
  if (bonus) {
    parts.push('bonus');
  }
  if (zfssBonus) {
    parts.push('zfssBonus');
  }
  if (cross) {
    parts.push('cross');
  }
  if (bonusDestinationId) {
    parts.push(`destination:${bonusDestinationId}`);
  }
  if (groupId) {
    parts.push(`groupId:${groupId}`);
  }
  if (zfss) {
    parts.push('zfss');
  }
  if (prize) {
    parts.push('prize');
  }
  if (corporate) {
    parts.push('corporate');
  }
  if (formOfEmployment) {
    parts.push(`formOfEmployment:${formOfEmployment}`);
  }
  if (role) {
    parts.push(`role:${role}`);
  }
  if (filter) {
    parts.push(`sort:${sort}`);
  }
  if (corporateGroupCompany) {
    parts.push(`corporateGroupCompany:${corporateGroupCompany}`);
  }
  if (perPage) {
    parts.push(`perPage:${perPage}`);
  }
  return parts.join('|');
};

const meta = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === employeesListActionNames.success) {
    const { payload } = action as EmployeesListSuccessAction;

    const { data, ...rest } = payload.response;

    return rest;
  }
  return state;
};

const employeesActions = Object.values(employeesListActionNames);
const listReducer = combineReducers({
  list,
  meta,
  status: makeStatusReducer(employeesListActionNames),
});

export type EmployeesList = ReturnType<typeof listReducer>;

const lists = (
  state: Record<string, EmployeesList> = {},
  action: Action<any>,
) => {
  if (employeesActions.includes(action.type)) {
    const { payload } = action as EmployeesListAction;
    const key = getEmployeesListKey(payload.params);
    return {
      ...state,
      [key]: listReducer(state[key], action),
    };
  }
  return state;
};

const employeeList = combineReducers({
  lists,
  items,
});

export type EmployeesListState = ReturnType<typeof employeeList>;

export default employeeList;
