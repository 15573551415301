import { getFetchAction, asyncNames, createActions } from './utils';

export const names = {
  ...asyncNames('SET_CHALLENGE_COMPANY_GROUP_LIST'),
};

export const actions = createActions(names);

export const fetchChallengeCompanyGroupList = getFetchAction(
  actions,
  'api_challenge_company_group_list',
);

export default fetchChallengeCompanyGroupList;
