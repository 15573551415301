import urlParse from 'url-parse';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import addMinutes from 'date-fns/addMinutes';
import { URL_LOGIN } from 'shared/utils/user/localUsers';

const url = urlParse(window.location, true);

if (url.query && url.query.token) {
  const { token } = url.query;
  try {
    safeLocalStorage.removeItem('rememberMe');
    // clear local
    safeLocalStorage.removeItem('company_id');
    safeLocalStorage.removeItem('token');
    safeLocalStorage.removeItem('expires');
  } catch (ex) {
    // No localstorage
  }
  try {
    safeSessionStorage.removeItem('token');
    safeSessionStorage.removeItem('expires');
    safeSessionStorage.removeItem('company_id');
    safeSessionStorage.removeItem(URL_LOGIN);
  } catch (ex) {
    // no sessionStrogate
  }
  try {
    safeSessionStorage.setItem('token', token);
    safeSessionStorage.setItem('urlLogin', '1');
    safeSessionStorage.setItem(
      'expires',
      addMinutes(new Date(), 1).toISOString(),
    );
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '', url.pathname);
  } catch (ex) {
    // something went wrong!
  }
}
