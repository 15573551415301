const TEST_KEY = 'ls-test';

const getMemoryStorage = () => {
  const memory = {};
  const memoryStorage = {
    getItem: key => memory[key],
    setItem: (key, value) => {
      memory[key] = value;
    },
    removeItem: key => {
      delete memory[key];
    },
  };
  return memoryStorage;
};

const isStorageSupported = storage => {
  if (typeof storage !== 'object') {
    return false;
  }
  // Test storage
  try {
    storage.setItem(TEST_KEY, 'test');
    storage.removeItem(TEST_KEY);
  } catch (e) {
    return false;
  }
  return true;
};

const getSafeStorage = storage => {
  const isSupported = isStorageSupported(storage);
  if (isSupported) {
    return storage;
  }
  return getMemoryStorage();
};

export default getSafeStorage;
