import { createAction } from 'redux-actions';

export const names = {
  setUserCompany: 'SET_USER_COMPANY',
  setViewPermissions: 'SET_VIEW_PERMISSIONS',
};

const setUserCompany = createAction(names.setUserCompany);
export const setViewPermissions = createAction(names.setViewPermissions);

export default setUserCompany;
