const getEnv = (env = 'APP_ENV'): string | undefined => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window[env] === 'undefined') {
      return undefined;
    }
    // @ts-ignore
    return window[env];
  }
  // @ts-ignore this throws in RN but is not actually required as the .native version is used
  return process.env[env];
};

export const envTrue = (env?: string) => env === '1' || env === 'true';

export const checkEnv = (env: string) => envTrue(getEnv(env));

export default getEnv;
