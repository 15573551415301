import { createActions, asyncNames } from 'shared/actions/utils';
import { SortOption } from 'shared/utils/employee';
import { MetaState } from 'shared/types/reducers/MetaState';
import { createAction } from 'redux-actions';
import { EmployeeSearchItem } from 'shared/types/Employee';

export const employeesListActionNames = asyncNames('EMPLOYEES_LIST') as {
  success: 'EMPLOYEES_LIST_SUCCESS';
  failure: 'EMPLOYEES_LIST_FAILURE';
  request: 'EMPLOYEES_LIST_REQUEST';
  cancel: 'EMPLOYEES_LIST_CANCEL';
};

export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';

export const employeesListAction = createActions(employeesListActionNames);

export type FetchEmployeesParams = {
  companyId: number;
  filter: string;
  role?: string;
  formOfEmployment?: string;
  statuses?: string;
  perPage: number;
  corporate?: boolean;
  sort?: SortOption | null;
  corporateGroupCompany?: number | null;
  zfss?: boolean;
  bonus?: boolean;
  prize?: boolean;
  zfssBonus?: boolean;
  groupId?: number;
  cross?: boolean;
  bonusDestinationId?: number;
};

export interface EmployeesListActionPayload {
  params: FetchEmployeesParams;
  page: number;
}

export interface DeleteEmployeesPayload {
  params: FetchEmployeesParams;
  page: number;
}

export interface DeleteEmployeesParams {
  employees: number[];
  deletionDate: string | null;
  terminationDate: string | null;
  refundAfterDelete: boolean;
}

export const deleteEmployeesAction = createAction<DeleteEmployeesParams>(
  DELETE_EMPLOYEES,
);

export type DeleteEmployeeAction = ReturnType<typeof deleteEmployeesAction>;

export interface EmployeesListSuccessActionPayload
  extends EmployeesListActionPayload {
  response: {
    data: EmployeeSearchItem[];
  } & MetaState;
}

export interface EmployeesListSuccessAction {
  type: typeof employeesListActionNames.success;
  payload: EmployeesListSuccessActionPayload;
}

export interface EmployeesListAction {
  type: string;
  payload: EmployeesListSuccessActionPayload;
}
