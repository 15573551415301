import axios from 'axios';
import get from 'lodash/get';
import { asyncNames, createActions } from './utils';

export const names = asyncNames('FETCH_GRADES');
export const actions = createActions(names);

const GRADE_ID = 3;
const fetchGrades = params => async (dispatch) => {
  dispatch(actions.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_group_get_groups',
        {
          ...params,
          type: GRADE_ID,
        },
        true,
      ),
    );
    await dispatch(actions.success({ ...params, grades: response.data.data }));
    return response.data.data;
  } catch (error) {
    console.log(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||viewError'),
    );
    dispatch(actions.failure({ error, ...params }));
    throw new Error(message);
  }
};

export default fetchGrades;
