import { combineReducers } from 'redux';
import items from './items';
import lists from './lists';

const products = combineReducers({
  items,
  lists,
});

export default products;
