import { getFetchAction, asyncNames, createActions } from './utils';

export const names = {
  ...asyncNames('KUDOS_CONFIG'),
};

export const actions = createActions(names);

export const fetchKudosConfig = getFetchAction(actions, 'api_kudos_type_get');

export default fetchKudosConfig;
