import merge from 'lodash/merge';

import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';

const initial = {};

const items = (state = initial, { type, payload }) =>
  type === purchasesNames.success
    ? merge({}, state, payload.data.entities.cards)
    : state;

export default items;
