import { createSpacing } from 'spacing-helper';
import { css } from 'styled-components';

export const SPACE_FACTOR = 10;

export const pxToRem = (px: number) => px / 16;

export const REM_FACTOR = pxToRem(SPACE_FACTOR);

export type ISpacingFn = (
  first?: number,
  second?: number,
  third?: number,
  fourth?: number,
) => string;

const px = createSpacing({ factor: SPACE_FACTOR }) as ISpacingFn; // 8 is default scaling factor

const rem = createSpacing({
  factor: REM_FACTOR,
  units: 'rem',
  precision: 4,
}) as ISpacingFn;

const em = createSpacing({
  factor: REM_FACTOR,
  units: 'em',
  precision: 4,
}) as ISpacingFn;

const numberEm = (size = 1) => REM_FACTOR * size;
const number = (size = 1) => SPACE_FACTOR * size;

const space = Object.assign(px, {
  px,
  rem,
  em,
  numberEm,
  number,
});

export type SpaceArgs =
  | [number]
  | [number, number]
  | [number, number, number]
  | [number, number, number, number];

export const callSpace = (args?: SpaceArgs) => {
  if (!args) {
    return space(0);
  }
  return space(...args);
};

export const marginCss = css<{ margin?: SpaceArgs }>`
  margin: ${(p) => callSpace(p.margin)};
`;
export const marginMobileCss = css<{ marginMobile?: SpaceArgs }>`
  margin: ${(p) => callSpace(p.marginMobile)};
`;

export const marginCssArgs = css<{ marginArgs?: SpaceArgs }>`
  margin: ${(p) => callSpace(p.marginArgs)};
`;

export const conditionalMarginCss = css<{ margin?: SpaceArgs }>`
  ${(p) => (p.margin ? marginCss : '')};
`;

export const conditionalMarginCssArgs = css<{ marginArgs?: SpaceArgs }>`
  ${(p) => (p.marginArgs ? marginCssArgs : '')};
`;

export const paddingCss = css<{ padding?: SpaceArgs }>`
  padding: ${(p) => callSpace(p.padding)};
`;

export type Space = typeof space;

export default space;
