import axios from 'axios';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { AsyncNames, asyncNames, createActions } from 'shared/actions/utils';
import { MetaState } from 'shared/types/reducers/MetaState';
import { AppRouting } from 'shared/utils/AppRouting';
import { Notification } from '../Notification';

export const notificationListActionNames = asyncNames(
  'FETCH_NOTIFICATIONS_LIST',
);

export const names: {
  message: 'WEBSOCKET_MESSAGE';
  handleSurcharge: 'WEBSOCKET_HANDLE_SURCHARGE';
  markRead: 'MARK_MESSAGES_READ';
  markAllRead: 'MARK_ALL_MESSAGES_READ';
} & AsyncNames<'FETCH_NOTIFICATIONS_LIST'> = {
  ...notificationListActionNames,
  message: 'WEBSOCKET_MESSAGE',
  handleSurcharge: 'WEBSOCKET_HANDLE_SURCHARGE',
  markRead: 'MARK_MESSAGES_READ',
  markAllRead: 'MARK_ALL_MESSAGES_READ',
};

const message = createAction<Notification>(names.message);
const handleSurcharge = createAction(names.handleSurcharge);

const markMessagesRead = createAction(names.markRead);
const markAllMessagesRead = createAction(names.markAllRead);

export const notificationListActions = createActions(
  notificationListActionNames,
);

interface FetchNotificationsParams {
  page: number;
  perPage?: number;
}

export interface NotificationsMetaState extends MetaState {
  unreadCount: number;
}

interface FetchNotificationsResponse extends NotificationsMetaState {
  data: string[];
  entities: {
    notifications: Record<string, Notification>;
  };
}

export const fetchNotifications =
  (params: FetchNotificationsParams) => async (dispatch: Dispatch<any>) => {
    const { page, perPage } = params;
    dispatch(notificationListActions.request({ params }));
    const url = AppRouting.generate('api_notification_list', {
      page,
      max_per_page: perPage || 20,
    });
    try {
      const response = await axios.get<FetchNotificationsResponse>(url);
      const { data, entities, ...meta } = response.data;
      await dispatch(
        notificationListActions.success({ params, meta, data, entities }),
      );
    } catch (error) {
      console.error(error);
      dispatch(notificationListActions.failure({ params, error }));
      // Due to whistleblowers redirect i going to disable throwed error, it will be only in console
      // throw error;
    }
  };

export interface MarkMessagesReadAction {
  type: typeof names.markRead;
  payload: {
    messages: Notification[];
  };
}

export interface MarkAllMessagesReadAction {
  type: typeof names.markAllRead;
  payload: {};
}

export interface WebsocketMessageAction {
  type: typeof names.message;
  payload: Notification;
}

export interface NotificationListSuccessAction {
  type: typeof names.success;
  payload: {
    data: string[];
    meta: NotificationsMetaState;
    params: FetchNotificationsParams;
    entities: {
      notifications: Record<string, Notification>;
    };
  };
}

export interface HandleSurchargeAction {
  type: typeof names.handleSurcharge;
  payload: boolean;
}

const actions = {
  message,
  markMessagesRead,
  markAllMessagesRead,
  handleSurcharge,
};

export default actions;
