import createAction from 'redux-actions/lib/createAction';
import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import notify from './notify';
import { asyncNames, createActions } from './utils';
// import mock from './categories.mock';

export const names = {
  set: 'CATEGORIES_SET',
  clear: 'CATEGORIES_CLEAR',
};

export const fetchNames = asyncNames('FETCH_CATEGORIES');
const set = createAction(names.set);
export const clear = createAction(names.clear);

export const categories = createActions(fetchNames);

// const getMockNewCategories = () =>
//   new Promise(resolve => {
//     setTimeout(() => {
//       resolve({ data: mock });
//     }, 200);
//   });

export const fetchCategories = (params = {}, noNotify = false) => async (dispatch, getState) => {
  dispatch(categories.request({ params }));
  try {
    // const response = await getMockNewCategories();
    const response = await axios(AppRouting.generate('api_get_categories_section', {}, true));
    const locale = params.locale || getState().locale;
    return dispatch(categories.success({ locale, params, data: response.data }));
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('products|||categoriesFetchFailure'),
    );
    if (!noNotify) {
      dispatch(notify({ message }));
    }
    dispatch(categories.failure({ params, error }));
  }
  return undefined;
};

export default set;
