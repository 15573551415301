import { names } from 'shared/actions/user/fetchCompanies';
import { names as userDataNames } from 'shared/actions/user/data';
import produce from 'immer';
import merge from 'lodash/merge';
import { names as accountNames } from 'shared/actions/accounts';
import { names as updateReportDay } from 'shared/actions/company/updateReportDay';
import { Company } from 'shared/types/Company';
import uniq from 'lodash/uniq';
import addZfssMinMax from 'shared/utils/zfss/minMaxIntervals';
import { Employee } from 'shared/types/Employee';
import { Action } from 'redux-actions';
import { EmployeesState } from './employees';

const initial = {};

const getRoles = (companyId: number, employees: EmployeesState) => {
  const companyEmployees = (Object.values(employees) as Employee[]).filter(
    employee => employee.companyId === companyId,
  );
  return uniq(companyEmployees.map(e => e.role));
};

const addRoles = (companies: CompaniesState, employees: EmployeesState) =>
  produce(companies, draftData => {
    const keys = Object.keys(draftData);
    keys.forEach(key => {
      const company = companies[Number(key)];
      company.roles = getRoles(company.id, employees);
    });
  });

const decorateCompanies = ({
  companies,
  employees,
}: {
  companies?: CompaniesState;
  employees?: EmployeesState;
}) => {
  return addRoles(addZfssMinMax(companies || {}), employees || {});
};
export interface CompaniesState {
  [id: number]: Company;
}

const companies = (
  state: CompaniesState = initial,
  { type, payload }: Action<any>,
): CompaniesState => {
  if (type === names.success) {
    return decorateCompanies(payload.entities);
  }
  if (type === 'UPDATE_ACCOUNTS' || type === accountNames.success) {
    return merge({}, state, decorateCompanies(payload.entities));
  }
  if (type === userDataNames.success) {
    const mergedCompanies = merge(
      {},
      // HACK: this can contain incomplete companies
      payload.accounts.entities.companies,
      payload.companies || {},
    );
    return merge(
      {},
      state,
      decorateCompanies({
        ...payload.accounts.entities,
        companies: mergedCompanies,
      }),
    );
  }
  if (type === updateReportDay.success) {
    const { companyId, day } = payload;
    return {
      ...state,
      [companyId]: { ...state[companyId], operationReportPeriodDay: day },
    };
  }
  return state;
};

export default companies;
