import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import toCamelCase from 'shared/utils/toCamelCase';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_CONSETS');

export const consents = createActions(names);

const fetchConsents = params => async dispatch => {
  dispatch(consents.request({ params }));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_get_company_consents',
        {
          ...params,
        },
        true,
      ),
    );
    const users = response.data.users.map(user =>
      toCamelCase({
        name: `${user.personnel_id} ${user.firstName} ${user.lastName}`,
        ...user,
      }),
    );
    await dispatch(
      consents.success({ params, users, consents: response.data.consents }),
    );
  } catch (error) {
    errorLogger(error.response);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('consents|||fetchFailure'),
    );

    dispatch(notify({ message }));
    dispatch(consents.failure({ params, error }));
  }
};

export default fetchConsents;
