import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';

const getToken = () => {
  try {
    const token =
      safeSessionStorage.getItem('token') || safeLocalStorage.getItem('token');
    return token;
  } catch (ex) {
    return undefined;
  }
};

export const getSecurityDeviceToken = async () => {
  try {
    const securityDevice = safeLocalStorage.getItem('securityDevice');
    return securityDevice;
  } catch (ex) {
    return undefined;
  }
};

export const getExpires = () => {
  try {
    const expires =
      safeSessionStorage.getItem('expires') ||
      safeLocalStorage.getItem('expires');
    return expires;
  } catch (ex) {
    return undefined;
  }
};

export default getToken;
