import { createAction } from 'redux-actions';
import {
  DocumentFieldValues,
  PurchaseDocumentConfig,
} from 'shared/types/Document';

export const names = {
  edit: 'SUBMIT_EDIT',
};

interface SubmitEditPayload {
  data: DocumentFieldValues;
  submit: PurchaseDocumentConfig;
}

export const submitEdit = createAction<SubmitEditPayload>(names.edit);

export type SubmitEditAction = ReturnType<typeof submitEdit>;
