import { names } from 'shared/actions/kudosConfig';
import { combineReducers, AnyAction } from 'redux';
import keyBy from 'lodash/keyBy';
import makeStatusReducer, { StatusState } from './status';

export interface KudosConfig {
  id: number;
  typeId: number;
  title: string;
  color: string;
  icon: string;
  active: boolean;
  sent: number;
  hidden?: boolean;
  received: number;
  section: string;
  company: number | null;
  kudosGroup: number | null;
  borderColor: string | null;
  description: string | null;
  restriction?: {
    hash: string;
    label: string;
  };
}

type KudosIdsState = number[];

export interface KudosConfigState {
  [id: number]: KudosConfig;
}

const config = (state: KudosConfigState = {}, action: AnyAction) => {
  if (action.type === names.success) {
    return keyBy(action.payload, 'id');
  }
  return state;
};

const ids = (state: KudosIdsState = [], action: AnyAction) => {
  if (action.type === names.success) {
    return action.payload.map((item: KudosConfig) => item.id);
  }
  return state;
};

const keys = (state: KudosIdsState = [], action: AnyAction) => {
  if (action.type === names.success) {
    let group: string | null = null;
    let company: string | null = null;
    action.payload.forEach((item: KudosConfig) => {
      if (item.kudosGroup && item.active && !item.hidden && group === null) {
        group = item.section;
      }
      if (!item.kudosGroup && item.active && company === null) {
        company = item.section;
      }
    });

    return {
      kudosGroup: group,
      companyKudos: company,
    };
  }
  return state;
};

export interface KudosConfigReducerState {
  config: KudosConfigState;
  status: StatusState;
  ids: KudosIdsState;
  keys: {
    kudosGroup: string | null;
    companyKudos: string | null;
  };
}

const kudosConfig = combineReducers({
  config,
  status: makeStatusReducer(names),
  ids,
  keys,
});

export default kudosConfig;
