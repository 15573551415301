import { names } from 'shared/actions/employeeBenefits';
import { combineReducers, AnyAction } from 'redux';
import {
  EmployeeBenefitProduct,
  EmployeeBenefitTotal,
} from 'shared/types/EmployeeBenefits';
import makeStatusReducer, { StatusState } from './status';

export type SearchHistoryItemsState = string[];

export interface SearchHistoryState {
  items: SearchHistoryItemsState;
  status: StatusState;
}

interface EmployeeBenefitsSuccessAction {
  type: string;
  payload: EmployeeBenefitTotal & {
    benefits: EmployeeBenefitProduct[];
  };
}

type EmployeeBenefitWidgetTile = EmployeeBenefitTotal | EmployeeBenefitProduct;

export interface EmployeeBenefitsState {
  items: EmployeeBenefitWidgetTile[];
  status: StatusState;
}

const items = (state: EmployeeBenefitWidgetTile[] = [], action: AnyAction) => {
  if (action.type === names.success) {
    const { payload } = action as EmployeeBenefitsSuccessAction;
    const { benefits, ...total } = payload;
    const data: EmployeeBenefitWidgetTile[] = total.showSummary
      ? [
          {
            ...total,
            total: true,
          },
          ...benefits,
        ]
      : benefits;
    return data;
  }
  return state;
};

const employeeBenefits = combineReducers({
  items,
  status: makeStatusReducer(names),
});

export default employeeBenefits;
