import { names } from 'shared/actions/injectableHtml';

const makeInjectableHtmlReducer = name => (state = [], { type, payload }) => {
  if (type === names[name].success) {
    return payload || [];
  }
  return state;
};

export default makeInjectableHtmlReducer;
