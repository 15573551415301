import { useState } from 'react';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import notify from 'shared/actions/notify';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import axios from 'shared/utils/axios';
import { createAction } from 'redux-actions';
import { BaseEmployee } from 'shared/types/Employee';
import { AppRouting } from 'shared/utils/AppRouting';
import { useTranslation } from 'react-i18next';
import useCurrentCompany from './useCurrentCompany';

interface Params {
  employeeId: number;
  companyId: number;
}

const addReceptionRole = ({ employeeId, companyId }: Params) =>
  axios.post(
    AppRouting.generate('api_partner_add_role', {
      partner_id: companyId,
      employee_id: employeeId,
    }),
  );

const removeReceptionRole = ({ employeeId, companyId }: Params) =>
  axios.delete(
    AppRouting.generate('api_partner_delete_role', {
      partner_id: companyId,
      employee_id: employeeId,
    }),
  );

export const toggleReceptionActionName = 'TOGGLE_EMPLOYEE_RECEPTION_VIEW';
export const toggleReceptionAction = createAction(toggleReceptionActionName);

const toggleReceptionRole = ({
  employeeId,
  companyId,
  enabled,
}: Params & { enabled: boolean }) =>
  enabled
    ? addReceptionRole({ employeeId, companyId })
    : removeReceptionRole({ employeeId, companyId });

interface ConfirmEntryOptions {
  onSuccess?: () => void;
  employee: BaseEmployee;
}

const useConfirmEntry = ({ onSuccess, employee }: ConfirmEntryOptions) => {
  const dispatch = useAppDispatch();
  const [trans] = useTranslation();
  const company = useCurrentCompany();
  const [pending, setPending] = useState(false);

  const handleToggleReception = async () => {
    if (!employee) {
      return;
    }

    const enabled = !employee.hasReceptionView;
    const { employeeId, userId } = employee;
    try {
      setPending(true);
      await toggleReceptionRole({
        employeeId,
        companyId: company.id,
        enabled,
      });

      if (onSuccess) {
        onSuccess();
      }
      dispatch(
        toggleReceptionAction({
          enabled,
          companyId: company.id,
          employeeId,
          userId,
        }),
      );

      const message = trans('employee|||permissions|||changedSuccess');
      dispatch(notify({ message, type: 'success' }));
      setPending(false);
    } catch (ex) {
      setPending(false);
      const message = get(
        ex,
        'response.data.errors[0]',
        trans('employee|||permissions|||changedFailure'),
      );
      dispatch(notify({ message }));
      errorLogger(ex);
    }
  };

  return {
    pending,
    handleToggleReception,
  };
};

export default useConfirmEntry;
