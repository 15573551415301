import ReactGA from 'react-ga4';

import {
  ProductData,
  SearchParams,
  TransactionsData,
  User,
} from 'shared/googleAnalytics/gaEventsTypes';

export const gaSearch = ({
  ga_search_params,
  user_data,
  mobile,
  driver,
}: {
  ga_search_params: SearchParams;
  user_data: User;
  mobile: boolean | undefined;
  driver?: any;
}) => {
  console.log('Google Analytics: gaSearch');
  const data = {
    event: 'search',
    category: 'search',
    action: 'Zdarzenie w momencie wyszukiwana w witrynie.',
    label: 'Użytkownik użył wyszukiwarki produktów',

    mobile: !!mobile,
    user_data,
    ga_search_params,
  };

  try {
    if (mobile && driver) {
      driver().logEvent('search', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaSearch', error);
  }
};

export const gaViewProductsList = ({
  ga_products_data,
  item_list_id,
  item_list_name,
  user_data,
  mobile,
  driver,
}: {
  ga_products_data: ProductData[];
  user_data: User;
  mobile: boolean | undefined;
  item_list_id: string;
  item_list_name: string;
  driver?: any;
}) => {
  console.log('Google Analytics: gaViewProductsList');
  const data = {
    event: 'view_item_list',
    category: 'benefit',
    action: 'Widok listy benefitów',
    label: 'Użytkownik zobaczył listę produktów/benefitów',

    item_list_id,
    item_list_name,

    mobile: !!mobile,
    user_data,
    items: ga_products_data,
  };

  try {
    if (mobile && driver) {
      driver().logEvent('view_item_list', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaViewProductsList', error);
  }
};

export const gaViewProducts = ({
  ga_products_data,
  item_list_id,
  item_list_name,
  user_data,
  mobile,
  driver,
}: {
  ga_products_data: ProductData[];
  user_data: User;
  mobile: boolean | undefined;
  item_list_id: string;
  item_list_name: string;
  driver?: any;
}) => {
  console.log('Google Analytics: gaViewProducts');
  const data = {
    event: 'view_item',
    category: 'benefit',
    action: 'Widok produktów niezwiązanych kategorią',
    label: 'Użytkownik zobaczył grupę produktów z różnych kategorii',

    item_list_id,
    item_list_name,

    mobile: !!mobile,
    user_data,
    items: ga_products_data,
  };

  try {
    if (mobile && driver) {
      driver().logEvent('view_item', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaViewProducts', error);
  }
};

export const gaSelectBenefit = ({
  ga_product_data,
  user_data,
  mobile,
  driver,
}: {
  ga_product_data: ProductData;
  user_data: User;
  mobile: boolean | undefined;
  driver?: any;
}) => {
  console.log('Google Analytics: gaSelectBenefit');
  const { item_category } = ga_product_data;
  const data = {
    event: 'select_item',
    category: 'benefit',
    action: 'Widok oferty benefitu',
    label: 'Użytkownik wybrał produkt/benefit z listy',

    item_list_id: item_category,
    item_list_name: item_category,

    mobile: !!mobile,
    user_data,
    items: [ga_product_data],
  };

  try {
    if (mobile && driver) {
      driver().logEvent('select_item', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaSelectBenefit', error);
  }
};

export const gaBeginCheckoutBenefit = ({
  ga_product_data,
  user_data,
  mobile,
  driver,
}: {
  ga_product_data: ProductData & { item_variant: string | number };
  user_data: User;
  mobile: boolean | undefined;
  driver?: any;
}) => {
  console.log('Google Analytics: gaBeginCheckoutBenefit');
  const data = {
    event: 'begin_checkout',
    category: 'benefit',
    action: 'Widok rozpoczęcia zakupu benefitu',
    label: 'Użytkownik rozpoczął zakup produktu/benefitu',

    mobile: !!mobile,
    user_data,
    items: [ga_product_data],
  };
  try {
    if (mobile && driver) {
      driver().logEvent('begin_checkout', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaBeginCheckoutBenefit', error);
  }
};

export const gaPurchase = ({
  ga_transactions_data,
  ga_product_buy_info,
  driver,
  user_data,
  mobile,
}: {
  ga_transactions_data: TransactionsData;
  ga_product_buy_info: ProductData & {
    item_variant: string | number;
    category_id: number;
    price: number;
    quantity: number;
  };
  driver?: any;
  user_data: User;
  mobile: boolean | undefined;
}) => {
  const { gift, transaction_id, value, createdAt, currency, userId } =
    ga_transactions_data;
  const {
    item_id,
    item_name,
    item_variant,
    item_category,
    item_brand,
    category_name,
    category_id,
    price,
    quantity,
  } = ga_product_buy_info;
  const data = {
    event: 'purchase',
    category: 'purchase',
    action: 'Zakup benefitu/ produktu',
    label: 'Zdarzenie w momencie dokonania zakupu',

    mobile: !!mobile,
    transaction_id,
    value,
    gift,
    createdAt,
    currency,
    userId,
    user_data,

    items: [
      {
        item_id,
        item_name,
        item_variant,
        item_brand,
        item_category,
        category_name,
        category_id,
        currency,
        price,
        quantity,
      },
    ],
  };
  console.log('Google Analytics: gaPurchase');

  try {
    if (mobile && driver) {
      driver().logEvent('purchase', data);
    } else {
      ReactGA.event(data);
    }
  } catch (error) {
    console.error('Google Analytics: gaPurchase: ', error);
  }
};
