import { names } from 'shared/actions/searchHistory';
import uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import makeStatusReducer, { StatusState } from './status';

export type SearchHistoryItemsState = string[];

export interface SearchHistoryState {
  items: SearchHistoryItemsState,
  status: StatusState,
}

interface HistoryAction {
  type: string;
  payload: string;
}

const items = (state: SearchHistoryItemsState = [], action: HistoryAction) => {
  if (action.type === names.success) {
    return action.payload;
  }
  if (action.type === names.update) {
    return uniq([action.payload, ...state]).slice(0, 100);
  }
  return state;
};

const searchHistory = combineReducers({
  items,
  status: makeStatusReducer(names),
});

export default searchHistory;
