import { createAction } from 'redux-actions';
import { createActions, asyncNames } from 'shared/actions/utils';
import IdDictionary from 'shared/types/IdDictionary';
import { Article, PublicationStatus } from 'shared/types/news/News';
import { MetaState } from 'shared/types/reducers/MetaState';

export const newsListActionNames = asyncNames('NEWS_LIST') as {
  success: 'NEWS_LIST_SUCCESS';
  failure: 'NEWS_LIST_FAILURE';
  request: 'NEWS_LIST_REQUEST';
  cancel: 'NEWS_LIST_CANCEL';
};

export const fetchNewsActionNames = asyncNames('FETCH_NEWS') as {
  success: 'FETCH_NEWS_SUCCESS';
  failure: 'FETCH_NEWS_FAILURE';
  request: 'FETCH_NEWS_REQUEST';
  cancel: 'FETCH_NEWS_CANCEL';
};

export const newsListAction = createActions(newsListActionNames);

export const fetchNewsActions = createActions(fetchNewsActionNames);

export const DELETE_ARTICLE = 'DELETE_ARTICLE';

export const deleteArticleAction = createAction(DELETE_ARTICLE);

export interface NewsListParams {
  publication?: PublicationStatus;
  all?: boolean;
  categoryId?: number;
  companyId: number;
  companyFilter?: boolean;
  uncategorized?: boolean;
  global?: boolean;
  promo?: boolean;
  filter?: string;
}

export interface NewsListSuccessResponse extends MetaState {
  data: number[];
  entities: {
    articles: IdDictionary<Article>;
  };
}

export interface NewsListActionPayload {
  params: NewsListParams;
}

export interface NewsListSuccessActionPayload extends NewsListActionPayload {
  response: NewsListSuccessResponse;
}

export interface NewsListSuccessAction {
  type: typeof newsListActionNames.success;
  payload: NewsListSuccessActionPayload;
}

export interface NewsListAction {
  type: string;
  payload: NewsListSuccessActionPayload;
}

export interface FetchNewsSuccessActionPayload {
  news: Article;
  id: number;
}

export interface FetchNewsSuccessAction {
  type: 'FETCH_NEWS_SUCCESS';
  payload: FetchNewsSuccessActionPayload;
}

export interface DeleteArticleAction {
  type: typeof DELETE_ARTICLE;
  payload: {
    articleId: number;
  };
}
