import axios from 'axios';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { asyncNames, createActions } from 'shared/actions/utils';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import {
  Feedback,
  FeedbackIntentionType,
  FeedbackListFilters,
  FeedbackListResponse,
  FeedbackResponse,
  ManagerFeedbackSummary,
} from './FeedbackType';

export const fetchListNames = asyncNames('FETCH_FEEDBACK_LIST');

export const fetchStatsNames = asyncNames('FETCH_FEEDBACK_STATS');

const setIntentions = 'SET_FEEDBACK_INTENTIONS';

const setFeedback = 'SET_FEEDBACK';

const deleteFeedback = 'DELETE_FEEDBACK';
const updateFeedback = 'UPDATE_FEEDBACK';

export const feedbackNames = {
  list: fetchListNames,
  stats: fetchStatsNames,
  setFeedback,
  setIntentions,
  delete: deleteFeedback,
  update: updateFeedback,
};

const fetchListActions = createActions(fetchListNames);

const fetchStatsActions = createActions(fetchStatsNames);

const fetchFeedbackActions = createActions(fetchListNames);

export const setIntentionsAction = createAction(setIntentions);

export const setFeedbackAction = createAction(setFeedback);

export const deleteFeedbackAction = createAction(deleteFeedback);
export const updateFeedbackAction = createAction(updateFeedback);

export const feedbackActions = {
  list: fetchListActions,
  delete: deleteFeedbackAction,
  update: updateFeedbackAction,
  set: setFeedbackAction,
  stats: fetchStatsActions,
  feedback: fetchFeedbackActions,
  setIntentionsAction,
};

export interface FetchFeedbackListAction {
  type: string;
  payload: {
    filters: FeedbackListFilters;
    page: number;
    companyId?: number;
  };
}

export interface FetchFeedbackStatsAction {
  type: string;
  payload: {
    intention: string;
    employeeId: number;
    from?: string;
    to?: string;
  };
}

export interface FetchFeedbackStatsSuccess extends FetchFeedbackStatsAction {
  type: string;
  payload: {
    response: ManagerFeedbackSummary;
    intention: string;
    employeeId: number;
    from?: string;
    to?: string;
  };
}

export interface SetIntentionsAction {
  type: typeof setIntentions;
  payload: {
    data: FeedbackIntentionType[];
  };
}

export interface FetchFeedbackListSuccess extends FetchFeedbackListAction {
  type: typeof fetchListNames['success'];
  payload: {
    response: FeedbackListResponse;
    filters: FeedbackListFilters;
    page: number;
    companyId?: number;
  };
}

export interface SetFeedback {
  type: typeof setFeedback;
  payload: {
    response: FeedbackResponse;
  };
}

export interface DeleteFeedback {
  type: typeof deleteFeedback;
  payload: {
    feedbackUid: string;
  };
}

export interface UpdateFeedback {
  type: typeof deleteFeedback;
  payload: {
    feedback: Feedback;
  };
}

export const fetchFeedbackStats = (params: {
  intention: string;
  employeeId: number;
  from?: string;
  to?: string;
}) => async (dispatch: Dispatch<any>) => {
  const { intention, from, to } = params;
  const opts: RoutingParams = {
    intention,
  };
  if (from) {
    opts.from = from;
  }
  if (to) {
    opts.to = to;
  }
  dispatch(fetchStatsActions.request({}));
  try {
    const res = await axios.get(
      AppRouting.generate('api_feedback_intention_stats', { ...params }),
    );
    dispatch(fetchStatsActions.success({ ...params, response: res.data }));
  } catch (ex) {
    if (axios.isCancel(ex)) {
      dispatch(fetchStatsActions.cancel({ ...params }));

      return;
    }
    dispatch(fetchStatsActions.failure({ ...params }));
  }
};
