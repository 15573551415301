import { combineReducers } from 'redux';
import { names } from 'shared/actions/products/fetch';
import { names as productNames } from 'shared/actions/products/actions';
import makeCategoryKey from 'shared/utils/makeCategoryKey';
import makeStatusReducer from '../status';


const list = (state = [], { type, payload }) => {
  if (type === names.success) {
    const { result } = payload.data;
    if (payload.params.page > 1) {
      return [...state, ...result.data];
    }
    return result.data;
  }
  return state;
};

const meta = (state = {}, { type, payload }) => {
  if (type === names.success) {
    const { entities, data, ...stats } = payload.data.result;
    return stats;
  }
  return state;
};

const params = (state = {}, { type, payload }) => {
  if (type === names.success) {
    return payload.params;
  }
  return state;
};

const item = combineReducers({
  status: makeStatusReducer(names),
  list,
  meta,
  params,
});

const items = (state = {}, action) => {
  const actions = Object.values(names);
  if (action.type === productNames.clear) {
    return {};
  }
  if (actions.includes(action.type)) {
    const key = makeCategoryKey(action.payload.params);
    const prev = !action.payload.clear ? state[key] : undefined;
    return { ...state, [key]: item(prev, action) };
  }
  return state;
};

export default items;
