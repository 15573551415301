import createAction from 'redux-actions/lib/createAction';

export const names = {
  toggleProductList: 'TOGGLE_PRODUCT_LIST',
  toggleOffline: 'TOGGLE_OFFLINE',
  setGuide: 'SET_GUIDE',
  closeGuide: 'CLOSE_GUIDE',
  forceGuide: 'FORCE_GUIDE',
  blurOverlay: 'TOGGLE_BLUR_OVERLAY',
  infoBoxHide: 'INFO_BOX_HIDE',
  fixedNav: 'NAV_FIXED_TOGGLE',
  setBodyOverflow: 'SET_BODY_OVERFLOW',
  setNavigation: 'SET_NAVIGATION',
  setHeader: 'SET_HEADER',
  setIdleWarning: 'SET_IDLE_WARNING',
};

export const toggleProductList = createAction(names.toggleProductList);

export const toggleOffline = createAction(names.toggleOffline);
export const setGuide = createAction(names.setGuide);
export const forceGuide = createAction(names.forceGuide);
export const blurOverlay = createAction(names.blurOverlay);
export const infoBoxHide = createAction(names.infoBoxHide);
export const bodyOverflow = createAction(names.setBodyOverflow);
export const setIdleWarning = createAction(names.setIdleWarning);

export const setBodyOverflow = value => (dispatch, getState) => {
  const state = getState();
  if (state.ui.bodyOverflow === value) {
    return;
  }
  dispatch(bodyOverflow(value));
};

export const toggleFixedNav = createAction(names.fixedNav);
export const setNavigation = createAction(names.setNavigation);
export const setHeader = createAction(names.setHeader);

export const closeGuide = createAction(names.closeGuide);
