import { asyncNames, createActions, getFetchAction } from './utils';
// import { normalize } from 'normalizr';

export const names = {
  update: 'UPDATE_ACCOUNTS',
  ...asyncNames('FETCH_ACCOUNTS'),
};

export const actions = createActions(names);

const fetchAccounts = getFetchAction(
  actions,
  'api_get_logged_user_accounts_data',
);

export default fetchAccounts;
