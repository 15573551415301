import axios from 'shared/utils/axios';
import defaultGetToken, {
  getExpires as defaultGetExpires,
} from 'shared/utils/user/getToken';

import parse from 'date-fns/parseISO';
import subMinutes from 'date-fns/subMinutes';
import isBefore from 'date-fns/isBefore';
import errorLogger from '../errorLogger';

let refreshInterval;
let initialized = false;
// let refreshing = false;

const shouldRefresh = (expires, config = {}) => {
  if (!expires) {
    console.warn('No expires for token - refreshing token');
    return true;
  }
  const expiresDate = parse(expires);
  const maxDate = subMinutes(expiresDate, 15);
  const now = new Date();
  if (config.debug) {
    console.log('[TokenRefresh] expires', { now, maxDate, expiresDate });
  }
  // console.log({ expiresDate, maxDate }, expiresDate.isBefore(maxDate));
  return isBefore(maxDate, now);
};

export const refreshToken = (
  persist,
  getExpires = defaultGetExpires,
  getToken = defaultGetToken,
  config = {},
) => async () => {
  const expires = await getExpires();
  const refresh = await shouldRefresh(expires, config);
  const { debug } = config;
  if (debug) {
    console.log('[TokenRefresh] shouldRefresh', refresh);
  }
  if (!refresh) {
    // console.log('[TokenRefresh] not refreshing');
    return;
  }
  const token = await getToken();
  if (debug) {
    console.log('[TokenRefresh] refreshing', token);
  }
  if (!token || !initialized) {
    return;
  }
  const url = AppRouting.generate('api_token_exchange');
  // console.log('[TokenRefresh] refreshing');
  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'X-Viamobile-Token': token,
        },
      },
    );
    if (debug && response) {
      console.log('[TokenRefresh] response', response);
    }
    persist(response.data.token, response.data.expired_at);
  } catch (ex) {
    errorLogger(ex);
    if (config.onRefreshError) {
      config.onRefreshError(ex);
    }
  }
};

export const cancelRefreshToken = () => {
  initialized = false;
  clearInterval(refreshInterval);
};

export const initRefreshToken = async (
  persist,
  getExpires,
  getToken,
  config = {},
) => {
  if (initialized) {
    return;
  }
  initialized = true;
  const tokenRefreshFn = refreshToken(persist, getExpires, getToken, config);
  // console.log('[TokenRefresh] init');
  await tokenRefreshFn();
  refreshInterval = setInterval(tokenRefreshFn, 1000 * 30);
};
