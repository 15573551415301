import { names } from 'shared/actions/locale';
import getAllUrlParams from 'shared/utils/queryString';
import { getPreferredLang, langs } from 'shared/utils/locale';
import { safeLocalStorage } from 'shared/utils/safeStorage';
import Locales from 'shared/types/Locales';
import { Action } from 'redux-actions';

const getInitialLocale = () => {
  try {
    const urlParams = getAllUrlParams(window.location.search);
    if (urlParams.lang && langs.find(lang => lang === urlParams.lang)) {
      return urlParams.lang;
    }
    const prefLang = getPreferredLang(langs[0]);

    return safeLocalStorage.getItem('locale')?.replace(/"/g, '') || prefLang;
  } catch (ex) {
    return langs[0];
  }
};

const locale = (state: Locales, action: Action<any>): Locales => {
  if (!state) {
    return getInitialLocale() as Locales;
  }
  if (action.type === names.set) {
    return action.payload as Locales;
  }
  return state;
};

export default locale;
