import {
  createAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import axios from 'axios';
import { combineReducers } from 'redux';
import {
  PreliminariesResponse,
  PreliminaryResponse,
} from 'shared/types/Preliminary';
import { PreliminaryFilters } from './usePreliminariesFilters';
import { Preliminary } from '../../types/Preliminary';
import createAsyncThunkStatusSlice from '../../utils/redux/createAsyncThunkStatusSlice';

interface FetchPreliminariesParams extends PreliminaryFilters {
  companyId: number;
  refresh?: boolean;
}

interface FetchPreliminaryParams {
  preliminaryId: string;
  refresh?: boolean;
}

interface PreliminaryManagerParams {
  preliminaryId: string;
  employeeId: number;
}

export const addPreliminaryOwner = createAsyncThunk(
  'preliminary/addOwner',
  async ({ preliminaryId, employeeId }: PreliminaryManagerParams) => {
    const opts: RoutingParams = {
      preliminary: preliminaryId,
      employee: employeeId,
    };

    const response = await axios.put<PreliminaryResponse>(
      AppRouting.generate('api_preliminary_manager_add', opts),
    );
    return response.data;
  },
);

export const patchPreliminary = createAction<PreliminaryResponse>(
  'preliminary/patch',
);

export const getPreliminariesKey = ({
  companyId,
  archived,
}: FetchPreliminariesParams) => {
  const params: (string | number)[] = [`companyId:${companyId}`];
  if (archived) {
    params.push('archived');
  }

  return params.join('|');
};

export const fetchPreliminaries = createAsyncThunk(
  'preliminaries/fetch',
  async (
    { companyId, archived }: FetchPreliminariesParams,
    { rejectWithValue },
  ) => {
    const opts: RoutingParams = {
      company: companyId,
    };

    opts.archived = archived ? 1 : 0;

    try {
      const response = await axios.get<PreliminariesResponse>(
        AppRouting.generate('api_preliminaries_get', opts),
      );
      return response.data;
    } catch (ex) {
      if (!ex.response) {
        throw ex;
      }
      return rejectWithValue(ex);
    }
  },
);

interface FetchPreParams {
  preliminaryId: string;
  from?: string;
  to?: string;
  refresh?: boolean;
}

export const fetchPreliminary = createAsyncThunk(
  'preliminary/fetch',
  async ({ preliminaryId, from, to }: FetchPreParams, { rejectWithValue }) => {
    const opts: RoutingParams = {
      preliminary: preliminaryId,
      from,
      to,
    };
    try {
      const response = await axios.get<PreliminaryResponse>(
        AppRouting.generate('api_preliminary_get', opts),
      );
      return response.data;
    } catch (ex) {
      if (!ex.response) {
        throw ex;
      }
      return rejectWithValue(ex);
    }
  },
);

const initialPreliminariesState: Record<string, Preliminary> = {};

const preliminariesItemsSlice = createSlice({
  name: 'listItems',
  reducers: {},
  initialState: initialPreliminariesState,
  extraReducers: builder => {
    builder.addCase(fetchPreliminaries.fulfilled, (state, action) => {
      return { ...state, ...action.payload.entities.preliminaries };
    });
    builder.addCase(patchPreliminary, (state, action) => {
      const newState = { ...state };

      if (action.payload.data) {
        const { data, entities } = action.payload;
        const { preliminaryId } = data;
        newState[preliminaryId] = {
          ...state[preliminaryId],
          ...data,
          ...entities,
        };
      }

      if (action.payload.preliminary) {
        const { preliminaryId } = action.payload.preliminary;
        newState[preliminaryId] = {
          ...state[preliminaryId],
          ...action.payload.preliminary,
        };
      }

      return newState;
    });
    builder.addMatcher(
      isAnyOf(fetchPreliminary.fulfilled, addPreliminaryOwner.fulfilled),
      (state, action) => {
        const { data, entities } = action.payload;
        const newData = { ...data, ...entities };
        const newState = { ...state };
        newState[data.preliminaryId] = newData;
        return newState;
      },
    );
  },
});

const preliminariesDetailsSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState: initialPreliminariesState,
  extraReducers: builder => {
    builder.addCase(patchPreliminary, (state, action) => {
      const newState = { ...state };

      if (action.payload.data) {
        const { data, entities } = action.payload;
        const { preliminaryId } = data;
        newState[preliminaryId] = {
          ...state[preliminaryId],
          ...data,
          ...entities,
        };
      }

      if (action.payload.preliminary) {
        const { preliminary } = action.payload;
        const { preliminaryId } = preliminary;
        newState[preliminaryId] = {
          ...state[preliminaryId],
          ...action.payload.preliminary,
        };
      }
      return newState;
    });
    builder.addMatcher(
      isAnyOf(fetchPreliminary.fulfilled, addPreliminaryOwner.fulfilled),
      (state, action) => {
        const { data, entities } = action.payload;
        const newData = { ...data, ...entities };
        const newState = { ...state };
        newState[data.preliminaryId] = newData;
        return newState;
      },
    );
  },
});

const preliminariesListSlice = createSlice({
  name: 'list',
  reducers: {},
  initialState: {} as Record<string, string[]>,
  extraReducers: builder => {
    builder.addCase(fetchPreliminaries.fulfilled, (state, action) => {
      const key = getPreliminariesKey(action.meta.arg);
      const newState = { ...state };
      newState[key] = action.payload.data;
      return newState;
    });
  },
});

const preliminariesStatus = createAsyncThunkStatusSlice(fetchPreliminaries, {
  getKey: getPreliminariesKey,
});

const preliminaryStatus = createAsyncThunkStatusSlice(fetchPreliminary, {
  getKey: (payload: FetchPreliminaryParams) => payload.preliminaryId,
});

const preliminaries = combineReducers({
  listItems: preliminariesItemsSlice.reducer,
  list: preliminariesListSlice.reducer,
  itemStatus: preliminaryStatus.reducer,
  items: preliminariesDetailsSlice.reducer,
  status: preliminariesStatus.reducer,
});

export default preliminaries;
