import { combineReducers } from 'redux';
import items from './items';
import lists from './lists';

const purchases = combineReducers({
  items,
  lists,
});

export default purchases;
