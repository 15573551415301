import { names } from 'shared/actions/fetchGrades';

const items = (state = null, { type, payload }) => {
  if (type === names.success) {
    return payload.grades;
  }

  return state;
};

export default items;
