/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosResponse } from 'axios';
import debounce from 'lodash/debounce';
import { safeLocalStorage } from 'shared/utils/safeStorage';
import LRU from 'lru-cache';
import parse from 'url-parse';

interface MediaObject {
  url: string;
  preview: string;
  dims: [number, number];
  size: number;
}

export interface TenorGif {
  id: string;
  title?: string;
  tags: string[];
  url: string;
  media: [
    {
      tinygif: MediaObject;
      gif: MediaObject;
      mp4: MediaObject;
    },
  ];
}

export type TenorSearchResults = {
  results: TenorGif[];
  next?: string;
};

let cache: LRU<string, AxiosResponse<any>> | undefined;

const axiosInstance = axios.create({
  withCredentials: false,
});

const { CancelToken } = axios;
let source = CancelToken.source();

// FIXME: move this to env
const TENOR_KEY = '4VESDI5TDTD1';

const base = 'https://api.tenor.com/v1';
const endpoints = {
  anonId: '/anonid',
  gifs: '/gifs',
  search: '/search',
  suggestions: '/search_suggestions',
  registershare: '/registershare',
  autocomplete: '/autocomplete',
  trending: '/trending',
};

type CancelTokenType = typeof source['token'];

const cleanupObj = <T extends { [key: string]: any }>(src: T) => {
  const obj = { ...src };
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

const getFromTenor = async (
  part: string,
  params = {},
  cancelToken?: CancelTokenType,
) => {
  if (!cache) {
    cache = new LRU(100);
  }
  const url = parse(`${base}${part}`);
  url.set(
    'query',
    cleanupObj({
      ...params,
      key: TENOR_KEY,
    }),
  );
  const urlString = url.toString();
  const cached = cache.get(urlString);

  if (cached) {
    return cached;
  }

  const response = await axiosInstance.get(urlString, {
    cancelToken,
  });
  cache.set(urlString, response);
  return response;
};

const getAnonId = async () => {
  const uid = await safeLocalStorage.getItem('tenorAnonId');
  if (!uid) {
    const response = await getFromTenor(endpoints.anonId);
    const { anon_id } = response.data;
    safeLocalStorage.setItem('tenorAnonId', anon_id);
    return anon_id;
  }

  return uid;
};

let pendingIds: string[] = [];

type SuccessCb = (results: TenorGif[]) => void;

const getGifs = async (ids: string[], onSuccess?: SuccessCb) => {
  pendingIds = [];
  const response = await getFromTenor(endpoints.gifs, {
    media_filter: 'minimal',
    ids: ids.join(','),
  });
  const { results } = response.data;
  if (onSuccess) {
    onSuccess(results);
  }
  return results as TenorGif[];
};

const debouncedGetGifs = debounce(getGifs, 50);

const accumulateIdsGetGifs = async (ids: string[], onSuccess?: SuccessCb) => {
  pendingIds = pendingIds.concat(ids);
  return debouncedGetGifs([...pendingIds], onSuccess);
};

const search = async (q: string, params = {}) => {
  const anon_id = await getAnonId();
  source.cancel();
  source = CancelToken.source();
  const response = await getFromTenor(
    endpoints.search,
    {
      media_filter: 'minimal',
      q,
      anon_id,
      contentfilter: 'low',
      ...params,
    },
    source.token,
  );
  return response.data as TenorSearchResults;
};

const trending = async (params = {}) => {
  const anon_id = await getAnonId();
  source.cancel();
  source = CancelToken.source();
  const response = await getFromTenor(
    endpoints.trending,
    {
      media_filter: 'minimal',
      anon_id,
      contentfilter: 'low',
      ...params,
    },
    source.token,
  );
  return response.data as TenorSearchResults;
};

const suggestions = async (q: string, params = {}) => {
  const anon_id = await getAnonId();
  const response = await getFromTenor(endpoints.suggestions, {
    q,
    anon_id,
    ...params,
  });
  const { results } = response.data;
  return results;
};
const autocomplete = async (q: string, params = {}) => {
  const anon_id = await getAnonId();
  const response = await getFromTenor(endpoints.autocomplete, {
    q,
    anon_id,
    ...params,
  });
  const { results } = response.data;
  return results;
};

const registerShare = async (id: string, params = {}) => {
  const anon_id = await getAnonId();
  await getFromTenor(endpoints.registershare, {
    id,
    anon_id,
    ...params,
  });
};

const tenor = {
  search,
  trending,
  autocomplete,
  suggestions,
  registerShare,
  getGifsInstant: getGifs,
  getGifs: accumulateIdsGetGifs,
  getAnonId,
};

export default tenor;
