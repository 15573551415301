import { names } from 'shared/actions/icon';
import moment from 'shared/utils/moment';

const initial = {};
// Change this to drop all cached icons
const version = 'v1.1';
// max cache age
const maxAge = 1000 * 60 * 60 * 24 * 30; // 30 days

const getCacheData = () => ({
  timestamp: Date.now(),
  version,
});

const filterState = (state, ver) => {
  const newState = {};
  const date = Date.now();
  Object.entries(state).forEach(([key, data]) => {
    // drop entry if version changes
    if (data.version !== ver) {
      return;
    }
    const dateDiff = date - moment(data.timestamp).toDate();
    // drop entries older than 30 days
    if (dateDiff > maxAge) {
      return;
    }
    newState[key] = data;
  });
  return newState;
};

const icons = (state = initial, { type, payload }) => {
  // clears all invalid entries
  if (type === names.clear) {
    return filterState(state, version);
  }
  if (type === names.success) {
    return {
      ...state,
      [payload.url]: {
        data: payload.data,
        ...getCacheData(),
      },
    };
  }
  return state;
};

export default icons;
