import { createAction } from 'redux-actions';
import { Group } from 'shared/types/Group';
import IdDictionary from 'shared/types/IdDictionary';
import { MetaState } from 'shared/types/reducers/MetaState';

export const setChallengeGroupsName = 'SET_CHALLENGE_GROUPS';

export const updateChallengeGroupsName = 'UPDATE_CHALLENGE_GROUPS';

export interface AddGroupsPayload {
  data: number[];
  meta: MetaState;
  challengeId: number;
  entities: {
    groups: IdDictionary<Group>;
  };
}

export interface UpdateChallengeGroupsPayload {
  update: {
    ids: number[];
  };
  challengeId: number;
}

export const setChallengeGroupsAction = createAction<AddGroupsPayload>(
  setChallengeGroupsName,
);

export const updateChallengeGroups = createAction<UpdateChallengeGroupsPayload>(
  updateChallengeGroupsName,
);
