import { names } from 'shared/actions/dashboard/tiles';

const items = (state = null, { type, payload }) => {
  if (type === names.success) {
    return payload.tiles;
  }
  return state;
};

export default items;
