import { createAction } from 'redux-actions';
import { createActions, asyncNames } from 'shared/actions/utils';
import { ChallengeOrganizersResponse } from './Challenge';

export const fetchOrganizersActionsNames = asyncNames('FETCH_ORGANIZERS');

export const fetchOrganizersActions = createActions(
  fetchOrganizersActionsNames,
);

export const deleteOrganizerActionName = 'DELETE_ORGANIZER';

export const deleteOrganizerAction = createAction(deleteOrganizerActionName);

export const addOrganizerActionName = 'ADD_ORGANIZER';

export const addOrganizerAction = createAction(addOrganizerActionName);

export interface FetchOrganizersSuccessAction {
  type: typeof fetchOrganizersActionsNames.success;
  payload: ChallengeOrganizersResponse & { page: number; challengeId: number };
}

export interface DeleteOrganizerSuccessAction {
  type: typeof deleteOrganizerActionName;
  payload: {
    challengeId: number;
    employeeId: number;
  };
}

export interface AddOrganizerSuccessAction {
  type: typeof addOrganizerActionName;
  payload: {
    challengeId: number;
    employeeId: number;
  };
}
