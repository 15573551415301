/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Action, combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { MetaState } from 'shared/types/reducers/MetaState';
import { makeAltStatusReducer, StatusState } from 'shared/reducers/status';
import {
  Challenge,
  ChallengeScore,
  ChallengesCounters,
  ChallengesCompanyCounters,
  ChallengesFilters,
  ChallengeStats,
  ChallengeStatus,
  ChallengeParticipant,
} from './Challenge';
import {
  challengesHomeActionNames,
  ChallengesHomeSuccessAction,
  fetchChallengesActionsNames,
  FetchChallengesSuccessAction,
  SetChallengeAction,
  setChallengeName,
  SetChallengesCountersAction,
  setChallengesCountersName,
  SetChallengesCompanyCountersAction,
  setChallengesCompanyCountersName,
  JoinChallengeAction,
  joinChallengeName,
  LeaveChallengeAction,
  leaveChallengeName,
  SetChallengeStatsAction,
  setChallengeStatsName,
  setScoresActionName,
  setChallengesScoresAction,
  setChallengesParticipantsAction,
  setParticipantsActionName,
  updateChallengeActionName,
  UpdateChallengeSuccessAction,
  createChallengeActionName,
  CreateChallengeSuccessAction,
  deleteChallengeActionName,
  DeleteChallengeSuccessAction,
} from './challengesActions';
import activities from './activitiesStore';
import trainings from './trainingsStore';
import organizers from './organizersStore';
import challengeGroups from './challengeGroupsStore';

export interface ChallengesListState {
  list: number[];
  meta: MetaState | null;
  status: StatusState;
}

interface ListState {
  [key: string]: ChallengesListState;
}

const statusReducer = makeAltStatusReducer(
  challengesHomeActionNames,
  fetchChallengesActionsNames,
);

export const getChallengesKey = ({
  status,
  filter,
  joined,
  organized,
  companyId,
  isCompanyChallenge,
}: ChallengesFilters & {
  companyId?: number;
  isCompanyChallenge?: boolean;
}) => {
  const parts: string[] = [status || 'all'];
  if (isCompanyChallenge) {
    parts.push('scope:company');
  }
  if (filter) {
    parts.push('filtered');
  }
  if (joined) {
    parts.push('joined');
  }
  if (organized) {
    parts.push('organized');
  }
  if (companyId) {
    parts.push(`companyId:${companyId}`);
  }

  return parts.join('|');
};

export const getChallengeUserKey = ({
  challengeId,
  employeeId,
}: {
  employeeId: number;
  challengeId: number;
}) => `${challengeId}|${employeeId}`;

const getHomeMeta = (count: number) => ({
  count_per_current_page: count > 3 ? 3 : count,
  max_per_page: 3,
  page: 1,
  total_count: count,
  total_page_count: Math.ceil(count / 3),
  unfilteredCount: 3,
});

const metaReducer = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === challengesHomeActionNames.success) {
    const { payload } = action as ChallengesHomeSuccessAction;
    const counterKey = `${payload.filters.status || 'finished'}Count` as
      | 'finishedCount'
      | 'activeCount'
      | 'upcomingCount';
    const counter = payload.data[counterKey];
    return getHomeMeta(counter);
  }

  if (action.type === fetchChallengesActionsNames.success) {
    const { payload } = action as FetchChallengesSuccessAction;
    const { entities, data, ...meta } = payload.data;
    return meta;
  }

  return state;
};

const list = (state: number[] = [], action: Action<any>) => {
  if (action.type === challengesHomeActionNames.success) {
    const { payload } = action as ChallengesHomeSuccessAction;
    const { status } = payload.filters;
    if (!status) {
      return state;
    }

    return payload.data[status];
  }

  if (action.type === fetchChallengesActionsNames.success) {
    const { payload } = action as FetchChallengesSuccessAction;

    if (payload.data.page === 1) {
      return payload.data.data;
    }
    return [...state, ...payload.data.data];
  }

  if (action.type === deleteChallengeActionName) {
    const { payload } = action as DeleteChallengeSuccessAction;
    return state.filter((id) => id !== payload.challengeId);
  }

  return state;
};

interface ChallengeAction {
  type: string;
  payload: {
    filters: ChallengesFilters;
    companyId: number;
  };
}

const actionNamesArr = [
  ...Object.values(fetchChallengesActionsNames),
  ...Object.values(challengesHomeActionNames),
];

function assertChallengeAction(
  input: Action<any> | ChallengeAction,
): asserts input is ChallengeAction {
  if (actionNamesArr.includes(input.type)) {
    return;
  }
  throw new Error('Not a ChallengeAction');
}

const listState = combineReducers({
  meta: metaReducer,
  status: statusReducer,
  list,
});

const setHomeActionStatus = (
  action: {
    type: string;
    payload: {
      filters: ChallengesFilters;
    };
  },
  status: ChallengeStatus,
) =>
  produce(action, (draft) => {
    if (!draft.payload.filters) {
      draft.payload.filters = {};
    }
    draft.payload.filters.status = status;
  });

const lists = (state: ListState = {}, action: Action<any>) => {
  if (action.type === deleteChallengeActionName) {
    return produce(state, (draft) => {
      Object.keys(state).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = listState(state[key], action);
      });
    });
  }

  try {
    assertChallengeAction(action);
  } catch (ex) {
    return state;
  }
  if (
    action.type === challengesHomeActionNames.success ||
    action.type === challengesHomeActionNames.request ||
    action.type === challengesHomeActionNames.failure ||
    action.type === challengesHomeActionNames.cancel
  ) {
    const { companyId, filters } = action.payload;
    const activeKey = getChallengesKey({
      status: 'active',
      companyId,
      ...filters,
    });
    const finishedKey = getChallengesKey({
      status: 'finished',
      companyId,
      ...filters,
    });
    const upcomingKey = getChallengesKey({
      status: 'upcoming',
      companyId,
      ...filters,
    });

    return {
      ...state,
      [activeKey]: listState(
        state[activeKey],
        setHomeActionStatus(action as ChallengeAction, 'active'),
      ),
      [finishedKey]: listState(
        state[finishedKey],
        setHomeActionStatus(action as ChallengeAction, 'finished'),
      ),
      [upcomingKey]: listState(
        state[upcomingKey],
        setHomeActionStatus(action as ChallengeAction, 'upcoming'),
      ),
    };
  }

  if (
    action.type === fetchChallengesActionsNames.success ||
    action.type === fetchChallengesActionsNames.request ||
    action.type === fetchChallengesActionsNames.failure ||
    action.type === fetchChallengesActionsNames.cancel
  ) {
    const { payload } = action;
    const key = getChallengesKey({
      ...payload.filters,
      companyId: payload.companyId,
    });
    return {
      ...state,
      [key]: listState(state[key], action),
    };
  }

  return state;
};

export interface ItemsState {
  [id: number]: Challenge;
}

const items = (state: ItemsState = {}, action: Action<any>) => {
  if (
    action.type === challengesHomeActionNames.success ||
    action.type === fetchChallengesActionsNames.success
  ) {
    const { payload } = action as
      | ChallengesHomeSuccessAction
      | FetchChallengesSuccessAction;
    return {
      ...state,
      ...payload.data.entities.challenges,
    };
  }
  if (action.type === setChallengeName) {
    const { payload } = action as SetChallengeAction;
    return {
      ...state,
      [payload.challengeId]: payload,
    };
  }

  if (action.type === joinChallengeName) {
    const { payload } = action as JoinChallengeAction;

    return produce(state, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[payload.score.challengeId].joinedEmployeeId =
        payload.score.employeeId;
    });
  }

  if (action.type === leaveChallengeName) {
    const { payload } = action as LeaveChallengeAction;

    return produce(state, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[payload.challengeId].joinedEmployeeId = null;
    });
  }

  if (action.type === updateChallengeActionName) {
    const { payload } = action as UpdateChallengeSuccessAction;
    const challenge = state[payload.challengeId];
    if (!challenge) {
      return state;
    }
    return {
      ...state,
      [challenge.challengeId]: { ...challenge, ...payload.update } as Challenge,
    };
  }

  if (action.type === createChallengeActionName) {
    const { payload } = action as CreateChallengeSuccessAction;

    return {
      ...state,
      [payload.challengeId]: payload,
    };
  }

  return state;
};

interface ChallengesCountersState {
  data: ChallengesCounters | null;
  companyId: number | null;
}

const counters = (
  state: ChallengesCountersState = { data: null, companyId: null },
  action: Action<any>,
) => {
  if (action.type === setChallengesCountersName) {
    const { payload } = action as SetChallengesCountersAction;
    return payload;
  }
  return state;
};

const companyCounters = (
  state: ChallengesCompanyCounters | null = null,
  action: Action<any>,
) => {
  if (action.type === setChallengesCompanyCountersName) {
    const { payload } = action as SetChallengesCompanyCountersAction;
    return payload;
  }
  return state;
};

interface ScoreState {
  [key: string]: ChallengeScore;
}

const scores = (state: ScoreState = {}, action: Action<any>) => {
  if (
    action.type === joinChallengeName ||
    action.type === setChallengeStatsName
  ) {
    const { payload } = action as JoinChallengeAction | SetChallengeStatsAction;
    if (!payload.score) {
      return state;
    }
    const key = getChallengeUserKey(payload.score);

    return produce(state, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[key] = payload.score;
    });
  }

  if (action.type === leaveChallengeName) {
    const { payload } = action as LeaveChallengeAction;

    const key = getChallengeUserKey(payload);

    return omit(state, key);
  }

  if (
    action.type === setScoresActionName ||
    action.type === setParticipantsActionName
  ) {
    const { payload } = action as setChallengesScoresAction;
    const keyed = keyBy(payload.entities.scores, getChallengeUserKey);
    return {
      ...state,
      ...keyed,
    };
  }

  return state;
};

interface ParticipantsState {
  [key: string]: ChallengeParticipant;
}

export const getChallengeParticipantKey = (
  employeeId: number,
  challengeId: number,
) => `${employeeId}|${challengeId}`;

const participants = (
  state: ParticipantsState = {},
  action: Action<any>,
): ParticipantsState => {
  if (action.type === setParticipantsActionName) {
    const { payload } = action as setChallengesParticipantsAction;

    return {
      ...state,
      ...payload.entities.participants,
    };
  }

  return state;
};

interface StatsState {
  [challengeId: string]: ChallengeStats;
}

const statsReducer = (state: StatsState = {}, action: Action<any>) => {
  if (
    action.type === joinChallengeName ||
    action.type === leaveChallengeName ||
    action.type === setChallengeStatsName
  ) {
    const { payload } = action as
      | JoinChallengeAction
      | LeaveChallengeAction
      | SetChallengeStatsAction;
    const { stats } = payload;
    if (!stats) {
      return state;
    }
    return produce(state, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[payload.challengeId] = stats;
    });
  }

  return state;
};

const challenges = combineReducers({
  lists,
  items,
  counters,
  companyCounters,
  scores,
  stats: statsReducer,
  activities,
  trainings,
  participants,
  organizers,
  groups: challengeGroups,
});

export type ChallengesState = ReturnType<typeof challenges>;

export default challenges;
