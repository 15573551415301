import { breakpoints } from 'shared/theme';

export const mobileQuery = window.matchMedia(
  `(max-width: ${breakpoints.lg}em)`,
);
export const mediumQuery = window.matchMedia(
  `(max-width: ${breakpoints.md}em)`,
);
export const smallQuery = window.matchMedia(`(max-width: ${breakpoints.sm}em)`);

export const getDetectPlatform = cb => () => {
  if (
    typeof process !== 'undefined' &&
    process?.env?.DISABLE_DETECT === 'true'
  ) {
    return true;
  }
  const mobile = mobileQuery.matches;
  const medium = mediumQuery.matches;
  const small = smallQuery.matches;
  if (cb) {
    cb({ mobile, small, medium });
  }
  return { mobile, small, medium };
};

export default getDetectPlatform();
