import { getFetchAction, asyncNames, createActions } from './utils';

export const names = asyncNames('EMPLOYEE_BENEFITS');

export const actions = createActions(names);

export const fetchEmployeeBenefits = getFetchAction(
  actions,
  'api_my_benefits',
  false,
);
