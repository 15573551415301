import { Action, combineReducers } from 'redux';
import produce from 'immer';
import uniq from 'lodash/uniq';
import makeStatusReducer from 'shared/reducers/status';
import {
  addOrganizerActionName,
  AddOrganizerSuccessAction,
  deleteOrganizerActionName,
  DeleteOrganizerSuccessAction,
  fetchOrganizersActionsNames,
  FetchOrganizersSuccessAction,
} from './organizerActions';

export interface OrganizersItemsState {
  [id: number]: number;
}

export interface OrganizersListState {
  [challengeId: number]: number[];
}

const list = (state: OrganizersListState = {}, action: Action<any>) => {
  if (action.type === fetchOrganizersActionsNames.success) {
    const { payload } = action as FetchOrganizersSuccessAction;
    if (payload.page > 1) {
      return produce(state, draft => {
        const ids = draft[payload.challengeId];
        // eslint-disable-next-line no-param-reassign
        draft[payload.challengeId] = uniq([...ids, ...payload.data]);
      });
    }
    return {
      ...state,
      [payload.challengeId]: payload.data,
    };
  }

  if (action.type === deleteOrganizerActionName) {
    const { payload } = action as DeleteOrganizerSuccessAction;
    return {
      ...state,
      [payload.challengeId]: state[payload.challengeId].filter(
        id => id !== payload.employeeId,
      ),
    };
  }

  if (action.type === addOrganizerActionName) {
    const { payload } = action as AddOrganizerSuccessAction;

    return produce(state, draft => {
      const item = draft[payload.challengeId];
      // eslint-disable-next-line no-param-reassign
      draft[payload.challengeId] = uniq([payload.employeeId, ...item]);
    });
  }

  return state;
};

const organizers = combineReducers({
  list,
  status: makeStatusReducer(fetchOrganizersActionsNames),
});

export default organizers;
