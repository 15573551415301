import axios from 'axios';
import get from 'lodash/get';
import { asyncNames, createActions } from 'shared/actions/utils';
import errorLogger from 'shared/utils/errorLogger';

export const names = asyncNames('FETCH_KUDOS');
export const actions = createActions(names);

const fetchKudos = (params, axiosOpts) => async dispatch => {
  dispatch(actions.request(params));
  try {
    const url = AppRouting.generate(
      'api_kudos_get',
      {
        ...params,
      },
      true,
    );
    const response = await axios(url, axiosOpts);
    await dispatch(
      actions.success({
        ...params,
        ...response.data,
      }),
    );

    return response.data;
  } catch (ex) {
    if (axios.isCancel(ex)) {
      dispatch(actions.cancel({ error: ex, ...params }));
      return ex;
    }
    errorLogger(ex);

    const message = get(
      ex,
      'response.data.errors.global',
      i18next.t('kudos:fetchKudosError'),
    );
    dispatch(actions.failure({ error: ex, ...params }));
    const error = new Error(message);
    error.response = ex.response;
    throw error;
  }
};

export default fetchKudos;
