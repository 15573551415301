import { createAction, Action } from 'redux-actions';
import { asyncNames, createActions } from 'shared/actions/utils';
import { DocumentItem } from './documentTypes';
import { DocumentWrapper } from './DocumentWrapper';

export const fetchNames = asyncNames('FETCH_DOCUMENTS');

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const names = {
  ...asyncNames,
  patch: 'DOCUMENT_PATCH',
  set: 'DOCUMENTS_SET',
};

interface DocumentsSetPayload {
  entities: {
    documents: {
      [key: string]: DocumentItem;
    };
  };
}

interface DocumentPatchPayload {
  id: number | string;
  item: DeepPartial<DocumentWrapper['entity']>;
}

interface FetchDocumentsSuccessPayload {
  data: number[];
  entities: {
    documents: {
      [key: string]: DocumentItem;
    };
  };
}

const documentsFetchActions = createActions(fetchNames);

export const documentsSet = createAction<DocumentsSetPayload>(names.set);
export const documentPatch = createAction<DocumentPatchPayload>(names.patch);

export const documentActions = {
  set: documentsSet,
  ...documentsFetchActions,
};

export type DocumentsSetAction = ReturnType<typeof documentsSet>;
export type DocumentPatchAction = ReturnType<typeof documentPatch>;

export type FetchDocumentsSuccessAction = Action<FetchDocumentsSuccessPayload>;
