// HACK: this should be imported from getEnv.js
const getEnv = env => {
  if (typeof window !== 'undefined') {
    return window[env];
  }
  return process.env[env];
};

const envTrue = env => env === '1' || env === 'true';

const disableHttps = envTrue(getEnv('DISABLE_HTTPS'));

const getDomain = (ws = false, protocol) => {
  if (ws) {
    return getEnv('WS_DOMAIN');
  }
  const base = getEnv('API_DOMAIN');

  if (protocol) {
    return `http${!disableHttps ? 's' : ''}://${base}`;
  }
  return base;
};

module.exports = getDomain;
