import { names } from 'shared/actions/bonuses/fetchBonuses';
import { names as fetchNames } from 'shared/actions/bonus/fetch';

const items = (state = {}, { type, payload }) => {
  if (type === names.success) {
    return { ...state, ...payload.data.entities.bonuses };
  }
  if (type === fetchNames.success) {
    return { ...state, [payload.bonus.id]: payload.bonus };
  }
  if (type === names.failure) {
    return { ...state, [payload.id]: { error: true } };
  }
  return state;
};

export default items;
