import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('DELETE_BONUSES');
export const actions = createActions(names);

const isIntegration = bonus => bonus.destination.name === 'integration';

// eslint-disable-next-line @typescript-eslint/camelcase
const deleteBonuses = ({ bonus, company_id }) => async dispatch => {
  dispatch(actions.request({ bonus, company_id }));
  const integration = isIntegration(bonus);
  try {
    await axios.delete(
      AppRouting.generate(
        'api_delete_bonus',
        { company_id, bonus_id: bonus.id },
        true,
      ),
    );
    dispatch(actions.success({ company_id, bonus }));
    if (integration) {
      dispatch(
        notify({
          type: 'success',
          message: i18next.t('bonuses|||integration|||deleteSuccess'),
        }),
      );
      return;
    }
    dispatch(
      notify({
        type: 'success',
        message: i18next.t('bonuses|||deleteSuccess'),
      }),
    );
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      integration
        ? i18next.t('bonuses|||integration|||deleteFailure')
        : i18next.t('bonuses|||deleteFailure'),
    );

    dispatch(notify({ message }));
    dispatch(actions.failure({ bonus, company_id, error }));
  }
};

export default deleteBonuses;
