import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import toCamelCase from 'shared/utils/toCamelCase';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_USER_CONSENT');

export const patch = createActions(names);

const fetchConset = params => async dispatch => {
  const { companyId, userId } = params;
  dispatch(patch.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_patch_user_consent',
        {
          company_id: companyId,
          user_id: userId,
        },
        true,
      ),
    );
    const users = response.data.users.map(user =>
      toCamelCase({
        name: `${user.first_name} ${user.last_name}`,
        ...user,
      }),
    );
    await dispatch(patch.success({ ...params, ...response.data, users }));
  } catch (error) {
    errorLogger(error.response);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('consents|||fetchFailure'),
    );

    dispatch(notify({ message }));
    dispatch(patch.failure({ params, error }));
  }
};

export default fetchConset;
