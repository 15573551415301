import isReactNative from 'shared/utils/isReactNative';

let Logger = {
  captureException: () => {},
};

export const configureErrorLogger = ({ logger }) => {
  Logger = logger;
};

function isError(value) {
  switch (Object.prototype.toString.call(value)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return value instanceof Error;
  }
}

const prepare = (ex, ctx) => {
  if (isError(ex)) {
    return [ex, ctx];
  }
  return [ex, ex];
};

export const getErrorToNotify = (exception, defaultMessage = '') => {
  const responseErrors = exception?.response?.data?.errors;
  return responseErrors ? Object.values(responseErrors)[0] : defaultMessage;
};

const errorLogger = (ex, ctx) => {
  const [error, context] = prepare(ex, ctx);
  const response = error && error.response;
  Logger.captureException(error, {
    extra: context || response,
  });
  if (isReactNative()) {
    // eslint-disable-next-line no-undef
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.warn(ex && ex.message, context || response);
    }
    return;
  }
  // eslint-disable-next-line no-console
  console.error(ex, ctx);
};

export default errorLogger;
