import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';
import { names as purchaseNames } from 'shared/actions/purchases/fetchPurchase';

import merge from 'lodash/merge';

const items = (state = {}, { type, payload }) => {
  if (type === purchasesNames.success) {
    return merge({}, state, payload.data.entities.orders);
  }
  if (type === purchaseNames.success) {
    return merge({}, state, payload.entities.orders);
  }
  return state;
};

export default items;
