import axios from 'axios';
import { Dispatch } from 'redux';
import { asyncNames, createActions } from 'shared/actions/utils';
import Locales from 'shared/types/Locales';
import { AppRouting } from 'shared/utils/AppRouting';
import { TextSection } from './Sections';

export const fetchTextSectionNames = asyncNames('FETCH_TEXT_SECTION');

export const fetchTextSectionActions = createActions(fetchTextSectionNames);

export interface FetchTextSectionAction {
  type: string;
  payload: {
    name: string;
    locale: Locales;
  };
}

export interface FetchTextSectionSuccess extends FetchTextSectionAction {
  type: typeof fetchTextSectionNames['success'];
  payload: {
    response: TextSection;
    locale: Locales;
    name: string;
  };
}

export const fetchSection = ({
  name,
  locale,
}: {
  name: string;
  locale: Locales;
}) => async (dispatch: Dispatch<any>) => {
  dispatch(fetchTextSectionActions.request({ name, locale }));
  try {
    const res = await axios.get(
      AppRouting.generate('api_text_section_get', { section: name }),
    );
    dispatch(
      fetchTextSectionActions.success({ locale, name, response: res.data }),
    );
  } catch (ex) {
    if (axios.isCancel(ex)) {
      dispatch(fetchTextSectionActions.cancel({ locale, name }));

      return;
    }
    dispatch(fetchTextSectionActions.failure({ locale, name, error: ex }));
  }
};
