import { createAction } from 'redux-actions';
import { Dispatch } from 'react';
import errorLogger from 'shared/utils/errorLogger';
import { ActionCreator } from 'redux';
import { AppRouting } from 'shared/utils/AppRouting';
import axios from 'shared/utils/axios';
import { User } from 'shared/types/User';
import { SocialContextType } from 'shared/modules/comments/SocialContext';

const REACTION_ADD = 'REACTION_ADD';

const REACTION_UNDO = 'REACTION_UNDO';

type ReactionTypes = 'like';

export const names = {
  add: REACTION_ADD,
  undo: REACTION_UNDO,
};

export type ReactionEntity = 'comment' | 'kudos' | 'post';

interface ReactionPayload {
  type: ReactionTypes;
  entity: ReactionEntity;
  id: number;
  userId: number;
  user: User | null;
}

interface AddReactionAction {
  type: typeof REACTION_ADD;
  payload: ReactionPayload;
}

interface UndoReactionAction {
  type: typeof REACTION_UNDO;
  payload: ReactionPayload;
}

export type ReactionActions = UndoReactionAction | AddReactionAction;

export const addAction = createAction(REACTION_ADD) as ActionCreator<
  AddReactionAction
>;
export const undoAction = createAction(REACTION_UNDO) as ActionCreator<
  UndoReactionAction
>;

export const reactionActions = {
  add: addAction,
  undo: undoAction,
};

type IdType = number | string;

interface ReactionActionParams {
  type: ReactionTypes;
  entity: ReactionEntity;
  undo: boolean;
  id: IdType;
  userId: number;
  social?: SocialContextType;
}

const comment = {
  add: (id: IdType, type: ReactionTypes) =>
    axios.post(
      AppRouting.generate('api_reaction_comment_create', { id, type }),
    ),
  undo: (id: IdType, type: ReactionTypes) =>
    axios.delete(
      AppRouting.generate('api_reaction_comment_delete', { id, type }),
    ),
};

const kudos = {
  add: (id: IdType, type: ReactionTypes) =>
    axios.post(AppRouting.generate('api_reaction_kudos_create', { id, type })),
  undo: (id: IdType, type: ReactionTypes) =>
    axios.delete(
      AppRouting.generate('api_reaction_kudos_delete', { id, type }),
    ),
};

const post = {
  add: (id: IdType, type: ReactionTypes) =>
    axios.post(AppRouting.generate('api_reaction_post_create', { id, type })),
  undo: (id: IdType, type: ReactionTypes) =>
    axios.delete(AppRouting.generate('api_reaction_post_delete', { id, type })),
};

type SocialPayload = {
  id: IdType;
  type: ReactionTypes;
  companyId?: number;
  entity: ReactionEntity;
};

const object = {
  add: ({ id, type, companyId, entity }: SocialPayload) =>
    axios.post(AppRouting.generate('api_reaction_object_create'), {
      objectId: id,
      objectType: entity,
      reactionType: type,
      company: companyId,
    }),
  undo: ({ id, type, entity }: SocialPayload) =>
    axios.delete(AppRouting.generate('api_reaction_object_delete'), {
      data: { objectId: id, objectType: entity, reactionType: type },
    }),
};

const configs = {
  kudos,
  comment,
  post,
};

const callApi = ({ entity, id, type, undo, social }: ReactionActionParams) => {
  const action = !undo ? 'add' : 'undo';
  if (social) {
    const method = object[action];
    return method({ id, type, companyId: social.companyId, entity });
  }

  const method = configs[entity][action];
  return method(id, type);
};

export const reaction = (params: ReactionActionParams) => async (
  dispatch: Dispatch<any>,
) => {
  const { entity, id, type, undo, userId } = params;
  const action = !undo ? 'add' : 'undo';

  try {
    const { data } = await callApi(params);
    dispatch(
      reactionActions[action]({
        entity,
        type,
        id,
        userId,
        user: undo ? null : data,
      }),
    );
  } catch (error) {
    errorLogger(error);
  }
};
