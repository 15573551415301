import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';
import accounts from '../accounts';

export const names = asyncNames('DELETE_GROUPS');
export const actions = createActions(names);

const deleteGroups = (groups, companyId) => async dispatch => {
  dispatch(actions.request({ company_id: companyId }));
  try {
    await axios.post(
      AppRouting.generate('api_group_delete', { company_id: companyId }, true),
      {
        groups,
      },
    );
    dispatch(actions.success({ company_id: companyId, groups }));
    dispatch(
      notify({ type: 'success', message: i18next.t('groups|||deleteSuccess') }),
    );
    dispatch(accounts());
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('groups|||deleteFailure'),
    );

    dispatch(notify({ message }));
    dispatch(actions.failure({ company_id: companyId, error }));
  }
};

export default deleteGroups;
