import { createAction } from 'redux-actions';

const KUDOS_EDIT = 'KUDOS_EDIT';
const KUDOS_DELETE = 'KUDOS_DELETE';
const KUDOS_REPORT = 'KUDOS_REPORT';
const KUDOS_UNDO_REPORT = 'KUDOS_UNDO_REPORT';
const KUDOS_UPDATE_LIMIT = 'KUDOS_UPDATE_LIMIT';

export const names = {
  edit: KUDOS_EDIT,
  delete: KUDOS_DELETE,
  report: KUDOS_REPORT,
  undoReport: KUDOS_UNDO_REPORT,
  updateLimit: KUDOS_UPDATE_LIMIT,
};

const kudosActions = {
  edit: createAction(KUDOS_EDIT),
  delete: createAction(KUDOS_DELETE),
  report: createAction(KUDOS_REPORT),
  undoReport: createAction(KUDOS_UNDO_REPORT),
  updateLimit: createAction(KUDOS_UPDATE_LIMIT),
};

export interface KudosLimit {
  count: number | null;
  limit: number | null;
  range: 'monthly' | 'quarterly' | 'yearly';
}

export interface UpdateLimitAction {
  type: typeof KUDOS_UPDATE_LIMIT;
  payload: {
    limit: KudosLimit;
    companyId: number;
  };
}

export default kudosActions;
