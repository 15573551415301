import { asyncNames, createActions, getFetchAction } from './utils';
// import { normalize } from 'normalizr';

export const names = asyncNames('INFO_BOXES');
export const actions = createActions(names);

const fetchHtml = getFetchAction(
  actions,
  'api_injectable_html_get_specified',
  false,
);

export default fetchHtml;
