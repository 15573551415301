import { combineReducers, Action } from 'redux';
import { names as uiNames } from 'shared/actions/ui';
import makeStatusReducer from 'shared/reducers/status';
import { GuidesResponse, GuideSetsState } from 'shared/types/Guides';
import { names } from './fetchGuideSets';

interface GuideStepsSuccessAction {
  type: 'FETCH_GUIDE_SETS_SUCCESS';
  payload: GuidesResponse;
}

interface GuideAction {
  type: string;
  payload: {
    id: number;
  };
}

const sets = (
  state: GuideSetsState['sets'] = {},
  action: Action<any> | GuideStepsSuccessAction,
) => {
  if (action.type === names.success) {
    const { payload } = action as GuideStepsSuccessAction;
    return {
      ...state,
      ...payload.entities.guideSets,
    };
  }

  return state;
};

const guides = (
  state: GuideSetsState['guides'] = {},
  action: Action<any> | GuideStepsSuccessAction | GuideAction,
) => {
  if (action.type === names.success) {
    const { payload } = action as GuideStepsSuccessAction;
    return {
      ...state,
      ...payload.entities.guides,
    };
  }
  if (action.type === uiNames.closeGuide) {
    const { payload } = action as GuideAction;
    const guide = state[payload.id];

    return {
      ...state,
      [payload.id]: {
        ...guide,
        visited: true,
      },
    };
  }

  return state;
};

const list = (
  state: number[] = [],
  action: Action<any> | GuideStepsSuccessAction,
) => {
  if (action.type === names.success) {
    const { payload } = action as GuideStepsSuccessAction;
    return payload.data;
  }

  return state;
};

export const status = makeStatusReducer(names);

export default combineReducers({
  sets,
  list,
  guides,
  status,
});
