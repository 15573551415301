import axios, { AxiosError, AxiosRequestConfig } from 'axios';
// import { normalize } from 'normalizr';
import errorLogger from 'shared/utils/errorLogger';
import i18next from 'i18next';
import { normalizeGroups } from 'shared/actions/groups/fetch';
import { Dispatch } from 'redux';
import { asyncNames, createActions } from 'shared/actions/utils';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import get from 'lodash/get';
import notify from 'shared/actions/notify';

const PER_PAGE = 50;

export const names = asyncNames('FETCH_CHALLENGE_GROUPS');

export const groups = createActions(names);
/* eslint-disable @typescript-eslint/camelcase */

const fetchChallengeGroups = (
  params: RoutingParams,
  opts: AxiosRequestConfig,
  setError: (error: AxiosError<any> | null) => void,
) => async (dispatch: Dispatch<any>) => {
  dispatch(groups.request({ params }));
  try {
    const response = await axios.get(
      AppRouting.generate(
        'api_challenge_group_search',
        {
          ...params,
          max_per_page: params.max || PER_PAGE,
        },
        true,
      ),
      opts,
    );
    const data = normalizeGroups(response.data);
    await dispatch(groups.success({ params, data }));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return error;
    }

    const errorMessage = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||fetchFailure'),
    );

    setError(error);
    dispatch(notify({ message: errorMessage }));

    errorLogger(error);
    dispatch(groups.failure({ params, error }));
    return error;
  }
};

export default fetchChallengeGroups;
