import { Action, combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import { NewsItemsState } from 'shared/types/news/News';
import { MetaState } from 'shared/types/reducers/MetaState';
import produce from 'immer';
import {
  DeleteArticleAction,
  DELETE_ARTICLE,
  fetchNewsActionNames,
  FetchNewsSuccessAction,
  NewsListAction,
  newsListActionNames,
  NewsListParams,
  NewsListSuccessAction,
} from './newsActions';
import newsCategories from './newsCategoriesStore';

export type NewsListState = number[];

const items = (
  state: NewsItemsState = {},
  action: NewsListSuccessAction | FetchNewsSuccessAction,
) => {
  if (action.type === newsListActionNames.success) {
    const { payload } = action as NewsListSuccessAction;
    const { articles } = payload.response.entities;
    return { ...state, ...articles };
  }
  if (action.type === fetchNewsActionNames.success) {
    const { id, news } = action.payload;
    return {
      ...state,
      [id]: news,
    };
  }
  return state;
};

const list = (
  state: NewsListState = [],
  action: NewsListSuccessAction | DeleteArticleAction,
) => {
  if (action.type === newsListActionNames.success) {
    const { payload } = action as NewsListSuccessAction;

    const { data, page } = payload.response;
    if (page > 1) {
      return [...state, ...data];
    }
    return data;
  }
  if (action.type === DELETE_ARTICLE) {
    const { payload } = action as DeleteArticleAction;
    return state.filter(id => id !== payload.articleId);
  }
  return state;
};

export const getNewsListKey = ({
  companyId,
  all,
  publication,
  categoryId,
  companyFilter,
  uncategorized,
  global,
  filter,
  promo,
}: NewsListParams) => {
  const parts: string[] = [`company:${companyId || 0}`];
  if (!uncategorized) {
    parts.push(`category:${categoryId || 'latest'}`);
  }
  if (!uncategorized) {
    parts.push('uncategorized');
  }
  if (all) {
    parts.push('all');
  }
  if (publication) {
    parts.push(`publication:${publication}`);
  }
  if (companyFilter) {
    parts.push('companyFilter');
  }
  if (global) {
    parts.push('global');
  }
  if (promo) {
    parts.push('promo');
  }
  if (filter) {
    parts.push('filter');
  }
  return parts.join('|');
};

const meta = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === newsListActionNames.success) {
    const { payload } = action as NewsListSuccessAction;

    const { data, ...rest } = payload.response;

    return rest;
  }
  return state;
};

const newsActions = Object.values(newsListActionNames);
const listReducer = combineReducers({
  list,
  meta,
  status: makeStatusReducer(newsListActionNames),
});

export type NewsList = ReturnType<typeof listReducer>;

const lists = (state: Record<string, NewsList> = {}, action: Action<any>) => {
  if (newsActions.includes(action.type)) {
    const { payload } = action as NewsListAction;
    const key = getNewsListKey(payload.params);
    return {
      ...state,
      [key]: listReducer(state[key], action),
    };
  }
  if (action.type === DELETE_ARTICLE) {
    return produce(state, draft => {
      const keys = Object.keys(draft);

      keys.forEach(key => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = listReducer(state[key], action);
      });
    });
  }
  return state;
};

const news = combineReducers({
  lists,
  items,
  categories: newsCategories,
});

export type NewsState = ReturnType<typeof news>;

export default news;
