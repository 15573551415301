import createAction from 'redux-actions/lib/createAction';
import axios from 'axios';
import getToken from 'shared/utils/user/getToken';
import errorLogger from 'shared/utils/errorLogger';
import { cancelRefreshToken } from 'shared/utils/user/tokenRefresh';
import { close } from 'shared/modules/notifications/websocket/websocketListener';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';

export const USER_LOGOUT = 'USER_LOGOUT';
export const logoutAction = createAction(USER_LOGOUT);

const removeToken = async token => {
  if (!token) {
    return;
  }
  try {
    await axios.delete(AppRouting.generate('api_token_invalidate'), {
      headers: {
        'X-Viamobile-Token': token,
      },
    });
  } catch (ex) {
    errorLogger(ex);
  }
};

const logout = (remove = true, payload) => dispatch => {
  cancelRefreshToken();
  close(dispatch);
  // save token
  const token = getToken();
  if (remove) {
    removeToken(token);
  }
  try {
    // clear storage data
    safeSessionStorage.removeItem('token');
    safeLocalStorage.removeItem('token');
    safeLocalStorage.removeItem('expires');
    safeSessionStorage.removeItem('expires');
    safeLocalStorage.removeItem('rememberMe');
    safeSessionStorage.removeItem('company_id');
    // localStorage.removeItem('company_id');
  } catch (ex) {
    // storage errors
    console.log(ex);
  }
  return dispatch(logoutAction(payload));
};

export default logout;
