import { createAction } from 'redux-actions';
import { createActions, asyncNames } from 'shared/actions/utils';
import IdDictionary from 'shared/types/IdDictionary';
import { NewsCategory } from 'shared/types/news/News';

export const newsCategoriesActionNames = asyncNames('NEWS_CATEGORIES') as {
  success: 'NEWS_CATEGORIES_SUCCESS';
  failure: 'NEWS_CATEGORIES_FAILURE';
  request: 'NEWS_CATEGORIES_REQUEST';
  cancel: 'NEWS_CATEGORIES_CANCEL';
};

export const newsCategoriesAction = createActions(newsCategoriesActionNames);

export interface NewsCategoriesActionPayload {
  companyId: number;
  all?: boolean;
}
export interface NewsCategoriesAction {
  type: string;
  payload: NewsCategoriesActionPayload;
}

export interface NewsCategoriesSuccessActionPayload
  extends NewsCategoriesActionPayload {
  data: number[];
  entities: {
    categories: IdDictionary<NewsCategory>;
  };
}

export interface NewsCategoriesSuccessAction {
  type: 'NEWS_CATEGORIES_SUCCESS';
  payload: NewsCategoriesSuccessActionPayload;
}

export const UPDATE_NEWS_CATEGORY = 'UPDATE_NEWS_CATEGORY';
export const REMOVE_NEWS_CATEGORY = 'REMOVE_NEWS_CATEGORY';

export const updateNewsCategory = createAction(UPDATE_NEWS_CATEGORY);
export const removeNewsCategory = createAction(REMOVE_NEWS_CATEGORY);

export interface NewsCategoryActionPayload {
  category: NewsCategory;
}

export interface UpdateNewsCategoryAction {
  type: typeof UPDATE_NEWS_CATEGORY;
  payload: NewsCategoryActionPayload;
}

export interface RemoveNewsCategoryAction {
  type: typeof REMOVE_NEWS_CATEGORY;
  payload: NewsCategoryActionPayload;
}
