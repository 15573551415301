import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import webSetCurrentCompany from 'shared/actions/company';
import hasPermission from 'shared/utils/hasPermission';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import { Dispatch } from 'redux';
import keyBy from 'lodash/keyBy';
import { AppRouting } from 'shared/utils/AppRouting';
import { CompaniesState } from 'shared/reducers/companies';
import { Company } from 'shared/types/Company';
import { AppState } from 'shared/types/AppState';
import { Employee } from 'shared/types/Employee';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_COMPANIES');

interface CompaniesResponse {
  companies: Company[];
  employees: {
    [id: number]: Employee;
  };
}

interface Params {
  data?: CompaniesResponse;
}

interface FetchCompaniesActions {
  request: (payload: { params: Params }) => void;
  success: (payload: {
    params: Params;
    entities: {
      companies: CompaniesState;
      employees: {
        [id: number]: Employee;
      };
    };
  }) => void;
  failure: (payload: { params: Params; error: AxiosError<any> }) => void;
}

export const actions = createActions(names) as FetchCompaniesActions;

const webGetCurrentId = async () => {
  try {
    const promise =
      safeLocalStorage.getItem('company_id') ||
      safeSessionStorage.getItem('company_id');
    const res = promise;
    if (!res) {
      return undefined;
    }
    const id = parseInt(res, 10);
    if (Number.isInteger(id)) {
      return id;
    }
  } catch (ex) {
    // no storage;
  }
  return undefined;
};

const managePerms = [
  'employees.list',
  'bonuses.list',
  'groups.list',
  'zfss.list',
  'integrations.list',
  'settlements.company',
  'company.view',
  'settlements.groups',
  'settlements.integrations',
  'partners.reception_view',
  'settlements.partner',
  'partners_settlements.download_invoices',
  'partners.set_user_as_reception',
  'bonuses.confirm',
  'bonuses.confirm_view',
];

const defaultGetActiveCompany = (
  id: number | undefined,
  companiesArr: Company[],
  companiesById: CompaniesState,
) => {
  if (
    id &&
    companiesById[id] &&
    hasPermission(managePerms, companiesById[id].permissions, true)
  ) {
    return companiesById[id];
  }
  const withPerms = companiesArr.find(company =>
    hasPermission(managePerms, company.permissions, true),
  );
  if (withPerms) {
    return withPerms;
  }
  if (id && companiesById[id]) {
    return companiesById[id];
  }
  if (!companiesArr[0]) {
    throw new Error('This user has no companies');
  }
  return companiesArr[0];
};

export const getFetchCompanies = (
  getCurrentId: () => Promise<number | undefined>,
  set = webSetCurrentCompany,
  getActiveCompany = defaultGetActiveCompany,
) => (params: Params | undefined = {}, logout?: () => void) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => {
  dispatch(actions.request({ params }));
  try {
    let { data } = params;
    if (!data || !data.companies || !data.employees) {
      const response = await axios(AppRouting.generate('api_users_companies'));
      // eslint-disable-next-line prefer-destructuring
      data = response.data as CompaniesResponse;
    }
    const { companies, employees } = data;
    const byId = keyBy(companies, 'id');
    // Set current company if not already set;
    const current = getState().company;

    if (!current) {
      const id = await getCurrentId();
      const company = getActiveCompany(id, Object.values(companies), byId);
      dispatch(set(company));
    } else if (byId[current.id]) {
      dispatch(set(byId[current.id]));
    }
    return dispatch(
      actions.success({ params, entities: { companies: byId, employees } }),
    );
  } catch (error) {
    if (logout) {
      logout();
    }
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      // @ts-ignore
      i18next.t('user|||companiesFetchFailure'),
    );

    dispatch(notify({ message }));
    return dispatch(actions.failure({ params, error }));
  }
};

export default getFetchCompanies(webGetCurrentId, webSetCurrentCompany);
