import { names } from 'shared/actions/user/data';
import makeStatusReducer from './status';

const initial = null;

const userData = (state = initial, action) => {
  if (action.type === 'UPDATE_USER' || action.type === names.success) {
    return { ...action.payload.user, invitations: action.payload.invitations };
  }
  if (action.type === names.patched) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const userDataStatus = makeStatusReducer(names);

export default userData;
