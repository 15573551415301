import { names } from 'shared/actions/fetchKudos';
import { names as singleKudosNames } from 'shared/actions/fetchSingleKudos';
import { names as kudosActionNames } from 'shared/modules/kudos/kudosActions';

import { MetaState } from 'shared/types/reducers/MetaState';
import { KudosResponseItem } from 'shared/types/Kudos';
import makeReactionsStateReducer from 'shared/modules/reactions/makeReactionStateReducer';

import { combineReducers } from 'redux';
import { Action } from 'redux-actions';
import omit from 'lodash/omit';
import makeStatusReducer, { StatusState } from './status';
import limitReducer from '../modules/kudos/limitReducer';

export interface KudosTypeState {
  list: number[];
  status: StatusState;
  meta: MetaState & {
    filter?: string;
    type?: string;
    kudos_type?: number;
    group?: number;
    date_from?: number;
    date_to?: number;
  };
}

export const getKudosKey = ({
  type,
  company,
}: {
  type: string;
  company: number;
}) => `${type}.${company}`;

const list = (state = [], { type, payload }: Action<any>) => {
  if (type !== names.success) {
    return state;
  }
  if (payload.page === 1) {
    return payload.data;
  }
  return [...state, ...payload.data];
};

const meta = (state = {}, { type, payload }: Action<any>) => {
  if (type === names.success) {
    const { data, entities, ...rest } = payload;
    return rest;
  }
  return state;
};

type KudosTypeReducer = (
  state: KudosTypeState,
  action: Action<any>,
) => KudosTypeState;

const byType: KudosTypeReducer = combineReducers({
  list,
  status: makeStatusReducer(names),
  meta,
});

export interface KudosItemsState {
  [id: number]: KudosResponseItem;
}

export interface KudosTypesState {
  [key: string]: KudosTypeState;
}

const types = (state: KudosTypesState = {}, action: Action<any>) => {
  if (Object.values(names).find(name => name === action.type)) {
    const { payload } = action;
    const key = getKudosKey(payload);
    return {
      ...state,
      [key]: byType(state[key], action),
    };
  }
  return state;
};

const kudosReactionsReducer = makeReactionsStateReducer('kudos');

const items = (
  state: KudosItemsState = {},
  action: Action<any>,
): KudosItemsState => {
  if (names.success === action.type) {
    const { payload } = action;
    return {
      ...state,
      ...payload?.entities?.kudos,
    };
  }
  if (singleKudosNames.success === action.type) {
    const {
      payload: { data },
    } = action;
    return {
      ...state,
      [data.id]: data,
    };
  }
  if (action.type === kudosActionNames.delete) {
    return omit(state, [action.payload.id]);
  }
  if (action.type === kudosActionNames.report) {
    const { payload } = action;
    const { userId, id } = payload;
    const prev = state[id];
    if (!prev) {
      return state;
    }
    const kudos = {
      ...prev,
      reporter: userId,
      reported: true,
    } as KudosResponseItem;
    return {
      ...state,
      [id]: kudos,
    };
  }

  if (action.type === kudosActionNames.undoReport) {
    const { payload } = action;
    const { id } = payload;
    const prev = state[id];
    if (!prev) {
      return state;
    }
    const kudos = {
      ...prev,
      reporter: null,
      reported: false,
    };
    return {
      ...state,
      [id]: kudos,
    };
  }
  return kudosReactionsReducer(state, action) as KudosItemsState;
};

const kudos = combineReducers({
  types,
  limit: limitReducer,
  items,
});

export type KudosState = ReturnType<typeof kudos>;

export default kudos;
