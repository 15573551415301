
import axios from 'axios';
import errorLogger from 'shared/utils/errorLogger';
import get from 'lodash/get';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('UPDATE_REPORT_DAY');

export const actions = createActions(names);

const updateReportDay = ({ companyId, day }) => async (dispatch) => {
  dispatch(actions.request({ companyId, day }));
  try {
    await axios.patch(AppRouting.generate('api_companies_update_period_day', {
      company_id: companyId,
    }), { operationReportPeriodDay: day });
    dispatch(actions.success({ companyId, day }));
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||updateFailure'),
    );

    dispatch(notify({ message }));
    dispatch(actions.failure({ companyId, day, error }));
  }
};

export default updateReportDay;
