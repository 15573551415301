import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('PATCH_USER_CONSENT');

export const patch = createActions(names);

const patchConsent = params => async (dispatch) => {
  const { company, user, consent, value } = params;
  dispatch(patch.request({ company, user, consent, value }));
  try {
    await axios.patch(
      AppRouting.generate(
        'api_patch_user_consent',
        {
          company_id: company,
          user_id: user,
        },
        true,
      ),
      {
        consent,
        value,
      },
    );
    await dispatch(patch.success({ ...params }));
  } catch (error) {
    errorLogger(error.response);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('consents|||patchFailure'),
    );

    dispatch(notify({ message }));
    dispatch(patch.failure({ params, error }));
    throw new Error(message);
  }
};

export default patchConsent;
