const configStatic = {
  SMALL_PLACEHOLDER: '/img/placeholder/640x395.jpg',
  WIDE_PLACEHOLDER: '/img/placeholder/890x334.jpg',
  contactMail: 'support@nais.co',
  defaultPerPage: 25,
} as const;

// eslint-disable-next-line prefer-destructuring
export const defaultPerPage = configStatic.defaultPerPage;

export default configStatic;
