import { asyncNames, createActions, getFetchAction } from '../utils';
// import { normalize } from 'normalizr';


export const names = asyncNames('FETCH_WHIP_ROUNDS');
export const actions = createActions(names);

const fetchWhipRounds = getFetchAction(actions, 'api_whipround_list', false);

export default fetchWhipRounds;
