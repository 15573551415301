import { createAction } from 'redux-actions';
import { ConsentsApiResponse, ThirdPartyConsent } from 'shared/types/Consents';

export interface ConsentsFetchedAction {
  type: 'CONSENTS_FETCHED';
  payload: ConsentsApiResponse;
}

export type ConsentUpdatePayload = {
  id: number;
} & Partial<ThirdPartyConsent>;

export interface ConsentUpdateAction {
  type: 'CONSENT_UPDATE';
  payload: ConsentUpdatePayload;
}

export const names = {
  consentsFetched: 'CONSENTS_FETCHED',
  consentUpdate: 'CONSENT_UPDATE',
};

export const consentsFetch = createAction<ConsentsApiResponse>(
  names.consentsFetched,
);

export const consentUpdate = createAction<ConsentUpdatePayload>(
  names.consentUpdate,
);
