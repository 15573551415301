import omit from 'lodash/omit';
import { Action, combineReducers } from 'redux';
import { MetaState } from 'shared/types/reducers/MetaState';
import makeStatusReducer from 'shared/reducers/status';
import { ChallengeStatChart } from './Challenge';
import {
  deleteTrainingActionName,
  DeleteTrainingSuccessAction,
  fetchTrainingsNames,
  FetchTrainingsSuccessAction,
  updateTrainingActionName,
  UpdateTrainingSuccessAction,
  SetTrainingsStatsAction,
  setTrainingsStatsName,
} from './challengesActions';
import { Training } from './Trainings';

export type TrainingItemsState = Record<number, Training>;

const metaReducer = (state: MetaState | null = null, action: Action<any>) => {
  if (action.type === fetchTrainingsNames.success) {
    const { payload } = action as FetchTrainingsSuccessAction;
    const { entities, data, ...meta } = payload.data;
    return meta;
  }

  return state;
};

const items = (
  state: TrainingItemsState = {},
  action: Action<any>,
): TrainingItemsState => {
  if (action.type === fetchTrainingsNames.success) {
    const { payload } = action as FetchTrainingsSuccessAction;
    return {
      ...state,
      ...payload.data.entities.trainings,
    };
  }

  if (action.type === updateTrainingActionName) {
    const { payload } = action as UpdateTrainingSuccessAction;
    const training = state[payload.trainingId];
    if (!training) {
      return state;
    }
    return {
      ...state,
      [training.trainingId]: { ...training, ...payload.update },
    };
  }

  if (action.type === deleteTrainingActionName) {
    const { payload } = action as DeleteTrainingSuccessAction;
    return omit(state, [payload.trainingId]);
  }

  return state;
};

const list = (state: number[] = [], action: Action<any>) => {
  if (action.type === fetchTrainingsNames.success) {
    const { payload } = action as FetchTrainingsSuccessAction;
    if (payload.data.page === 1) {
      return payload.data.data;
    }
    return [...state, ...payload.data.data];
  }

  if (action.type === deleteTrainingActionName) {
    const { payload } = action as DeleteTrainingSuccessAction;
    return state.filter((id) => id !== payload.trainingId);
  }

  return state;
};

const stats = (
  state: ChallengeStatChart[] | null = null,
  action: Action<any>,
) => {
  if (action.type === setTrainingsStatsName) {
    const { payload } = action as SetTrainingsStatsAction;

    return payload.stats || [];
  }

  return state;
};

const trainings = combineReducers({
  items,
  list,
  stats,
  status: makeStatusReducer(fetchTrainingsNames),
  meta: metaReducer,
});

export default trainings;
