import { EmployeeData } from 'shared/types/Employee';
import IdDictionary from 'shared/types/IdDictionary';
import { UploadedImage } from 'shared/types/ImageData';
import { MetaState } from 'shared/types/reducers/MetaState';
import { MeasureType } from '../challenges/Challenge';
import { ReactionsResponse } from '../comments/Comment';
import {
  CommentsPermissions,
  PostsPermissions,
  ReactionsPermissions,
} from '../ModulePermissions';

export enum PostEntity {
  challenge = 'challenge',
  newsfeed = 'newsfeed',
  article = 'article',
}

export interface ChallengePostContext {
  challengeId: number;
  title: string;
  type: 'challenge';
}

export type PostContextType = ChallengePostContext;

export interface BasePost {
  id: number;
  canDelete: boolean;
  tenorId: string | null;
  employeeId: number | null;
  userId: number | null;
  content: string | null;
  createdAt: string;
  thread: number | null;
  updatedAt: string;
  reactions: ReactionsResponse;
  value: number | null; // ??
  valueType: null | MeasureType; // ??;
  context?: PostContextType | null;
  image: UploadedImage | null;
}

export interface ChallengeUserPost extends BasePost {
  type: 'user';
  employeeId: number;
  userId: number;
}

export interface TrainingPost extends BasePost {
  type: 'training';
  value: number;
  employeeId: number;
  userId: number;
  activity?: {
    label?: string;
    measure?: string;
    icon?: string;
  };
}

export interface SystemPost extends BasePost {
  type: 'system';
  value: number;
  employeeId: number | null;
  userId: number;
  event?: string;
}

export interface PostResponse extends MetaState {
  data: number[];
  entities: {
    posts: IdDictionary<Post>;
    employees: IdDictionary<EmployeeData>;
  };
}

export interface PostsModules {
  posts: PostsPermissions;
  comments: CommentsPermissions;
  reactions: ReactionsPermissions;
}

export interface PostActions {
  handleDelete: (post: Post) => void;
  handleEdit: (post: Post) => void;
}

export interface GenericPost {
  id: string;
  tenorId: string | null;
  content: string;
  thread: string;
  employeeId: number;
  type: 'user';
  userId: number;
  image: UploadedImage | null;
  createdAt: string;
  updatedAt: string;
  reactions: ReactionsResponse;
  entityType: string;
  objectId: string;
  objectType: string;
}

export interface GenericUserPost extends GenericPost {
  content: string;
  employeeId: number;
  userId: number;
}

export type Post =
  | ChallengeUserPost
  | TrainingPost
  | SystemPost
  | GenericUserPost;
