import { createAction } from 'redux-actions';
import { PaymentMethod } from '@stripe/stripe-js';
import { asyncNames, createActions } from 'shared/actions/utils';

export const fetchStripeCustomerNames = asyncNames('FETCH_STRIPE_CUSTOMER');

export const fetchStripeCustomerAction = createActions(
  fetchStripeCustomerNames,
);

export const patchStripeCustomerName = 'PATCH_STRIPE_CUSTOMER';

export const patchStripeCustomerAction = createAction(patchStripeCustomerName);

export const fetchStripeCardsNames = asyncNames('FETCH_STRIPE_CARDS');

export const fetchStripeCardsAction = createActions(fetchStripeCardsNames);

export const stripeCardDetachName = 'STRIPE_CARD_DETACH';

export const stripeCardDetach = createAction(stripeCardDetachName);

export interface BasicStripeCustomer {
  id: string;
  object: 'customer';
  created: number;
  currency: string;
  default_source: string | null;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: null;
    default_payment_method: string | null;
    footer: null;
  };
  name: string;
  next_invoice_sequence: number;
  phone: string | null;
  tax_exempt: string;
}

export interface FetchStripeCustomerSuccess {
  type: typeof fetchStripeCustomerNames.success;
  payload: {
    data: BasicStripeCustomer;
  };
}

export interface FetchStripeCardsSuccess {
  type: typeof fetchStripeCardsNames.success;
  payload: {
    data: PaymentMethod[];
    customerId: string;
    has_more: boolean;
  };
}
export interface StripeCardDetach {
  type: typeof fetchStripeCardsNames.success;
  payload: {
    method: PaymentMethod;
  };
}

export interface PatchStripeCustomer {
  type: typeof patchStripeCustomerName;
  payload: {
    data: BasicStripeCustomer;
  };
}
