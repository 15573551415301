import space, { pxToRem } from './utils/space';

export const breakpoints = {
  xs: 0, // em
  sm: pxToRem(768), // 48 em
  md: pxToRem(864), // 54 em
  lg: pxToRem(1232), // 77 em
};

const container = {
  // xs: 23.125,
  sm: pxToRem(660), // 41.25 rem
  md: pxToRem(800), // 50 rem
  lg: pxToRem(1170), // 73.125 rem
};

export const user = {
  primary10: '#EFFBED',
  primary25: '#c3e2bf',
  primary50: '#ECF6EB',
  primary100: '#CFE8CC',
  primary200: '#9FD099',
  primary300: '#70B966',
  primary400: '#39AA2C',
  primary500: '#108A00',
  primary600: '#0D6E00',
  primary700: '#0A5300',
  primary800: '#063700',
  primary900: '#031C00',
  hover: '#20D60B',
  bg: '#E8F9EC',
};

export const company = {
  primary10: '#eaf1fc',
  primary25: '#cbdaf8',
  primary50: '#E4F4FF',
  primary100: '#D6E2F9',
  primary200: '#ACC4F3',
  primary300: '#83A7EE',
  primary400: '#3770DF',
  primary500: '#2656B5',
  primary600: '#204999',
  primary700: '#133679',
  primary800: '#122753',
  primary900: '#0A162D',
  hover: '#8FB0EF',
  bg: '#E5F8FD',
};

export const grayPalette = {
  gray50: '#F2F2F5',
  gray100: '#F1F3F6',
  gray200: '#E3E7EC',
  gray300: '#D6DBE3',
  gray400: '#C8CFD9',
  gray500: '#BAC3D0',
  gray600: '#959CA6',
  gray700: '#70757D',
  gray800: '#4A4E53',
  gray900: '#25272A',
  bgGray10: '#fdfdfe',
  bgGray20: '#fcfdfe',
  bgGray50: '#f8f9fb',
};

export const alert = {
  alert600: '#C1170B',
  alert500: '#F44336',
  alert400: '#F7776E',
  alert300: '#FAA59E',
};

export const caution = {
  caution600: '#81701F',
  caution500: '#FFD500',
  caution400: '#FFE666',
  caution300: '#FFF2B2',
  caution200: '#bbbb43',
};

export const highlight = {
  highlight600: '#E35402',
  highlight500: '#FD7222',
  highlight400: '#FE9E67',
  highlight300: '#FEBF9A',
};

export const standby = {
  standby600: '#d97706',
  standby500: '#f59e0b',
  standby400: '#fbbf24',
  standby300: '#fcd34d',
};

export const font = {
  primary: grayPalette.gray900,
  secondary: grayPalette.gray800,
  disabled: grayPalette.gray800,
  highlight: highlight.highlight500,
  link: user.primary500,
};

export const grays = {
  darkGray: grayPalette.gray900,
  mediumGray: grayPalette.gray800,
  ravenGray: grayPalette.gray700,
  cloudyBlue: grayPalette.gray500,
  paleGray: grayPalette.gray100,
  lightGray: grayPalette.gray50,
  paleBlue: grayPalette.gray300,
};

export const bg = '#ffffff';

const grayscale = [
  grays.paleGray,
  grays.paleBlue,
  grays.cloudyBlue,
  grays.mediumGray,
  grays.darkGray,
];

export const status = {
  error: alert.alert500,
  success: user.primary400,
  info: grayPalette.gray900,
  caution: caution.caution500,
  cautionHover: caution.caution600,
};

const theme = {
  // Color should be a string or and array of valid colors (hex, rgba etc...)
  color: {
    primary: user.primary400, // This will change based on mode
    company: {
      primary: company.primary400,
      primaryAlternative: company.primary400,
      primarySmall: company.primary400,
      primaryHover: company.hover,
      primaryStroke: company.primary400,
      primaryBg: company.bg,
      primary50: company.primary50,
      primary25: company.primary25,
      primary10: company.primary10,
      primary100: company.primary100,
      primary200: company.primary200,
      primary300: company.primary300,
      primary400: company.primary400,
      primary500: company.primary500,
      primary600: company.primary600,
      primary700: company.primary700,
    },
    user: {
      primary: user.primary400,
      primaryAlternative: user.primary500,
      primarySmall: user.primary500,
      primaryHover: user.hover,
      primaryStroke: user.primary500,
      primaryBg: user.bg,
      primary50: user.primary50,
      primary25: user.primary25,
      primary10: user.primary10,
      primary100: user.primary100,
      primary200: user.primary200,
      primary300: user.primary300,
      primary400: user.primary400,
      primary500: user.primary500,
      primary600: user.primary600,
      primary700: user.primary700,
    },
    backgrounds: {
      brand: user.bg,
      info: '#e6f8fd',
      alert: '#ffebe9',
      warning: '#ffebe9',
      danger: '#fdebdd',
    },
    ...alert,
    ...grayPalette,
    ...caution,
    ...highlight,
    ...standby,
    status,
    secondary: font.highlight,
    brand: user.primary400,
    success: user.primary400,
    danger: font.highlight,
    font,
    bg,
    green500: user.primary500,
    bgGray: grayPalette.gray50,
    gray: grayscale,
    ...grays,
    chart: {
      current: ['#2E40C7', '#9EA9FD', '#D5DAE2'],
      zfss: ['#D182E6', '#ECC6F6', '#D5DAE2'],
      totalCurrent: ['#e34703', '#FFD0B6'],
      totalConsumed: ['#FFBE0B', '#FFF3D2'],
      group: ['#2fc6a1', '#afeadc'],
      integration: ['#50b2e5', '#afd6ea'],
      personal: ['#20D60B', '#FFD500', '#306CE2'],
      teamAge: ['#20D60B', '#FFD500', '#306CE2', '#FD7222', '#F1F3F6'],
    },
    balance: {
      bonus: '#3ed964',
      price: '#64e083',
      zfss: '#8be8a2',
      depositions: '#c5f3d0',
    },
  },
  borderRadius: 5,
  contentBorderRadius: 10,
  // Values are numbers
  value: {
    pad: {
      xl: 25,
      l: 20,
      m: 10,
      s: 5,
      xs: 2,
    },
    header: {
      height: 143,
      navigation: 82,
    },
    filter: {
      width: 250,
    },
    sideNav: {
      width: 280,
      fontSize: 14,
    },
    breakpoints,
    media: {
      smallMobile: 30, // 480
      mobileNarrow: 48, // 768
      fluid: 43.125,
      md: 54,
      viewNarrow: 64,
      mobile: 77, // em 1230px
      viewStandard: 73.125,
      productWide: 73.125,
      wide: 73.125,
      headerNarrow: 73.125, // 1400
    },
  },
  container,
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: space.numberEm(3), // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container,
    breakpoints,
  },
};

export const colorsToChart = [
  '#800000',
  '#000080',
  '#808000',
  '#800080',
  '#008080',
  '#C00000',
  '#008000',
  '#0000FF',
  '#d90101',
  '#000000',
  '#400000',
  '#0000C0',
  '#000040',
  '#002020',
  '#C000C0',
  '#00C0C0',
  '#C0C0C0',
  '#004000',
  '#404000',
  '#400040',
  '#004040',
  '#404040',
  '#200020',
  '#600000',
  '#808080',
  '#006000',
  '#000060',
  '#606000',
  '#600060',
  '#006060',
  '#606060',
  '#A00000',
  '#00A000',
  '#0000A0',
  '#A0A000',
  '#A000A0',
  '#00A0A0',
  '#A0A0A0',
  '#E00000',
  '#107e10',
  '#E000E0',
  '#0000E0',
  '#c9c97f',
  '#00E0E0',
  '#E0E0E0',
  '#00C000',
  '#b4b449',
  '#FF00FF',
  '#02a6a6',
  '#f5f50c',
  '#00FF00',
];

export default theme;
