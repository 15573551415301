import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import notify from './notify';
import { asyncNames, createActions } from './utils';

export const names = asyncNames('FETCH_CITIES');

export const cities = createActions(names);


const shouldLoad = getState => !getState().cities;


const fetchCities = (params, forceRefresh = false) => async (
  dispatch,
  getState,
) => {
  if (!forceRefresh && !shouldLoad(getState)) {
    return;
  }
  dispatch(cities.request());
  try {
    const response = await axios(
      AppRouting.generate(
        'api_get_cities',
        {},
        true,
      ),
    );
    dispatch(cities.success({ cities: response.data }));
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('products|||citiesFetchFailure'),
    );

    dispatch(notify({ message }));
    dispatch(cities.failure({ params, error }));
  }
};

export default fetchCities;
