import makeStatusReducer, { StatusState } from 'shared/reducers/status';
import { ReactionActions } from 'shared/modules/reactions/reactionActions';
import { names as kudosNames } from 'shared/actions/fetchKudos';
import { names as kudosSingleNames } from 'shared/actions/fetchSingleKudos';

import { Action, combineReducers } from 'redux';
import {
  names,
  CommentsAction,
  assertCommentAction,
  CommentCreateAction,
  CommentsAsyncAction,
  CommentsSuccessAction,
  CommentEditAction,
  CommentDeleteAction,
  CommentReportAction,
  CommentUndoReportAction,
} from './commentsActions';
import { CommentResponse, CommentThread } from './Comment';
import { makeThreadKey } from './commentUtils';
import makeReactionsStateReducer from '../reactions/makeReactionStateReducer';
import { fetchPostsNames } from '../posts/fetchPosts';
import {
  PostCreatedAction,
  postCreatedActionName,
} from '../posts/postsActions';

// export const fetchComments = getFetchAction(actions, 'api_kudos_type_get');

export interface Page {
  status: StatusState;
  list: number[];
}

export interface Pages {
  [index: number]: Page;
}

export type CommentItems = Record<string | number, CommentResponse>;

export interface ThreadLists {
  [id: string]: Pages;
}

const pageStatusReducer = makeStatusReducer(names);

const listReducer = (state: number[] = [], action: CommentsAction) => {
  if (action.type === names.success) {
    return action.payload.data;
  }
  if (action.type === names.create) {
    return [action.payload.comment.id, ...state];
  }
  return state;
};

const pageReducer = combineReducers({
  status: pageStatusReducer,
  list: listReducer,
});

const pagesReducer = (
  state: Pages = {},
  action: CommentsAsyncAction | CommentCreateAction,
) => {
  if (action.type === names.create) {
    return {
      ...state,
      1: pageReducer(state[1], action),
    };
  }
  const { page } = action.payload;
  return {
    ...state,
    [page]: pageReducer(state[page], action),
  };
};

const commentReactionsReducer = makeReactionsStateReducer('comment');

const itemsReducer = (
  state: CommentItems = {},
  action: CommentsAction | ReactionActions | Action<any>,
) => {
  if (
    action.type === names.success ||
    action.type === kudosNames.success ||
    action.type === fetchPostsNames.success
  ) {
    const { payload } = action as CommentsSuccessAction;
    return {
      ...state,
      ...payload.entities.comments,
    };
  }
  if (action.type === names.create) {
    const { payload } = action as CommentCreateAction;
    const { comment } = payload;
    return {
      ...state,
      [comment.id]: comment,
    };
  }

  if (action.type === names.edit) {
    const { payload } = action as CommentEditAction;
    const { content, id } = payload;
    const prev = state[id];
    const comment = { ...prev, content };
    return {
      ...state,
      [id]: comment,
    };
  }

  if (action.type === names.report) {
    const { payload } = action as CommentReportAction;
    const { reporter, id } = payload;
    const prev = state[id];
    const comment = { ...prev, reporter, reported: true };
    return {
      ...state,
      [id]: comment,
    };
  }

  if (action.type === names.undoReport) {
    const { payload } = action as CommentUndoReportAction;
    const { id } = payload;
    const prev = state[id];
    const comment = { ...prev, reporter: null, reported: false };
    return {
      ...state,
      [id]: comment,
    };
  }

  if (action.type === names.delete) {
    const { payload } = action as CommentDeleteAction;
    const { id } = payload;
    const { [id]: removed, ...rest } = state;

    return rest;
  }

  return commentReactionsReducer(state, action) as CommentItems;
};

export const listsReducer = (
  state: ThreadLists = {},
  action: Action<any> | CommentsAction,
) => {
  try {
    assertCommentAction(action);
  } catch (ex) {
    return state;
  }
  const cAction = action as CommentsAsyncAction | CommentCreateAction;
  if (!cAction.payload.thread) {
    return state;
  }

  const { thread } = cAction.payload;
  return {
    ...state,
    [makeThreadKey(cAction.payload)]: pagesReducer(state[thread], cAction),
  };
};

type Threads = Record<string | number, CommentThread>;

export const threadsReducer = (state: Threads = {}, action: Action<any>) => {
  if (
    action.type === kudosNames.success ||
    action.type === kudosSingleNames.success ||
    action.type === fetchPostsNames.success
  ) {
    const { payload } = action as any;
    return {
      ...state,
      ...payload?.entities?.threads,
    };
  }

  if (action.type === postCreatedActionName) {
    const { payload } = action as PostCreatedAction;
    const threadId = payload.post.thread;
    if (!threadId) {
      return state;
    }
    return {
      ...state,
      [threadId]: {
        id: threadId,
        comments: [],
        commentCount: 0,
        reportedComments: [],
        reportedCommentCount: 0,
      } as CommentThread,
    };
  }

  if (action.type === names.create) {
    const { payload } = action as CommentCreateAction;
    const thread = state?.[payload.thread];
    return {
      ...state,
      [payload.thread]: {
        ...thread,
        commentCount: (thread?.commentCount || 0) + 1,
      },
    };
  }
  if (action.type === names.delete) {
    const { payload } = action as CommentDeleteAction;
    const thread = state?.[payload.thread];
    return {
      ...state,
      [payload.thread]: {
        ...thread,
        commentCount: (thread?.commentCount || 0) - 1,
        reportedCommentCount: payload.reported
          ? (thread?.reportedCommentCount || 0) - 1
          : thread?.reportedCommentCount,
      },
    };
  }
  return state;
};

export interface CommentsState {
  items: CommentItems;
  lists: ThreadLists;
  threads: Threads;
}

const commentsReducer = combineReducers({
  lists: listsReducer,
  items: itemsReducer,
  threads: threadsReducer,
});

export default commentsReducer;
