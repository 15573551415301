import { combineReducers, ActionCreator, Dispatch } from 'redux';
import keyBy from 'lodash/keyBy';
import { asyncNames, createActions } from 'shared/actions/utils';
import debounce from 'lodash/debounce';
import { createAction } from 'redux-actions';
import { AxiosError } from 'axios';
import makeStatusReducer from 'shared/reducers/status';
import Locales from 'shared/types/Locales';
import axios from 'shared/utils/axios';
import tenor, { TenorGif } from './tenorApi';

interface TenorItemsState {
  [id: string]: TenorGif;
}

interface TenorSearchRequest {
  type: 'TENOR_SEARCH_REQUEST';
  payload: {
    query?: string;
  };
}

interface TenorSearchSuccess {
  type: 'TENOR_SEARCH_SUCCESS';
  payload: {
    results: TenorGif[];
    pos?: string;
  } & TenorMetaState;
}

interface TenorSearchFailure {
  type: 'TENOR_SEARCH_FAILURE';
}

interface TenorSearchCancel {
  type: 'TENOR_SEARCH_CANCEL';
}

interface TenorAsyncActions {
  request: ActionCreator<TenorSearchRequest>;
  success: ActionCreator<TenorSearchSuccess>;
  failure: ActionCreator<TenorSearchFailure>;
  cancel: ActionCreator<TenorSearchCancel>;
}

export const names = asyncNames('TENOR_SEARCH');

export const actions = (createActions(names) as unknown) as TenorAsyncActions;

type TenorList = string[];

interface TenorMetaState {
  query?: string;
  next?: string;
  done?: boolean;
  empty?: boolean;
}

interface TenorGifsAction {
  type: 'TENOR_GIFS';
  payload: {
    results: TenorGif[];
  };
}
export const tenorGifsAction = createAction<{
  results: TenorGif[];
}>('TENOR_GIFS');

type TenorAction = TenorGifsAction | TenorSearchSuccess | TenorSearchFailure;

const items = (state: TenorItemsState = {}, action: TenorAction) => {
  if (action.type === 'TENOR_GIFS' || action.type === 'TENOR_SEARCH_SUCCESS') {
    const byId = keyBy(action.payload.results, 'id');
    return {
      ...state,
      ...byId,
    };
  }
  return state;
};

const list = (state: TenorList = [], action: TenorAction) => {
  if (action.type === 'TENOR_SEARCH_SUCCESS') {
    const ids = action.payload.results.map(item => item.id);
    const pos = parseInt(action.payload.pos || '0', 10);
    if (pos) {
      return [...state, ...ids];
    }
    return ids;
  }
  return state;
};

const meta = (state: TenorMetaState = {}, action: TenorAction) => {
  if (action.type === 'TENOR_SEARCH_SUCCESS') {
    const { next, query, empty } = action.payload;
    const done = action.payload.results.length < 20;
    return { next, query, done, empty };
  }
  return state;
};
const tenorReducer = combineReducers({
  items,
  list,
  meta,
  status: makeStatusReducer(names),
});

export type TenorStoreState = ReturnType<typeof tenorReducer>;

type GetState = () => { tenor: TenorStoreState; locale: Locales };

const searchTenor = async ({
  query,
  onSearched,
  dispatch,
  getState,
}: {
  query: string;
  onSearched?: () => void;
  dispatch: Dispatch<any>;
  getState: GetState;
}) => {
  const state = getState();
  const { locale } = state;
  const { next, done } = state.tenor.meta;
  // If using same query use next as pos else start from top
  const pos = state.tenor.meta.query === query ? next : undefined;
  // If not first search (pos) and is done don't search
  if (pos && done) {
    return;
  }
  try {
    // If searching with pos and prev results where empty we force trending
    const forceTrending = pos && state.tenor.meta.empty;
    // remember empty value if loading more trending
    let empty = forceTrending;
    dispatch(actions.request({ query }));

    let res =
      query.length > 0 && !forceTrending
        ? await tenor.search(query, { pos, locale })
        : await tenor.trending({ pos, locale });
    if (onSearched) {
      onSearched();
    }
    // If no results get trending
    if (query && res.results.length === 0) {
      empty = true;
      res = await tenor.trending({ pos, locale });
    }

    dispatch(
      actions.success({
        query,
        results: res.results,
        pos,
        next: res.next,
        empty,
      }),
    );
  } catch (ex) {
    const error = ex as AxiosError;
    if (axios.isCancel(error)) {
      dispatch(actions.cancel());
      return;
    }
    dispatch(actions.failure());
  }
};

const searchTenorDebounced = debounce(searchTenor, 250);

export const searchTenorGif = ({
  query,
  onSearched,
}: {
  query: string;
  onSearched?: () => void;
}) => async (dispatch: Dispatch<any>, getState: GetState) => {
  return searchTenorDebounced({
    query,
    onSearched,
    dispatch,
    getState,
  });
};

export default tenorReducer;
