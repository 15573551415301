import { names } from 'shared/actions/consents/fetch';
import { names as patchNames } from 'shared/actions/consents/patch';
import { names as userNames } from 'shared/actions/consents/fetchUser';
import { combineReducers } from 'redux';
import without from 'lodash/without';

const users = (state = {}, action) => {
  if (action.type === names.success || action.type === userNames.success) {
    const usersMap = { ...state };
    action.payload.users.forEach(user => {
      usersMap[user.id] = user;
    });
    return usersMap;
  }
  if (action.type === patchNames.success) {
    const { user, consent, value } = action.payload;
    const ids = state[user].userConsentsIds || [];
    const userConsentsIds = value ? [...ids, consent] : without(ids, consent);
    return { ...state, [user]: { ...state[user], userConsentsIds } };
  }
  return state;
};

const list = (state = [], action) => {
  if (action.type === names.success) {
    return action.payload.users.map(user => user.id);
  }
  return state;
};

const ids = (state = [], action) => {
  if (action.type === names.success || action.type === userNames.success) {
    return [...action.payload.consents];
  }
  return state;
};

const userConsents = combineReducers({
  users,
  list,
  ids,
});

export default userConsents;
