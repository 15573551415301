import { Action, ActionCreator, Dispatch } from 'redux';
import { asyncNames, createActions, ActionNames } from 'shared/actions/utils';
import { AppState } from 'shared/types/AppState';
import { User } from 'shared/types/User';
import axios from 'axios';
import { createAction } from 'redux-actions';
import errorLogger from 'shared/utils/errorLogger';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { CommentResponse, FetchCommentsParams } from './Comment';
import { makeThreadKey } from './commentUtils';

const COMMENTS_CREATE = 'COMMENTS_CREATE';
const COMMENTS_EDIT = 'COMMENTS_EDIT';
const COMMENTS_DELETE = 'COMMENTS_DELETE';
const COMMENTS_REPORT = 'COMMENTS_REPORT';
const COMMENTS_UNDO_REPORT = 'COMMENTS_UNDO_REPORT';

export interface CommentAsyncNames {
  request: 'COMMENTS_REQUEST';
  success: 'COMMENTS_SUCCESS';
  failure: 'COMMENTS_FAILURE';
  cancel: 'COMMENTS_CANCEL';
}

export type CommentActionNames = CommentAsyncNames & {
  create: typeof COMMENTS_CREATE;
  edit: typeof COMMENTS_EDIT;
  delete: typeof COMMENTS_DELETE;
  report: typeof COMMENTS_REPORT;
  undoReport: typeof COMMENTS_UNDO_REPORT;
};

export interface CommentsSuccessAction {
  type: CommentAsyncNames['success'];
  payload: {
    thread: number;
    page: number;
    reported: boolean;
    data: number[];
    entities: {
      comments: { [id: number]: CommentResponse };
      users: { [id: number]: User };
    };
  };
}

export interface CommentsFailureAction {
  type: CommentAsyncNames['failure'];
  payload: {
    reported: boolean;
    thread: number;
    page: number;
    error: Error;
  };
}

interface CommentsRequestAction {
  type: CommentAsyncNames['request'];
  payload: {
    reported: boolean;
    thread: number;
    page: number;
  };
}

interface CommentsCancelAction {
  type: CommentAsyncNames['cancel'];
  payload: {
    reported: boolean;
    thread: number;
    page: number;
  };
}

export interface CommentCreateAction {
  type: CommentActionNames['create'];
  payload: {
    thread: number;
    content: string;
    comment: CommentResponse;
  };
}

export interface CommentEditAction {
  type: CommentActionNames['edit'];
  payload: {
    id: number;
    content: string;
  };
}

export interface CommentDeleteAction {
  type: CommentActionNames['delete'];
  payload: CommentResponse;
}

export interface CommentReportAction {
  type: CommentActionNames['report'];
  payload: CommentResponse;
}

export interface CommentUndoReportAction {
  type: CommentActionNames['undoReport'];
  payload: CommentResponse;
}

export interface CommentActions {
  request: ActionCreator<CommentsRequestAction>;
  success: ActionCreator<CommentsSuccessAction>;
  failure: ActionCreator<CommentsFailureAction>;
  cancel: ActionCreator<CommentsCancelAction>;

  create: ActionCreator<CommentCreateAction>;
  edit: ActionCreator<CommentEditAction>;
  delete: ActionCreator<CommentDeleteAction>;
  report: ActionCreator<CommentReportAction>;
  undoReport: ActionCreator<CommentUndoReportAction>;
}

export type CommentsAsyncAction =
  | CommentsSuccessAction
  | CommentsFailureAction
  | CommentsRequestAction
  | CommentsCancelAction;

export type CommentsAction =
  | CommentsAsyncAction
  | CommentCreateAction
  | CommentEditAction
  | CommentDeleteAction
  | CommentReportAction
  | CommentUndoReportAction;

const commentAsyncNames = asyncNames('COMMENTS') as CommentAsyncNames;

export const names = {
  ...commentAsyncNames,
  create: COMMENTS_CREATE,
  edit: COMMENTS_EDIT,
  delete: COMMENTS_DELETE,
  report: COMMENTS_REPORT,
  undoReport: COMMENTS_UNDO_REPORT,
} as CommentActionNames;

export const commentActions = {
  ...createActions((names as unknown) as ActionNames),
  create: createAction(COMMENTS_CREATE),
  edit: createAction(COMMENTS_EDIT),
  delete: createAction(COMMENTS_DELETE),
  report: createAction(COMMENTS_REPORT),
  undoReport: createAction(COMMENTS_UNDO_REPORT),
} as CommentActions;

export function assertCommentAction(
  input: Action<any> | CommentsAction,
): asserts input is CommentsAction {
  // <-- the magic
  const actionNames = Object.values(names);
  if (actionNames.includes(input.type)) {
    return;
  }
  throw new Error('Not a CommentsAction');
}

export function assertCommentAsyncAction(
  input: Action<any> | CommentsAsyncAction,
): asserts input is CommentsAsyncAction {
  // <-- the magic
  const actionNames = Object.values(commentAsyncNames);
  if (actionNames.includes(input.type)) {
    return;
  }
  throw new Error('Not a CommentsAsyncAction');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getComments = (params: FetchCommentsParams) => {
  const opts: RoutingParams = {
    thread: params.thread,
    max_per_page: 5,
  };
  if (params.page) {
    opts.page = params.page;
  }
  if (params.reported) {
    opts.reported_comments = 1;
    opts.max_per_page = 100;
  }
  if (params.perPage) {
    opts.max_per_page = params.perPage;
  }
  const { social } = params;
  return axios.get(
    social?.api === 'social'
      ? AppRouting.generate('api_comments_thread_object_get', opts)
      : AppRouting.generate('api_thread_comments_get', opts),
  );
};

export const fetchComments = (params: FetchCommentsParams) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => {
  const state = getState();

  const status =
    state.comments.lists[makeThreadKey(params)]?.[params.page]?.status;

  if (status?.loading) {
    return;
  }

  await dispatch(commentActions.request(params));

  try {
    const response = await getComments(params);
    const {
      data: { data, entities },
    } = response;
    await dispatch(commentActions.success({ ...params, data, entities }));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(commentActions.cancel(params));
      return;
    }

    errorLogger(error);
    // const message = get(
    //   error,
    //   'response.data.errors.global',
    //   i18next.t('products|||fetchFailure'),
    // );

    dispatch(commentActions.failure({ ...params, error }));
  }
};
