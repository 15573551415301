import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { createAction } from 'redux-actions';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';
import defaultLogout from './logout';

export const fetchNames = asyncNames('FETCH_USER_DATA');

export const names = {
  ...fetchNames,
  patched: 'USER_PATCHED',
};

export const actions = createActions(fetchNames);

export const userPatched = createAction(names.patched);

const data = (setLocale, logout = defaultLogout) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  if (!setLocale && state.userDataStatus && state.userDataStatus.loading) {
    return state.userData;
  }
  dispatch(actions.request());
  try {
    const response = await axios(
      AppRouting.generate('api_get_logged_user_data'),
    );
    if (setLocale && response.data.user.locale !== state.locale) {
      await dispatch(setLocale({ lang: response.data.user.locale }));
    }
    await dispatch(actions.success({ ...response.data }));
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return dispatch(logout());
    }
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('user|||loginFailure'),
    );
    dispatch(notify({ message }));
    dispatch(actions.failure());
    throw error;
  }
};

export default data;
