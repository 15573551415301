import merge from 'lodash/merge';

import { names } from 'shared/actions/products/fetch';
import { names as singleNames } from 'shared/actions/products/fetchProduct';
import { names as productNames } from 'shared/actions/products/actions';
import { names as purchasesNames } from 'shared/actions/purchases/fetchPurchases';
import { names as purchaseNames } from 'shared/actions/purchases/fetchPurchase';

const initial = {};
const items = (state = initial, { type, payload }) => {
  if (type === names.success) {
    return merge({}, state, payload.data.entities.products);
  }
  if (type === singleNames.success) {
    return { ...state, [payload.id]: { ...payload.product } };
  }
  if (type === singleNames.failure) {
    return { ...state, [payload.id]: { error: true } };
  }
  if (type === productNames.clear) {
    return initial;
  }
  if (type === purchasesNames.success) {
    return merge({}, state, payload.data.entities.products);
  }
  if (type === purchaseNames.success) {
    return merge({}, state, payload.entities.products);
  }
  return state;
};

export default items;
