import { createAction } from 'redux-actions';
import { PostsParams } from './fetchPosts';
import { Post } from './PostTypes';

export const postCreatedActionName = 'POST_CREATED';
export const postUpdatedActionName = 'POST_UPDATED';
export const postDeletedActionName = 'POST_DELETED';

export const postCreatedAction = createAction(postCreatedActionName);
export const postUpdatedAction = createAction(postUpdatedActionName);
export const postDeletedAction = createAction(postDeletedActionName);

export interface PostCreatedAction {
  type: typeof postCreatedActionName;
  payload: {
    post: Post;
    params: PostsParams;
  };
}

export interface PostUpdatedAction {
  type: typeof postUpdatedActionName;
  payload: {
    post: Post;
  };
}

export interface PostDeletedAction {
  type: typeof postDeletedActionName;
  payload: {
    id: number;
  };
}
