import { combineReducers } from 'redux';
import { names } from 'shared/actions/bonuses/fetchBonuses';
import { names as deleteNames } from 'shared/actions/bonuses/delete';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import { names as companyNames } from 'shared/actions/company';

import makeStatusReducer from '../status';

const items = (state = [], { type, payload }) => {
  if (type === names.success) {
    const { result } = payload.data;
    if (payload.params.page > 1) {
      return uniq([...state, ...result.data]);
    }
    return result.data;
  }
  if (type === deleteNames.success) {
    return without(state, payload.bonus.id);
  }
  if (type === companyNames.set) {
    return [];
  }
  return state;
};
const meta = (state = {}, { type, payload }) => {
  if (type === names.success) {
    const { result } = payload.data;
    const { data, entities, ...rest } = result;
    return rest;
  }
  if (type === companyNames.set) {
    return {};
  }
  return state;
};
const list = combineReducers({
  status: makeStatusReducer({ ...names, initial: companyNames.set }),
  items,
  meta,
});
export default list;
