import { combineReducers } from 'redux';
import items from './items';
import lists from './lists';

const groups = combineReducers({
  items,
  lists,
});

export default groups;
