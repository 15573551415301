/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { AppRouting } from 'shared/utils/AppRouting';
import { combineReducers } from 'redux';
import errorLogger from 'shared/utils/errorLogger';
import { RouteNames } from 'shared/types/RouteNames';
import { DynamicFormConfig } from 'shared/types/Preliminary';

type ApiConfig = {
  url: RouteNames;
  idParam: number | string;
  objectType: string;
  type: string;
};

export type DynamicFormsActionTypes =
  | 'subaccount_transfer'
  | 'refund'
  | 'payment';

interface FetchDynamicFormsParams {
  objectId: number | string;
  objectType: 'company' | string;
  type: string;
  setError: (ex: AxiosError) => void;
  apiConfig: ApiConfig;
  currentKey: string;
}

interface DynamicFormsKey {
  companyId: string | number;
  formType?: string;
}

export const getDynamicFormsKey = ({
  companyId,
  formType,
}: DynamicFormsKey) => {
  const params: (string | number)[] = [`companyId:${companyId}`];
  if (formType) {
    params.push(`type:${formType}`);
  }
  return params.join('|');
};

export const fetchDynamicForm = createAsyncThunk(
  'formConfiguration/fetch',
  async (
    {
      objectId,
      objectType = 'company',
      type,
      setError,
      apiConfig,
      currentKey,
    }: FetchDynamicFormsParams,
    { rejectWithValue },
  ) => {
    const url = AppRouting.generate(apiConfig.url, {
      [apiConfig.idParam]: objectId,
      [apiConfig.objectType]: objectType,
      [apiConfig.type]: type,
    });
    try {
      const response = await axios.get(url);
      return { ...response.data, currentKey };
    } catch (ex) {
      errorLogger(ex);
      setError(ex);
      return rejectWithValue(ex);
    }
  },
);

export interface Entities {}

const configurationFormSlice = createSlice({
  name: 'configuration',
  reducers: {},
  initialState: {} as { [key: string]: DynamicFormConfig & Entities },
  extraReducers: builder => {
    builder.addCase(fetchDynamicForm.fulfilled, (state, action) => {
      const key = action.payload.currentKey as string;
      return {
        ...state,
        [key]: { ...action.payload.data, entities: action.payload.entities },
      };
    });
  },
});

const dynamicForms = combineReducers({
  list: configurationFormSlice.reducer,
});

export default dynamicForms;
