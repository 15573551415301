import { names as userDataNames } from 'shared/actions/user/data';
import { names as accountNames } from 'shared/actions/accounts';

const accounts = (state = null, { type, payload }) => {
  if (type === 'UPDATE_ACCOUNTS' || type === accountNames.success) {
    return payload.data;
  }
  if (type === userDataNames.success) {
    return payload.accounts.data;
  }
  return state;
};

export default accounts;
