import axios from 'axios';
import errorLogger from 'shared/utils/errorLogger';
import { asyncNames, createActions } from './utils';

export const names = {
  ...asyncNames('FETCH_ICON'),
  clear: 'ICONS_CLEAR',
};

export const actions = createActions(names);
export const clearAction = createActions(names);


let initialized = false;

const inCache = (state, key) => !!state[key];

const fetchIcon = url => async (dispatch, getState) => {
  if (!initialized) {
    dispatch(actions.clear());
  }
  initialized = true;
  if (inCache(getState().icons, url)) {
    return;
  }
  dispatch(actions.request(url));
  const request = axios.create({ withCredentials: false });
  try {
    const response = await request(url);
    dispatch(actions.success({ url, data: response.data }));
  } catch (error) {
    errorLogger(error);
  }
};

export default fetchIcon;
