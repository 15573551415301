/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { combineReducers } from 'redux';
import createAsyncThunkStatusSlice from '../../utils/redux/createAsyncThunkStatusSlice';
import {
  SubaccountTypeConfig,
  SubaccountTypesResponse,
} from '../../types/Subaccount';

interface FetchSubaccountTypesParams {
  companyId: number;
  refresh?: boolean;
}

export const fetchSubaccountTypes = createAsyncThunk(
  'subaccount_types/fetch',
  async ({ companyId }: FetchSubaccountTypesParams) => {
    const opts: RoutingParams = {
      company: companyId,
    };

    const response = await axios.get<SubaccountTypesResponse>(
      AppRouting.generate('api_subaccount_types_get', opts),
    );
    return response.data;
  },
);

export const getSubaccountTypesKey = ({
  companyId,
}: FetchSubaccountTypesParams) => {
  const params: (string | number)[] = [`companyId:${companyId}`];

  return params.join('|');
};

const initialSubaccountTypesState: Record<string, SubaccountTypeConfig> = {};

const subaccountTypesSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState: initialSubaccountTypesState,
  extraReducers: builder => {
    builder.addCase(fetchSubaccountTypes.fulfilled, (state, action) => {
      const { entities } = action.payload;
      state = { ...state, ...entities.subaccountTypes };
      return state;
    });
  },
});

const subaccountTypesListSlice = createSlice({
  name: 'list',
  reducers: {},
  initialState: {} as Record<string, string[]>,
  extraReducers: builder => {
    builder.addCase(fetchSubaccountTypes.fulfilled, (state, action) => {
      const key = getSubaccountTypesKey(action.meta.arg);
      state[key] = action.payload.data;
      return state;
    });
  },
});

const subaccountsStatus = createAsyncThunkStatusSlice(fetchSubaccountTypes, {
  getKey: getSubaccountTypesKey,
});

const subaccountTypes = combineReducers({
  items: subaccountTypesSlice.reducer,
  list: subaccountTypesListSlice.reducer,
  status: subaccountsStatus.reducer,
});

export default subaccountTypes;
