import { combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import produce from 'immer';
import { MetaState } from 'shared/types/reducers/MetaState';
import {
  Feedback,
  FeedbackListFilters,
  FeedbackStatChart,
  FeedbackIntentionType,
  FeedbackQuestion,
} from './FeedbackType';
import {
  DeleteFeedback,
  feedbackNames,
  FetchFeedbackListAction,
  FetchFeedbackListSuccess,
  SetFeedback,
  SetIntentionsAction,
  UpdateFeedback,
} from './feedbackActions';
import stats from './feedbackStatsStore';

const items = (
  state: Record<string, Feedback> = {},
  action: FetchFeedbackListSuccess | SetFeedback | UpdateFeedback,
) => {
  if (action.type === feedbackNames.list.success) {
    const { payload } = action as FetchFeedbackListSuccess;
    return {
      ...state,
      ...payload.response.entities.feedback,
    };
  }
  if (action.type === feedbackNames.setFeedback) {
    const { payload } = action as SetFeedback;
    const { data } = payload.response;
    return {
      ...state,
      [data.feedbackUid]: data,
    };
  }

  if (action.type === feedbackNames.update) {
    const { payload } = action as UpdateFeedback;

    return {
      ...state,
      [payload.feedback.feedbackUid]: payload.feedback,
    };
  }
  return state;
};

const questions = (
  state: Record<string, FeedbackQuestion> = {},
  action: FetchFeedbackListSuccess | SetFeedback,
) => {
  if (action.type === feedbackNames.list.success) {
    const { payload } = action as FetchFeedbackListSuccess;
    const { entities } = payload.response;
    return {
      ...state,
      ...entities?.question,
    };
  }
  if (action.type === feedbackNames.setFeedback) {
    const { payload } = action as SetFeedback;
    const { entities } = payload.response;
    return {
      ...state,
      ...entities?.question,
    };
  }
  return state;
};

export const getFeedbackListKey = ({
  companyId,
  filters,
}: {
  companyId?: number;
  filters: FeedbackListFilters;
}) => {
  const parts = [`${companyId || 'user'}`];
  if (filters.query) {
    parts.push(`query:${filters.query}`);
  }

  if (filters.type) {
    parts.push(`type:${filters.type}`);
  }

  if (filters.from) {
    parts.push(`from:${filters.from}`);
  }
  if (filters.to) {
    parts.push(`to:${filters.to}`);
  }
  if (filters.intention) {
    parts.push(`from:${filters.intention}`);
  }
  return parts.join('|');
};

const list = (
  state: string[] = [],
  action:
    | FetchFeedbackListAction
    | FetchFeedbackListSuccess
    | DeleteFeedback
    | UpdateFeedback,
): string[] => {
  if (action.type === feedbackNames.list.success) {
    const { payload } = action as FetchFeedbackListSuccess;
    if (payload.page === 1) {
      return payload.response.data;
    }
    return [...state, ...payload.response.data];
  }
  if (action.type === feedbackNames.delete) {
    const { payload } = action as DeleteFeedback;

    return state.filter(uid => uid !== payload.feedbackUid);
  }
  return state;
};

const chartsReducer = (
  state: FeedbackStatChart[] = [],
  action: FetchFeedbackListAction | FetchFeedbackListSuccess,
): FeedbackStatChart[] => {
  if (action.type === feedbackNames.list.success) {
    const { payload } = action as FetchFeedbackListSuccess;

    return payload.response?.charts || [];
  }
  return state;
};

const metaReducer = (
  state: MetaState | null = null,
  action: FetchFeedbackListAction | FetchFeedbackListSuccess,
): MetaState | null => {
  if (action.type === feedbackNames.list.success) {
    const { payload } = action as FetchFeedbackListSuccess;
    const { entities, data, charts, ...meta } = payload.response;
    return meta;
  }

  return state;
};

const listState = combineReducers({
  list,
  charts: chartsReducer,
  status: makeStatusReducer(feedbackNames.list),
  meta: metaReducer,
});

export type FeedbackListState = ReturnType<typeof listState>;

const lists = (
  state: Record<string, FeedbackListState> = {},
  action: FetchFeedbackListAction,
) => {
  if (
    action.type === feedbackNames.list.success ||
    action.type === feedbackNames.list.request ||
    action.type === feedbackNames.list.failure ||
    action.type === feedbackNames.list.cancel
  ) {
    const { payload } = action;
    const key = getFeedbackListKey(payload);
    return {
      ...state,
      [key]: listState(state[key], action),
    };
  }
  if (action.type === feedbackNames.delete) {
    const keys = Object.keys(state);
    return produce(state, draft => {
      keys.forEach(key => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = listState(state[key], action);
      });
    });
  }
  return state;
};

const intentions = (
  state: FeedbackIntentionType[] | null = null,
  action: SetIntentionsAction,
) => {
  if (action.type === feedbackNames.setIntentions) {
    return action.payload.data;
  }

  return state;
};

const feedback = combineReducers({
  intentions,
  items,
  lists,
  questions,
  stats,
});

export type FeedbackState = ReturnType<typeof feedback>;

export default feedback;
