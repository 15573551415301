import { combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import Locales from 'shared/types/Locales';
import {
  FetchTextSectionAction,
  fetchTextSectionNames,
  FetchTextSectionSuccess,
} from './sectionsActions';
import { TextSection } from './Sections';

const data = (
  state: TextSection | null = null,
  action: FetchTextSectionAction | FetchTextSectionSuccess,
): TextSection | null => {
  if (action.type === fetchTextSectionNames.success) {
    const { payload } = action as FetchTextSectionSuccess;

    return payload.response;
  }
  return state;
};

const section = combineReducers({
  data,
  status: makeStatusReducer(fetchTextSectionNames),
});

export type FeedbackSectionState = ReturnType<typeof section>;

export const getSectionKey = ({
  name,
  locale,
}: {
  name: string;
  locale: Locales;
}) => {
  return `${name}|${locale}`;
};

const sections = (
  state: Record<string, FeedbackSectionState> = {},
  action: FetchTextSectionAction,
) => {
  if (
    action.type === fetchTextSectionNames.success ||
    action.type === fetchTextSectionNames.request ||
    action.type === fetchTextSectionNames.failure ||
    action.type === fetchTextSectionNames.cancel
  ) {
    const { payload } = action;
    const key = getSectionKey(payload);
    return {
      ...state,
      [key]: section(state[key], action),
    };
  }
  return state;
};

export type SectionsState = ReturnType<typeof sections>;

export default sections;
