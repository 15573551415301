/* eslint-disable no-param-reassign */
import produce from 'immer';
import uniq from 'lodash/uniq';
import { Action, combineReducers } from 'redux';
import makeStatusReducer from 'shared/reducers/status';
import IdDictionary, { KeyDictionary } from 'shared/types/IdDictionary';
import { NewsCategory } from 'shared/types/news/News';
import {
  NewsCategoriesAction,
  newsCategoriesActionNames,
  NewsCategoriesSuccessAction,
  RemoveNewsCategoryAction,
  REMOVE_NEWS_CATEGORY,
  UpdateNewsCategoryAction,
  UPDATE_NEWS_CATEGORY,
} from './newsCategoriesActions';

export type NewsListState = number[];

const handleParentUpdate = ({
  parent,
  category,
  draft,
  prevParentId,
}: {
  prevParentId: number | null;
  parent: NewsCategory | undefined;
  category: NewsCategory;
  draft: IdDictionary<NewsCategory>;
}) => {
  if (!parent) {
    return;
  }
  if (parent.parentCategoryId === category.categoryId) {
    parent.parentCategoryId = prevParentId || null;
    if (prevParentId && category.parentCategoryId) {
      const prevParent = draft[prevParentId];
      prevParent.childrenIds.push(category.parentCategoryId);
    }
    draft[category.categoryId].childrenIds = category.childrenIds.filter(
      id => id !== category.parentCategoryId,
    );
  }

  draft[parent.categoryId].childrenIds = uniq([
    ...parent.childrenIds,
    category.categoryId,
  ]);
};

const items = (state: IdDictionary<NewsCategory> = {}, action: Action<any>) => {
  if (action.type === newsCategoriesActionNames.success) {
    const { payload } = action as NewsCategoriesSuccessAction;
    const { categories } = payload.entities;
    return { ...state, ...categories };
  }
  if (action.type === UPDATE_NEWS_CATEGORY) {
    const { payload } = action as UpdateNewsCategoryAction;
    const { category } = payload;
    return produce(state, draft => {
      const prevParentId = state[category.categoryId]?.parentCategoryId;
      draft[category.categoryId] = category;
      const parent = category.parentCategoryId
        ? draft[category.parentCategoryId]
        : undefined;
      handleParentUpdate({
        draft,
        category,
        parent,
        prevParentId,
      });
      const prevParent = prevParentId && draft[prevParentId];
      if (prevParent && prevParentId !== category.parentCategoryId) {
        prevParent.childrenIds = prevParent.childrenIds.filter(
          id => id !== category.categoryId,
        );
      }
    });
  }
  if (action.type === REMOVE_NEWS_CATEGORY) {
    const { payload } = action as RemoveNewsCategoryAction;
    const { parentCategoryId } = payload.category;
    if (!parentCategoryId) {
      return state;
    }
    return produce(state, draft => {
      const parent = draft[parentCategoryId];
      parent.childrenIds = parent.childrenIds.filter(
        id => id !== payload.category.categoryId,
      );
    });
  }
  return state;
};

const list = (state: NewsListState = [], action: Action<any>) => {
  if (action.type === newsCategoriesActionNames.success) {
    const { payload } = action as NewsCategoriesSuccessAction;

    const { data } = payload;

    return data;
  }
  if (action.type === REMOVE_NEWS_CATEGORY) {
    const { payload } = action as RemoveNewsCategoryAction;

    return state.filter(id => id !== payload.category.categoryId);
  }
  if (action.type === UPDATE_NEWS_CATEGORY) {
    const { payload } = action as UpdateNewsCategoryAction;
    if (!payload.category.parentCategoryId) {
      return state;
    }
    return state.filter(id => id !== payload.category.categoryId);
  }
  return state;
};

const categoriesActions = Object.values(newsCategoriesActionNames);

export const getNewsCategoriesKey = ({
  companyId,
  all,
  global,
  promo,
}: {
  companyId?: number;
  all?: number | boolean;
  global?: number | boolean;
  promo?: number | boolean;
}) => {
  const parts: string[] = [`company:${companyId || 0}`];
  if (all) {
    parts.push('all');
  }
  if (global) {
    parts.push('global');
  }
  if (promo) {
    parts.push('promo');
  }
  return parts.join('|');
};

const listReducer = combineReducers({
  list,
  status: makeStatusReducer(newsCategoriesActionNames),
});

export type CompanyCategoryList = ReturnType<typeof listReducer>;

const lists = (
  state: KeyDictionary<CompanyCategoryList> = {},
  action: Action<any>,
) => {
  if (categoriesActions.includes(action.type)) {
    const { payload } = action as NewsCategoriesAction;
    const key = getNewsCategoriesKey(payload);
    return {
      ...state,
      [key]: listReducer(state[key], action),
    };
  }
  if (
    action.type === REMOVE_NEWS_CATEGORY ||
    action.type === UPDATE_NEWS_CATEGORY
  ) {
    return produce(state, draft => {
      Object.keys(draft).forEach(key => {
        draft[key] = listReducer(state[key], action);
      });
    });
  }
  return state;
};

const newsCategories = combineReducers({
  lists,
  items,
});

export type NewsCategoriesState = ReturnType<typeof newsCategories>;

export default newsCategories;
