import axios from 'axios';
import get from 'lodash/get';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('FETCH_USER');
export const actions = createActions(names);

const fetchUser = params => async dispatch => {
  dispatch(actions.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_company_get_user',
        {
          ...params,
        },
        true,
      ),
    );
    const user = response.data;
    await dispatch(actions.success({ ...params, user }));
    return response.data;
  } catch (error) {
    console.log(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('user|||fetchFailure'),
    );
    dispatch(actions.failure({ error, ...params }));
    const ex = new Error(message);
    ex.response = error.response;
    throw ex;
  }
};

export default fetchUser;
