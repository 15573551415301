import { getDecimalCurrency } from 'shared/utils/currency';
import {
  WhipRoundStatus,
  WhipRoundMemberStatus,
  WhipRoundMember,
} from 'shared/types/WhipRound';

export const types = {
  fixedAmount: 'fixed_amount',
  fixedMembers: 'fixed_members',
};

export const statuses = {
  created: 'created',
  started: 'started',
  closed: 'closed',
  removed: 'removed',
  finished: 'finished',
};

export const userStatuses = {
  prepared: 'prepared',
  invited: 'invited',
  accepted: 'accepted',
  declined: 'declined',
  closed: 'closed',
  finished: 'finished',
  removed: 'removed',
};

export const getAmount = (total: number, userCount: number) => {
  const totalDec = getDecimalCurrency(total);

  const amount = Math.floor(totalDec / userCount);
  const totalAmount = amount * userCount;
  const difference = totalDec - totalAmount;
  return {
    amount,
    difference,
  };
};

export const isOpen = (status: WhipRoundStatus) =>
  status === WhipRoundStatus.created || status === WhipRoundStatus.started;

export const isFinished = (status: WhipRoundStatus) =>
  status === WhipRoundStatus.finished || status === WhipRoundStatus.removed;

export const isClosed = (status: WhipRoundStatus) =>
  status === WhipRoundStatus.closed;

export const canJoin = (status: WhipRoundMemberStatus) =>
  status &&
  (status === WhipRoundMemberStatus.prepared ||
    status === WhipRoundMemberStatus.invited);

export const isAccepted = (status: WhipRoundMemberStatus) =>
  status === WhipRoundMemberStatus.accepted ||
  status === WhipRoundMemberStatus.closed ||
  status === WhipRoundMemberStatus.finished;

export const isDeclined = (status: WhipRoundMemberStatus) =>
  status === WhipRoundMemberStatus.declined;

export const isPrepared = (status: WhipRoundMemberStatus) =>
  status === WhipRoundMemberStatus.prepared;

export const userAccepted = (user: WhipRoundMember) =>
  user && isAccepted(user.status);

export const userCanJoin = (user: WhipRoundMember) =>
  user && canJoin(user.status);

export const getCurrentContribution = (members: WhipRoundMember[]) =>
  members &&
  members.reduce(
    (sum, member) => (userAccepted(member) ? sum + member.contribution : sum),
    0,
  );

export const getTotalContribution = (members: WhipRoundMember[]) =>
  members &&
  members.reduce(
    (sum, member) => (member ? sum + member.contribution : sum),
    0,
  );

export const userPrepared = (user: WhipRoundMember) =>
  user && isPrepared(user.status);

export const userDeclined = (user: WhipRoundMember) =>
  user && isDeclined(user.status);

export const notDeclined = (user: WhipRoundMember) => !userDeclined(user);

export const filterDeclinedUsers = (users: WhipRoundMember[]) =>
  users && users.filter(notDeclined);

export default types;
