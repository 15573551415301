const makePurchaseListKey = ({
  types,
  mode = 'private',
  employee,
}: {
  types: string | null;
  mode?: string;
  employee?: number;
}) => {
  const type = types != null ? types : 'all';
  const employeeKey = employee ? `|employeeId:${employee}` : '';
  return `${mode}${employeeKey}|${type}`;
};

export default makePurchaseListKey;
