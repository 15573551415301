import axios from 'axios';
import { asyncNames, createActions } from '../utils';

export const names = asyncNames('FETCH_BONUS');
export const actions = createActions(names);

const fetchBonus = params => async dispatch => {
  dispatch(actions.request(params));
  try {
    const response = await axios(
      AppRouting.generate(
        'api_get_bonus',
        {
          ...params,
        },
        true,
      ),
    );
    await dispatch(actions.success({ ...params, bonus: response.data }));

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(actions.cancel({ error, ...params }));
      return undefined;
    }

    dispatch(actions.failure({ error, ...params }));
    throw error;
  }
};

export default fetchBonus;
