import { createAction } from 'redux-actions';
import axios from 'shared/utils/axios';
import errorLogger from 'shared/utils/errorLogger';
import get from 'lodash/get';
import { AppRouting } from 'shared/utils/AppRouting';
import { RouteNames } from 'shared/types/RouteNames';
import { ActionCreator, Dispatch } from 'redux';
import notify from './notify';

// export type AsyncNames<T extends string> = {
//   // eslint-disable-next-line prettier/prettier
//   request: `${T}_REQUEST`,
//   success: `${T}_SUCCESS`,
//   failure: `${T}_FAILURE`,
//   cancel: `${T}_CANCEL`,
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type AsyncNames<T extends string> = {
  // eslint-disable-next-line prettier/prettier
  request: string;
  success: string;
  failure: string;
  cancel: string;
};

export const asyncNames = <T extends string>(baseName: T) => {
  return {
    request: `${baseName}_REQUEST`,
    success: `${baseName}_SUCCESS`,
    failure: `${baseName}_FAILURE`,
    cancel: `${baseName}_CANCEL`,
  } as AsyncNames<T>;
};

export type ActionNames = { [key: string]: string };

type AsyncActionNames = ReturnType<typeof asyncNames>;

type AsyncActions<T> = {
  [Key in keyof T]: ActionCreator<T[Key]>;
};

export const createActions = <T extends ActionNames>(names: T) => {
  const actions: {
    [key in keyof T]?: ActionCreator<any>;
  } = {};
  Object.keys(names).forEach((key: keyof T) => {
    actions[key] = createAction(names[key]);
  });
  return actions as AsyncActions<T>;
};

export const getFetchAction = (
  actions: AsyncActions<AsyncActionNames>,
  url: RouteNames,
  sendNotify: boolean = true,
) => (params = {}) => async (dispatch: Dispatch<any>) => {
  dispatch(actions.request({ params }));

  try {
    const { data } = await axios(AppRouting.generate(url, params));
    if (Array.isArray(data)) {
      await dispatch(actions.success(data));
    } else {
      await dispatch(actions.success({ ...data }));
    }
    return { data, params };
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(actions.cancel({ params, error }));
      return { error, cancel: true };
    }
    dispatch(actions.failure({ params, error }));
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      // @ts-ignore
      i18next.t('ui|||fetchFailure'),
    );
    if (sendNotify) {
      dispatch(notify({ message }));
    }
    return { error, message, params };
  }
};

export const getPatchAction = (
  actions: AsyncActions<AsyncActionNames>,
  sendNotify: boolean = true,
) => (url: RouteNames, params = {}) => async (dispatch: Dispatch<any>) => {
  dispatch(actions.request({ params }));

  try {
    const { data } = await axios(url, params);

    await dispatch(actions.success(data));

    return { data, params };
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(actions.cancel({ params, error }));
      return { error, cancel: true };
    }
    dispatch(actions.failure({ params, error }));
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      // @ts-ignore
      i18next.t('ui|||fetchFailure'),
    );
    if (sendNotify) {
      dispatch(notify({ message }));
    }
    return { error, message, params };
  }
};
