import createAction from 'redux-actions/lib/createAction';

export const names = {
  add: 'CART_ADD',
  remove: 'CART_REMOVE',
  modify: 'CART_MODIFY',
  clear: 'CART_CLEAR',
  gift: 'CART_GIFT',
  funding: 'CART_FUNDING',
  documentSubmit: 'CART_DOCUMENT_SUBMIT',
};

export const add = createAction(names.add);
export const remove = createAction(names.remove);
export const clear = createAction(names.clear);
export const modify = createAction(names.modify);
export const gift = createAction(names.gift);
export const funding = createAction(names.funding);
export const documentSubmit = createAction(names.documentSubmit);


export default {
  add,
  remove,
  clear,
  modify,
  gift,
  funding,
  documentSubmit,
};
